import React from 'react'
import "../../style/Loader.css";
import { SyncLoader } from 'react-spinners';

const Loader = () => {
return (
    <div className="loading-screen">
        <SyncLoader color="#fea500" loading={true} height={15} width={5} />
    </div>
    );
}

export default Loader