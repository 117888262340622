import React, { useRef } from "react";

// style
import "../../style/PostCreate.css";

// font aswesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const AdvertiseModal = ({ advertiseModal, setAdvertiseModal, justShow }) => {
  const modalRef = useRef();

  function resetAllValue() {
    setAdvertiseModal(false);

    const closeIconStyle = document.querySelector(".closeIconStyle");
    if (closeIconStyle) {
      closeIconStyle.style.display = "none";
    }
  }

  function onFunction(e) {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      resetAllValue();
    }
  }

  return (
    <div
      className={"postCreate helpModal"}
      style={{
        opacity: advertiseModal ? 1 : 0,
        cursor: advertiseModal ?? "none",
        zIndex: advertiseModal ? 111111111111 : -1,
        visibility: advertiseModal ? "visible" : "hidden",

      }}
      onClick={onFunction}
    >
      <div className="center_div" ref={modalRef}>
        {/* top part */}
        <div className="post_create_top_part">
          {justShow ? <p></p> :
            <p>
              <span className="text-white">
              Advertising
              </span>
            </p>
          }
          {/* close icon */}
          <div className="close_icon" onClick={resetAllValue}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>

        {/* main part */}
        <div className="main_part">
          {/* text area */}
          <div className="text_area">
            <p>
              {
                justShow ? justShow :
                  "If you’re looking to form a partnership or advertise on our movie community site , contact us at:"
              }
            </p>
            {justShow ? null :
              <a href="help@filmwaffle.com" className=" hover:opacity-[.7]">
                help@filmwaffle.com
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiseModal;
