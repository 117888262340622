import React, { useState, useRef, useEffect } from "react";

// react router
import { Link, NavLink, useNavigate } from "react-router-dom";

// react image
import { BiCamera } from "react-icons/bi";
import { RiLogoutBoxLine } from "react-icons/ri";

// images
import { companyLogo3 } from "../../constant/LogoPath";
import { logo4, profile } from "../../constant/imagePath";
import {
  MessageIcon1,
  Home1Icon,
  Notification1Icon,
  FollowersIcon,
  Message2Icon,
  GroupIcon,
  Setting1Icon,
  SignupIcon,
  FollowMemberIcon,
  RemoveUserIcon,
  BlockMemberIcon,
  LoginIcon,
  VerticalDotsWhiteIcon,
  HelpIconWhite,
  HelpIcon,
} from "../../constant/IconsPath";

import DeleteUserModel from "../Models/DeleteUserModel";

// font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faSackDollar, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

// components
import { Help } from "../Models/Index";
import ViewAllGroup from "../Models/ViewAllGroup/ViewAllGroup";
import GroupMembers from "../Models/GroupMembers";

// dummy data
import users from "../../data/AllUsers";
import StorageService from "../../services/StorageService";
import Footer from "../Footer/Footer";
import { post } from "../../api/api";
import AdvertiseModal from "../Models/AdvertiseModal";
import { faStar } from "@fortawesome/free-regular-svg-icons";


const MobileNavBar = ({ open, toggle, messageShow, loginAndSignup, messageCurrentPage, showLoginMessage }) => {
  let body = document.querySelector("body");
  body.style.overflow = open ? "hidden" : "auto";
  const [helpModal, setHelpModal] = useState(false);
  const [advertiseModal, setAdvertiseModal] = useState(false);
  const [isSymbolicLogo, setIsSymbolicLogo] = useState(false);
  const [findUser, setFindUser] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [passInputVal, setPassInputVal] = useState("");
  const [viewGroup, setViewGroup] = useState(false);
  const [showMember, setShowMember] = useState(false);
  const [showDeleteBox, setShowDeleteBox] = useState(false);
  const someValue = StorageService.getValue('userName');
  const someProfile = StorageService.getValueProfilePic('profilePic');

  const navigate = useNavigate();

  const searchBox = useRef();
  const closeSearchResult = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (searchBox.current && !searchBox.current.contains(e.target)) {
        let searchField = document.querySelector(".searchField");
        searchField.value = "";
        setIsSymbolicLogo(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    let handler1 = (e) => {
      if (
        closeSearchResult.current &&
        !closeSearchResult.current.contains(e.target)
      ) {
        setFindUser([]);
        setSearchFieldValue("");
      }
    };

    document.addEventListener("mousedown", handler1);

    return () => {
      document.addEventListener("mousedown", handler1);
    };
  }, []);

  const openSearchArea = () => {
    setIsSymbolicLogo(true);
    let searchField = document.querySelector(".searchField");
    searchField.focus();
  };

  const handleDeleteClick = () => {
    setShowDeleteBox(!showDeleteBox)
  };


  const SearchUser = (e) => {
    if (e.trim().length !== 0 || passInputVal !== "") {
      setPassInputVal(e);
      setSearchFieldValue(e);
      if (e === "") {
        setFindUser([]);
      }
    }
  };

  useEffect(()=>{
    const debounce = setTimeout(() => {
      if(passInputVal){
        getAllUser(passInputVal)
      }
    }, 500);
    return () => clearTimeout(debounce);
  },[passInputVal])

  const getAllUser= async(e)=>{
    let res = await post(`/Identity/SearchUsers?username=${e}`)
    let data=await res.json();
    if(data&&data?.length>0){
      setFindUser(data?.slice(0,10))
    }
  }

  const searchPeople1 = () => {
    navigate("/search-result", { state: passInputVal });
  };

  const navigateAnotherPage = (val) => {
    setFindUser([]);
    navigate("/userProfile", { state: val });
  };


  return (
    <React.StrictMode>
      <ViewAllGroup open={viewGroup} setViewGroup={setViewGroup} />
      <DeleteUserModel helpModal={showDeleteBox} setHelpModal={setShowDeleteBox}/>
      <GroupMembers
        open={showMember}
        toggle={() => setShowMember((prev) => !prev)}
        heading={"Followers"}
        option={[{
          text: "Remove follower",
          simpleText: "remove",
          labelText: "remove",
          icon: RemoveUserIcon,
        },
        {
          text: "Follow member",
          simpleText: "follow",
          labelText: "follow",
          icon: FollowMemberIcon,
        },
        {
          text: "Block member",
          simpleText: "remove",
          labelText: "block",
          icon: BlockMemberIcon,
        }
        ]}
        popupName={"followers"}
      />
      <div>
        <Help helpModal={helpModal} setHelpModal={setHelpModal} />
        <AdvertiseModal advertiseModal={advertiseModal} setAdvertiseModal={setAdvertiseModal} />
        <nav
          className="flex items-center justify-between sm:hidden px-5"
          style={{
            height: "80px",
            // boxShadow: open && "0 4px 20px 0 rgba(0, 0, 0, 0.08)",
            zIndex: open && "11111",
            position: open && "relative",
          }}
        >
          {/* logo area */}
          <div className="logo flex items-center justify-center">
            {/* <Link to={loginAndSignup ? "/homeView" : "/"} className={`logo1_1 ${isSymbolicLogo && "active"}`}>
              <img className="w-[24px]" src={companyLogo3} alt="logo" />
            </Link> */}

            <Link to={"/"} className={`logo2_2 `}>
              <img className="w-[170px] h-auto" src={companyLogo3} alt="logo" />
            </Link>
          </div>

          {/* social Link */}
          <div className="flex items-center justify-center gap-5 header_top_right_side_icon">
            <div
              className={`searchArea ${isSymbolicLogo ? "active" : "deactive"}`}
              ref={searchBox}
            >
              <input
                type="text"
                placeholder="Search Members"
                className="searchField"
                onChange={(e) => loginAndSignup ? showLoginMessage() : SearchUser(e.target.value)}
                value={searchFieldValue}
              />
            </div>

            {!isSymbolicLogo && (
              <NavLink onClick={openSearchArea}>
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className={`text-secondary text-3xl mobileResponsiveIcon ${isSymbolicLogo && "deactive"
                    }`}
                  style={{ width: "15px", height: "15px", color: "#fff" }}
                />
              </NavLink>
            )}

            {/* {messageShow && (
              <NavLink to="/messages/1">
                <span style={{ width: "19px" }} className="text-secondary text-3xl mobileResponsiveIcon">
                  <MessageIcon1 width="18px" strokeColor={messageCurrentPage ? "#ffc665" : "#fff"} />
                </span>
              </NavLink>
            )} */}
            <div onClick={toggle} style={{ cursor: "pointer", width: "5px" }}>
              <img
                src={VerticalDotsWhiteIcon}
                className="text-secondary text-3xl mobileResponsiveIcon"
                alt="vertical icon"
              />
            </div>
          </div>


          {/* search result drop down */}
          {searchFieldValue && passInputVal && (
            <div
              className="searchResultInBox"
              ref={closeSearchResult}
              style={{ width: "95%", left: "0.6rem", top: "4rem" }}
            >
              <div
                className="search_result_top_label"
                onClick={searchPeople1}
              >
                <div className="magnifyning_glass">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
                <p>
                  Search for{" "}
                  <span
                    style={{
                      color: "#fff",
                      lineHeight: "normal",
                      fontWeight: "bold",
                    }}
                  >
                    "{searchFieldValue}"
                  </span>
                </p>
              </div>
              {findUser?.length > 0 &&
                        findUser.map((val) => {
                          let { id, userName, profilePicture } = val;
                          return (
                            <div
                              className="user1"
                              key={id}
                              onClick={() => navigateAnotherPage(id)}
                            >
                              <img src={profilePicture?`data:image/png;base64,${profilePicture}`:logo4} alt="userImage" />
                              <p><span style={{ color: "#fff" }}>
                                {userName}
                              </span></p>
                            </div>
                          );
                        })}
            </div>
          )}
        </nav>

        <div
          className={
            open
              ? "px-5 w-[100%] md:w-[370px] absolute sm:top-[76px] sm:right-0 ml-auto bg-[#fff] sm:shadow-lg z-[100] fullNavBar"
              : "hidden"
          }
          style={{
            padding: open ? "0rem" : "",
            height: open ? "100vh" : "",
            backgroundColor: open ? "rgb(19 19 19)" : "",
            border: open && "1px solid #303030",
            borderTop: open && "none",
            overflow: "auto"
          }}
        >
          {(loginAndSignup || !StorageService?.getJwt()) ? (
            <div
              className="flex items-center justify-center gap-5 loginAndSignUp"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "11px",
                padding: "0px 1.25rem 4rem",
                alignItems: "flex-start",
                marginTop: "1rem",
              }}
            >
              <NavLink to="/login" className="Headerlogin" onClick={toggle}>
                <span style={{ marginRight: "0.5rem" }} >
                  <LoginIcon />
                </span>

                Login
              </NavLink>
              <NavLink to="/signUp" className="HeaderSignup" onClick={toggle}>
                <span style={{ marginRight: "0.5rem" }} >
                  <SignupIcon />
                </span>

                Sign up
              </NavLink>
              <NavLink
                className="HeaderSignup helpLink"
                onClick={() => {
                  toggle();
                  setHelpModal(!helpModal);
                }}
              >
                <img
                  src={HelpIconWhite}
                  alt="signup icon"
                  style={{ marginRight: "0.5rem" }}
                />
                Help
              </NavLink>
              <NavLink
                className="HeaderSignup helpLink"
                onClick={() => {
                  toggle();
                  setAdvertiseModal(!advertiseModal);
                }}
              >
              
              <FontAwesomeIcon icon={faStar}className="mr-1" color={ "#808080"} />
              Advertising
              </NavLink>

              <div className="flex items-center justify-center termsAandCondition">
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/Agreement"
                  reloadDocument={true}
                >
                  User Agreement
                </Link>
                <span className="text-[#dddddd] mt-[21px]">|</span>
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/PrivacyPolicy"
                  reloadDocument={true}
                >
                  Privacy Policy
                </Link>
                <span className="text-[#dddddd] mt-[21px]">|</span>
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/AcceptableUsePolicy"
                  reloadDocument={true}
                >
                  Acceptable Use Policy
                </Link>
                <span className="text-[#dddddd] mt-[21px]">|</span>
                <Link
                  className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                  to="/Disclaimer"
                  reloadDocument={true}
                >
                  Disclaimer
                </Link>
              </div>
              {/* <Footer /> */}
            </div>
          ) : (
            <>
              <div
                className="flex items-center mt-7"
                style={{
                  margin: "0.3rem 0",
                  padding: open && "1.25rem",
                  paddingBottom: open && "0",
                }}
              >
                {/* profile image */}
                {/* <div className="w-10 h-10 p-[5px] rounded-full relative profile_image_design">
                  <img
                    className="mx-auto w-full h-full rounded-full z-0"
                    src={`data:image/jpeg;base64,${someProfile}`}
                    alt="Profile"
                    style={{ boxShadow: "{0px 4px 24px 0px #0000001f" }}
                  />
                  <div className="h-[9.5px] w-[9.5px] bg-primary rounded-full flex items-center justify-center absolute bottom-[5px] right-[6px]">
                    <BiCamera className="text-white w-[5px]" />
                  </div>
                </div> */}

                <div className="w-50 h-50 sm:w-8 sm:h-8 rounded-full overflow-hidden relative" style={{ marginLeft: "-10px", marginTop: '-10px' }}>
                  <img
                    className="w-8 h-8  rounded-full object-cover"
                    src={(someProfile != "null"&&!someProfile?.includes('null')) ? `data:image/jpeg;base64,${someProfile}` : logo4}
                    alt="Profile"
                  />
                </div>
                <div className="ml-2">
                  <h2 className="text-lg user_name_design">
                    <span className="text-[#fff]">
                      {someValue && someValue}
                    </span>
                  </h2>
                  <NavLink
                    onClick={toggle}
                    to="/userProfileView"
                    className="text-[#707B88]"
                  >
                    View Your Profile
                  </NavLink>
                </div>
              </div>
              <nav
                onClick={toggle}
                className="divide-y divider-gray"
                style={{
                  height: open && "calc(100% - 110px)",
                  overflow: open && "auto",
                  padding: open && "1.25rem",
                  paddingTop: open && "0",
                  paddingBottom: open && "4rem",
                }}
              >
                <NavLink
                  to="/"
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  style={{ borderColor: "#303030" }}
                >
                  <img src={Home1Icon} alt="home" className="text-xl mr-2" />
                  Home
                </NavLink>



                {/* <div
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  onClick={() => setShowMember(!showMember)}
                  style={{ cursor: "pointer", borderColor: "#303030" }}
                >
                  <img
                    src={FollowersIcon}
                    alt="followers"
                    className="text-xl mr-2"
                  />
                  Followers
                </div> */}

                {/* <NavLink
                  to="/messages/1"
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  style={{ borderColor: "#303030" }}
                >
                  <img
                    src={Message2Icon}
                    alt="message"
                    className="text-xl mr-2"
                  />
                  Messages
                </NavLink> */}

                <NavLink
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  onClick={() => { toggle(); setViewGroup(!viewGroup) }}
                  style={{ borderColor: "#303030" }}
                >
                  <img
                    src={GroupIcon}
                    alt="group icon"
                    className="text-xl mr-2"
                  />
                  Groups
                </NavLink>

                <NavLink
                  to="/settings/accountInfo"
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  style={{ borderColor: "#303030" }}
                >
                  <img
                    src={Setting1Icon}
                    alt="setting"
                    className="text-xl mr-2"
                  />
                  Settings
                </NavLink>

                

                <NavLink
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  onClick={() => {
                    toggle();
                    setHelpModal(!helpModal);
                  }}
                  style={{ borderColor: "#303030" }}
                >
                  <img src={HelpIcon} alt="help" className="text-xl mr-2" />
                  Help
                </NavLink>



                <NavLink
                  to="/login"
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  style={{ borderColor: "#303030" }}
                  onClick={() => localStorage.clear()}
                >
                  <RiLogoutBoxLine className="text-xl mr-2" color="#FEA500" /> Log Out
                </NavLink>
                <NavLink
                  to="#"
                  className="flex items-center text-lg text-[#B5B5B5] py-[15px] link_style"
                  style={{ borderColor: "#303030" }}
                  onClick={handleDeleteClick} 
                >
                  <FontAwesomeIcon icon={faTrashAlt} color="#fea500b8" className="mr-2" />
                  Delete Account
                </NavLink>

                <div className="flex items-center justify-center termsAandCondition">
                  <Link
                    className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                    to="/Agreement"
                    reloadDocument={true}
                  >
                    User Agreement
                  </Link>
                  <span className="text-[#dddddd] mt-[21px]">|</span>
                  <Link
                    className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                    to="/PrivacyPolicy"
                    reloadDocument={true}
                  >
                    Privacy Policy
                  </Link>
                  <span className="text-[#dddddd] mt-[21px]">|</span>
                  <Link
                    className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                    to="/AcceptableUsePolicy"
                    reloadDocument={true}
                  >
                    Acceptable Use Policy
                  </Link>
                  <span className="text-[#dddddd] mt-[21px]">|</span>
                  <Link
                    className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2] "
                    to="/Disclaimer"
                    reloadDocument={true}
                  >
                    Disclaimer
                  </Link>
                </div>

                {/* <Footer  /> */}
              </nav>
            </>
          )}
        </div>
      </div>

    </React.StrictMode>
  );
};

export default MobileNavBar;
