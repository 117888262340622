import React from "react";

// style
import classes from "./AccountActivation.module.css";

// components
import CardDiv from "../UI/CardDiv/CardDiv";
import NavigateButton from "./NavigateButton/NavigateButton";

const AccountActivation = (props) => {
  const { heading, content, btnConfig, img, particularImg } = props;

  const buttons = btnConfig.map(({ id, name, to, noBg,onClick }) => (
    <NavigateButton key={id} name={name} to={to} noBg={noBg}onClick={onClick} />
  ));

  return (
    <CardDiv boxWidth={props.boxWidth} Margin={props.Margin}>
      <div
        className={`${classes["activation"]} wrapper-ele ${particularImg} text-center`}
        style={{
          maxWidth: props.boxWidth ?? "",
        }}
      >
        <img src={img} alt="successImage" className={`m-auto`} />
        <p> {heading}</p>
        <>{content}</>
        <div className={`${classes["btn-group"]}`}>{buttons}</div>
      </div>
    </CardDiv>
  );
};

export default AccountActivation;
