import React, { useState, useEffect } from "react";
import { startLoading, stopLoading } from "../Store/IdentitySlice";
import { get, post } from "../api/api";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import StorageService from "../services/StorageService";
import axios from "axios";

const ClickButton = () => {
  const dispatch = useDispatch();
  const [on, setOn] = useState(false);
  let userId = StorageService?.getValue("userId")

  useEffect(() => {
    getUserData()
  }, []);

  const getUserData = async () => {
    dispatch(startLoading());
    try {
      const token = StorageService.getJwt();
      const response = await axios.post(
        `${StorageService?.baseURL}/Identity/GetUser?userId=${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        const userData = response?.data?.user;
        dispatch(stopLoading());
        setOn(userData?.isTwoFactorEnabled)
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      dispatch(stopLoading());
    }
  };


  const handleClick = () => {
    setOn(!on);
    let val = !on;
    saveAuthentication(val);
  }
  
  const saveAuthentication = async (val) => {
    dispatch(startLoading());
    try {
      let res = await post(`/Identity/ToggleTwoFactorAuth?UserId=${userId}&EnableTwoFactor=${val}`)
      let data = await res.json();
      toast.success("Authentication created successfully!");
    } catch (error) {
      console.error("Error fetching top trending posts:", error);
      dispatch(stopLoading());
    }
    
  };

  console.log(on, "on");
  return (
    <div>
      <button
        onClick={handleClick}
        className={
          on
            ? "bg-primary w-[60px] h-5 rounded-full relative transition-all duration-300 ease-in-out border-primary"
            : "bg-[#000] w-[60px] h-5 rounded-full relative transition-all duration-300 ease-in-out border-[#fff]"
        }
        style={{ borderWidth: "1px", borderStyle: "solid" }}
      >
        <span
          className={
            on
              ? "w-[14px] h-[14px] bg-whitew-[14px] h-[14p bg-white block rounded-full absolute top-1/2 left-[49px] -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-in-out"
              : "w-[14px] h-[14px] bg-whitew-[14px] h-[14p bg-white block rounded-full absolute top-1/2 left-[11px] -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-in-out"
          }
        ></span>
      </button>
    </div>
  );
};

export default ClickButton;
