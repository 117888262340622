import {
  user1,
  user2,
  user3,
  user4,
  user5,
  user6,
  user7,
  user8,
  user9,
  user10,
  user11,
  user12,
  user13,
  user14,
  user15,
  user16,
} from "../constant/imagePath";

let userData = [
  {
    id: 1,
    image: user1,
    name: "Williams",
    message: "Hey bro! I just messaged..",
    time: "7:29 P.M",
    status: "Online",
    follower: 250,

    messages: [
      {
        who: "reciver",
        msg: "Hey! I just read your reviews. They are fantastic. Thank you so much for sharing your thoughts ony my post. Appreciated!!",
        time: "7:29 P.M",
      },
      {
        who: "sender",
        msg: "Hi! Thank you very much for your kind words. It means a lot to me.",
        time: "7:32 P.M",
      },
      {
        who: "reciver",
        msg: "My pleasure brother. What's your plan for tomorrow?? Lets meet!!",
        time: "7:32 P.M",
      },
      {
        who: "sender",
        msg: "Umm! Not sure but let’s make a plan to meet. What time?",
        time: "7:35 P.M",
      },
      {
        who: "reciver",
        msg: "Great. What’s easy for you??",
        time: "7:35 P.M",
      },
      {
        who: "reciver",
        msg: "What about 6 p.m?? Are you available??",
        time: "7:35 P.M",
      },
      {
        who: "sender",
        msg: "6 PM is too early for me. What about night??",
        time: "7:42 P.M",
      },
      { who: "reciver", msg: "Yessss!! Let’s do it.", time: "7:43 P.M" },
      {
        who: "reciver",
        msg: "What about others? Will they join us?",
        time: "7:43 P.M",
      },
      {
        who: "sender",
        msg: "I’m not sure about others but I will definitely come. :)",
        time: "7:42 P.M",
      },
    ],
  },
  {
    id: 2,
    image: user2,
    name: "George Smith",
    message: "Hey bro! I just messaged..",
    time: "6:23 P.M",
    follower: 240,
    status: "Offline",
  },
  {
    id: 3,
    image: user3,
    name: "Anthony",
    message: "Hey bro! I just messaged..",
    time: "2:23 A.M",
    status: "Online",
    follower: 230,
  },
  {
    id: 4,
    image: user4,
    name: "Tony Parker",
    message: "Hey bro! I just messaged..",
    time: "4:12 P.M",
    status: "Online",
    follower: 220,
  },
  {
    id: 5,
    image: user5,
    name: "Clara",
    message: "Hey bro! I just messaged..",
    time: "2:21 A.M",
    follower: 210,
    status: "Offline",
  },
  {
    id: 6,
    image: user6,
    name: "Olivia",
    message: "Hey bro! I just messaged..",
    time: "12:23 A.M",
    follower: 200,
    status: "Offline",
  },
  {
    id: 7,
    image: user7,
    name: "Henry",
    message: "Hey bro! I just messaged..",
    time: "11:29 P.M",
    status: "Online",
    follower: 150,
  },
  {
    id: 8,
    image: user8,
    name: "Amelia",
    message: "Hey bro! I just messaged..",
    time: "7:29 P.M",
    follower: 170,
    status: "Offline",
  },
  {
    id: 9,
    image: user9,
    name: "Shawn Michael",
    message: "Hey bro! I just messaged..",
    time: "8:52 P.M",
    follower: 140,
    status: "Offline",
  },
  {
    id: 10,
    image: user10,
    name: "Sophia",
    message: "Hey bro! I just messaged..",
    time: "9:29 P.M",
    status: "Online",
    follower: 180,
  },
  {
    id: 11,
    image: user11,
    name: "Ava",
    message: "Hey bro! I just messaged..",
    time: "5:37 P.m",
    status: "Online",
    follower: 155,
  },
  {
    id: 12,
    image: user12,
    name: "Jimmy",
    message: "Hey bro! I just messaged..",
    time: "7:29 P.m",
    follower: 450,
    status: "Offline",
  },
  {
    id: 13,
    image: user13,
    name: "Mark",
    message: "Hey bro! I just messaged..",
    time: "10:50 A.M",
    status: "Online",
    follower: 200,
  },
  {
    id: 14,
    image: user14,
    name: "Tom",
    message: "Hey bro! I just messaged..",
    time: "11:29 P.m",
    follower: 100,
    status: "Offline",
  },
  {
    id: 15,
    image: user15,
    name: "Joe",
    message: "Hey bro! I just messaged..",
    time: "4:29 A.M",
    status: "Online",
    follower: 222,
  },
  {
    id: 16,
    image: user16,
    name: "Grace",
    message: "Hey bro! I just messaged..",
    time: "6:20 P.m",
    status: "Online",
    follower: 350,
  },
];

export default userData;
