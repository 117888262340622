import React, { useRef, useState } from "react";

// react router
import { useNavigate } from "react-router-dom";

// Styling
import "../../style/BeforeLoginMessage.css";

// font awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import StorageService from "../../services/StorageService";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteUserModel = ({ helpModal, setHelpModal }) => {
    const modalRef = useRef();
    const navigate = useNavigate();
    const [confirmText, setConfirmText] = useState("");
    const [checked, setChecked] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    let userId = StorageService?.getValue("userId")


     
  const DeleteAccount = async () => {
    // e.preventDefault();
    try {
      const token = StorageService.getJwt();
      const response = await axios.post(`${StorageService?.baseURL}/Identity/DeleteUser?userId=${userId}`, {},{
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        }
      });

      if (response) {
        toast.success('Your Account successfull deleted!');
        navigate('/login')
      }

    } catch (error) {
      console.error("Error:", error);
    }
  };

    function resetAllValue() {
        setHelpModal(false);

        const closeIconStyle = document.querySelector(".closeIconStyle");
        if (closeIconStyle) {
            closeIconStyle.style.display = "none";
        }
    }

    function onFunction(e) {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            resetAllValue();
        }
    }

    function handleDelete() {
        if (confirmText.trim().toUpperCase() === "DELETE" && checked) {
            DeleteAccount()
        } else {
            alert("Please confirm deletion by typing 'DELETE' and checking the checkbox.");
        }
    }
    function handleCheckboxChange() {
        setChecked(!checked);
        setShowDeleteButton(!showDeleteButton);
    }


    return (
        <div
            className={"postCreate helpModal"}
            style={{
                opacity: helpModal ? 1 : 0,
                cursor: helpModal ? "auto" : "none",
                zIndex: helpModal ? 1111111111111 : -1,
                visibility: helpModal ? "visible" : "hidden"
            }}
            onClick={onFunction}
        >
            <div className="center_div" ref={modalRef}>
                {/* top part */}
                <div className="post_create_top_part">
                    <p></p>
                    {/* close icon */}
                    <div className="close_icon" onClick={resetAllValue}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>

                {/* main part */}
                <div className="main_part">
                    {/* text area */}
                    <div className="text_area">
                        <p>
                            <span className="text-white">
                                Confirm deletion of your account by typing 'DELETE' in the text box below.
                            </span>
                        </p>

                        <div className="post_title">
                            <input
                                type="text"
                                className="text transform uppercase"
                                placeholder="Type 'DELETE' to confirm"
                                value={confirmText}
                                onChange={(e) => setConfirmText(e.target.value)}
                                disabled={checked}
                            />
                        </div>


                        <div style={{ height: '20px' }}></div>

                        {/* checkbox for data understanding */}

                        {(confirmText === "delete" || confirmText === "DELETE")&&<div className="checkbox">
                            <input
                                type="checkbox"
                                id="dataUnderstanding"
                                checked={checked}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="dataUnderstanding" style={{ color: "white", marginLeft:'10px' }}>
                                I understand that all data stored with FilmWaffle will be deleted permanently and cannot be restored.
                            </label>
                        </div>}

                        <div style={{ height: '20px' }}></div>

                        {/* button */}
                        <div className="btn_group" style={{ marginTop: "2rem", opacity: checked ? 1 : 0 }}>
                            <button
                                onClick={handleDelete}
                                className="bg-primary w-full h-[50px] mb-4 text-black rounded-md flex items-center justify-center"
                                disabled={!checked}
                            >
                                Delete Account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteUserModel;
