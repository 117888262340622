import React, { useState, useRef, useEffect } from 'react';
import './ReadMore.css';

const Read_More = ({ content, pageNavigateFunction, sepratePage, homeView }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const textRef = useRef(null);

    const lineHeight = 1.5; // Line height
    const maxLines = 10;    // Max lines to show on desktop before "Read More"
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Mobile view detection
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const checkTextHeight = () => {
            const textHeight = textRef.current.scrollHeight;
            const maxHeightInPixels = parseFloat(getComputedStyle(textRef.current).fontSize) * lineHeight * maxLines;
            if (textHeight > maxHeightInPixels) {
                setIsTruncated(true);
            }
        };
        checkTextHeight();
        window.addEventListener('resize', checkTextHeight);
        return () => window.removeEventListener('resize', checkTextHeight);
    }, [content]);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const convertToLinks = (inputText) => {
        const specificDomains = [
            "filmwaffle.com", "disneyplus.com", "netflix.com", "appletv.com",
            "paramountplus.com", "shudder.com", "youtube.com", "primevideo.com",
            "nowtv.com", "imdb.com", "youtu.be", "wikipedia.org",
        ];

        const specificDomainPattern = new RegExp(`\\b(https?:\\/\\/)?(www\\.)?(${specificDomains.join("|")})(\\/[^\\s]*)?\\b`, "gi");

        const linkifiedText = inputText.replace(specificDomainPattern, (match) => {
            const url = match.startsWith("http") ? match : `https://${match}`;
            return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="linkified">${match}</a>`;
        });

        return { __html: linkifiedText };
    };

    // Function to truncate content after 10 spaces
    const truncateContentBySpaces = (text, spaceCount) => {
        let spaceCounter = 0;
        let index = text.length;
        for (let i = 0; i < text.length; i++) {
            if (text[i] === ' ') {
                spaceCounter++;
            }
            if (spaceCounter === spaceCount) {
                index = i;
                break;
            }
        }
        return text.substring(0, index);
    };

    // Check if the content should be truncated based on spaces (for mobile view)
    const truncatedContent = isMobile && !isExpanded 
        ? truncateContentBySpaces(content, 10) + '...' 
        : content;

    return (
        <div>
            <div
                className="text-content"
                ref={textRef}
                onClick={() => pageNavigateFunction()}
                style={{
                    maxHeight: isExpanded ? 'none' : `${maxLines * lineHeight}em`,
                    overflow: 'hidden',
                    cursor: sepratePage || homeView ? "auto" : "pointer",
                    color: "#b2b2b2",
                    fontSize: "0.9rem",
                    fontFamily: "sans-serif",
                    fontWeight: 300,
                    whiteSpace: "pre-wrap",
                    lineHeight: `${lineHeight}em`,
                }}
                dangerouslySetInnerHTML={convertToLinks(truncatedContent)}
            ></div>
            {(isTruncated || (isMobile && !isExpanded)) && (
                <button className='readMoreBtn' onClick={toggleReadMore}>
                    {isExpanded ? 'Read Less' : 'Read More'}
                </button>
            )}
        </div>
    );
};

export default Read_More;
