import React, { useState } from "react";

// Style
import classes from "./OTPVerification.module.css";

// Components
import OTPButton from "./OTPButton/OTPButton";
import Divider from "../Divider/Divider";
import CardDiv from "../../UI/CardDiv/CardDiv";
import FormContent from "../FormContent/FormContent";
import OtpInput from "react-otp-input";
import Header from "../../Header/Header";

const OTPVerification = () => {
  const [otp, setOtp] = useState("");

  const handleChange = (e) => {
    setOtp(e);
  };
  return (
    <React.StrictMode>
      <Header loginAndSignup={true} messageShow={true} sarchBarShow={false} />
      <CardDiv
        className={classes["otp-container"]}
        boxWidth="390px"
        Margin="0rem auto"
      >
        <form action="#">
          <FormContent
            heading="OTP Verification"
            content="Sent to Your Email"
            paddingTop="0"
          />

          <div className={`mt-2rem ${classes["otp-inp-container"]}`}>
            <p>
              <span style={{ color: "#868b90" }}>
                Enter One Time Password*
              </span>
            </p>
            <OtpInput
              className={classes["otp-inp"]}
              value={otp}
              isInputNum={true}
              onChange={handleChange}
              numInputs={6}
            />
          </div>

          <div className={classes["otp-resend"]}>
            <p style={{ fontWeight: "lighter" }}>
              <span style={{ color: "#fff" }}>
                Resend in {"  "}
              </span>
              <span style={{ fontWeight: "400" }}>0:59</span>
            </p>
          </div>

          <div className={classes["otp-btn-container"]}>
            <OTPButton className={`${classes["resendOtpStyle"]}`}>Resend OTP</OTPButton>
            <OTPButton className={`${classes["bg-yellow"]}`}>
              <span style={{ color: "#000" }}>
                Verify OTP
              </span>
            </OTPButton>
          </div>

          <Divider topMargin={"2.2rem"}></Divider>
          <div className={classes["login-link"]}>
            <button className={classes["loginWithAnotherAccount"]}>
              <span style={{ color: "#ffff" }}>
                Login with another account
              </span>
            </button>
          </div>
        </form>
      </CardDiv>
    </React.StrictMode>
  );
};

export default OTPVerification;
