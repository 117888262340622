import React, { useEffect, useRef, useState } from "react";

// component
import MochatPostSection from "../../components/post/MochatPostSection";

// images
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get, post } from "../../api/api";
import { updateTrendingPostData, updateTrendingPostDataOnSearch } from "../../Store/TrendingPageSlice";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import { SyncLoader } from "react-spinners";

const Post = () => {

  const {postData} = useSelector((state) => state.Trending);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noData, setNData] = useState(true);
  const dispatch = useDispatch();
  const sectionRef = useRef();
    const datas = useRef(postData);
    const pageNo = useRef(page);
    datas.current = postData;
    pageNo.current = page;
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          const totalCount = [...new Set(datas?.current?.map((item)=> item?.totalRecordCount))];
          if (entry.isIntersecting && !loading && pageNo.current < Math?.ceil(totalCount[0] / 10)) {
            getTrandingPosts(pageNo.current + 1);
            setPage((prevPage) => prevPage + 1);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );
  
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
  
      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }, []);
  useEffect(()=>{
    dispatch(updateTrendingPostData({ data: [], route: "Top" }));
    const timer = setTimeout(() => {
      getTrandingPosts(page)
      setNData(false)
    }, 2000);

    return () => clearTimeout(timer);
   
  },[!postData])
  const getTrandingPosts = async (pageNumber) => {
    setLoading(true)
    try {
      let res = await post(`/Post/ReadUserPosts?pageNumber=${pageNumber}&pageSize=10`);

      let data = await res.json();
      if (res && data) {
        dispatch(updateTrendingPostData({ data, route: "Post" }))
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching trending posts:", error);
      setLoading(false)
    }
  };

  const navigate = useNavigate();

  return (
    <React.StrictMode>
      {/* // {top 5 button section} */}
      <div className="flex items-center justify-around sm:justify-start py-8 sm:gap-4 BtnGroupStyle">
        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10
                buttonAddBorder"
          onClick={() => navigate("/userProfileView")}
        >
          <span className="text-primary font-primaryFont font-normal sm:block">
            Posts
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate("/userProfileView/saved")}
        >
          <span className="text-white font-primaryFont font-normal sm:block">
            Saved
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate("/userProfileView/groups")}
        >
          <span className="text-white font-primaryFont font-normal sm:block">
            Groups
          </span>
        </button>
      </div>
      {/* {Mochat post section} */}
      {postData?.length > 0 &&
        postData?.map((value, index) => {
          return (
            <React.StrictMode key={index}>
              <MochatPostSection data={value} addMargin={false} url={true}/>
            </React.StrictMode>
          );
        })}
         <div ref={sectionRef} className="flex justify-center items-center">.{(loading||noData)&&<SyncLoader color="#fea500" loading={true} height={15} width={5} />}</div>
        {postData?.length===0&&!noData&&<p className="empty">No post found...</p>}
    </React.StrictMode>
  );
};

export default Post;
