import React, { useState } from "react";

// react router
import { NavLink, Outlet } from "react-router-dom";

// components
import DropDown from "./DropDown";
import { Header } from "../../components";
import { Message } from "../../components/Models/Index";

// Data
import { navItems } from "../../assets/data/navItems";

// icons
import { SlArrowRight } from "react-icons/sl";
import { FaBars } from "react-icons/fa";

// style
import "../../style/Settings.css";
import { crossIcon } from "../../constant/IconsPath";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  return (
    <React.StrictMode>
      <Header loginAndSignup={false} messageShow={true} />
      <div className="container mx-auto pb-[8] mainContainer containerContent homePageStyle" style={{justifyContent: "flex-start"}}>
        <div className="mt-4 mx-5 flex items-center justify-between settinTopBar">
          <h1 className="text-[30px] sm:text-[44px] font-bold sm:mb-5 bg-hero-pattern settingHeading">
            <span className="text-white">
              Settings
            </span>
          </h1>
          {toggle ? (
            // <GrClose
            //   onClick={() => setToggle(!toggle)}
            //   className="text-2xl block sm:hidden"
            //   style={{ cursor: "pointer" }}
            //   color="#fff"
            //   colorProfile={"#fff"}
            // />
            <img src={crossIcon} alt="close" width={"22px"} onClick={() => setToggle(!toggle)} />
          ) : (
            <FaBars
              onClick={() => setToggle(!toggle)}
              className="text-2xl block sm:hidden"
              style={{ cursor: "pointer", color: "#fff" }}
            />
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-4 sm:gap-8 mt-4 sm:mt-0">
          <div className="bg-[#131313] settingLeftSide">
            <h1 className="mx-5 mt-[0.25rem] mb-[10.27px]" style={{
              fontSize: "44px",
              fontWeight: "700"
            }}>

              Settings
            </h1>
            <div className="mx-5 pt-6 border-t-2 border-solid border-slate-200 hidden sm:block" style={{ borderTop: "1px solid #292b2d" }}>
              <nav>
                {navItems.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.url}
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary text-[18px] font-medium flex items-center justify-between mb-8"
                        : "text-white text-[18px] font-medium flex items-center justify-between mb-8"
                    }
                    onClick={() => setOpen(false)}
                  >
                    {item.name}
                    <SlArrowRight className="text-sm" />
                  </NavLink>
                ))}

                {/* Security dropdown menu */}
                <NavLink
                  to="security/changePassword"
                  className={
                    open
                      ? "text-primary text-[18px] font-medium flex items-center justify-between mb-8 relative"
                      : "text-white text-[18px] font-medium flex items-center justify-between mb-8 relative"
                  }
                  onClick={() => setOpen(true)}
                >
                  Security
                  <SlArrowRight
                    className={
                      open
                        ? "text-sm rotate-90 transition-all duration-300 ease-in-out"
                        : "text-sm transition-all duration-300 ease-in-out"
                    }
                  />
                  <DropDown open={open} />
                </NavLink>
              </nav>
            </div>

            {/* mobile menu */}
            <div
              className={
                toggle
                  ? "px-5 pt-6 border-t-2 border-solid border-slate-200 block absolute bg-[#131313] w-[100%] z-[50] h-[100%] border-[#414141]"
                  : "hidden mx-5 pt-6 border-t-2 border-solid border-slate-200"
              }
            >
              <nav>
                {navItems.map((item, index) => (
                  <NavLink
                    key={index}
                    to={item.url}
                    className={({ isActive }) =>
                      isActive
                        ? "text-primary text-[18px] font-medium flex items-center justify-between mb-8"
                        : "text-white text-[18px] font-medium flex items-center justify-between mb-8"
                    }
                    onClick={() => {
                      setOpen(false);
                      setToggle(false);
                    }}
                  >
                    {item.name}
                    <SlArrowRight className="text-sm" />
                  </NavLink>
                ))}

                {/* Security dropdown menu */}
                <NavLink
                  to="security/changePassword"
                  className={
                    open
                      ? "text-primary text-[18px] font-medium flex items-center justify-between mb-8 relative"
                      : "text-white text-[18px] font-medium flex items-center justify-between mb-8 relative"
                  }
                  onClick={() => setOpen(true)}
                >
                  Security
                  <SlArrowRight
                    className={
                      open
                        ? "text-sm rotate-90 transition-all duration-300 ease-in-out"
                        : "text-sm transition-all duration-300 ease-in-out"
                    }
                  />
                  <DropDown open={open} toggleNav={() => setToggle(!toggle)} />
                </NavLink>
              </nav>
            </div>
          </div>

          <div className="col-span-3 settingCenterPart">
            <Outlet />
          </div>
        </div>

        {/* message box */}
        <Message />
      </div>
    </React.StrictMode>
  );
};

export default Settings;
