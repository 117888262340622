import React from "react";
import { Link } from "react-router-dom";
import classes from "./NavigateButton.module.css";
const NavigateButton = (props) => {
  const { name, to, noBg,onClick } = props;
  return (
    <Link
      to={to}
      onClick={name == "Resend Email" ? onClick : null}
      className={`${classes.button} ${noBg && classes["transparent-bg"]}`}
    >
      {name}
    </Link>
  );
};

export default NavigateButton;
