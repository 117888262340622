import React, { useState, useRef } from "react";

// Redux Toolkit
import { useDispatch } from "react-redux";
import { editReply, editedComment } from "../../Store/PostsSlice";

// Emoji Picker
import EmojiPicker from "emoji-picker-react";

// Icon
import { logo4 } from "../../constant/imagePath";
import { DarkEmojiIcon } from "../../constant/IconsPath";

// Style
import "../../style/editComment.css";
import { useEffect } from "react";
import axios from "axios";
import StorageService from "../../services/StorageService";
import { toast } from "react-toastify";

const EditComment = ({
  comment,
  UpdateComment,
  updateState,
  setIsEdit,
  styleApply,
  name,
  commentId,
  postId,
  parentId,
  replyId,
  getNewComentes
}) => {
  const [updateInputValue, setUpdateInputValue] = useState(comment);
  const [isFieldEmpty, setIsFieldEmpty] = useState(true);
  const EditInputFieldValue = useRef();
  const EmojiIcon1 = useRef();
  const [emojiShow, setEmojiShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const handler1 = (e) => {
      if (EmojiIcon1.current && !EmojiIcon1.current.contains(e.target)) {
        setEmojiShow(false);
      }
    };
    document.addEventListener("mousedown", handler1);
    return () => {
      document.removeEventListener("mousedown", handler1);
    };
  }, []);

  const automaticSetInputFiledHeight = (element, defaultHeight) => {
    if (element) {
      if (element.target.value.trim().length !== 0) {
        setIsFieldEmpty(false);
      } else {
        setIsFieldEmpty(true);
      }

      setUpdateInputValue(element.target.value);

      const target = element?.target ?? element;
      target.style.height = defaultHeight;
      target.style.height = `${target.scrollHeight}px`;
    }
  };

  const automaticSetInputFiledHeightWithEmoji = (element, defaultHeight) => {
    if (element) {
      setIsFieldEmpty(false);

      setUpdateInputValue((prevValue) => `${prevValue} ${element.emoji}`);

      const Edit_typingArea = document.querySelector(".edit_typingArea");
      Edit_typingArea.style.height = defaultHeight;
      Edit_typingArea.style.height = `${Edit_typingArea.scrollHeight}px`;
    }
  };

  const cancelEdit = () => {
    setIsEdit(false);
  };

  const editReplyAddFunction = async () => {
    try {
      const token = StorageService.getJwt();
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.post(
        `${StorageService?.baseURL}/Comment/UpdateComment/${commentId}`,
        { content: updateInputValue },
        header
      );

      if (res.status === 200) {
        if (updateState) {
          updateState();
        }getNewComentes(postId)
        // toast.success("Comment updated successfully");
        setIsEdit(false);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      toast.error("Error updating comment. Please try again later.");
    }
  };

  return (
    <div
      className="edit_reply"
      style={{
        justifyContent: styleApply?.justifyContent ?? "center",
      }}
    >
      {/* left side */}
      <div
        className="edit_reply_leftSide"
        style={{
          width: styleApply?.imageSize ?? "38px",
        }}
      >
        <img src={logo4} alt="user icon" width={"auto"} height={"auto"} />
      </div>

      {/* right side */}
      <div
        className={`edit_reply_rightSide ${styleApply?.responsiveWidth}`}
        style={{
          width: styleApply?.width ?? "calc(100% - 36px)",
        }}
      >
        <textarea
          type="text"
          placeholder="Add a reply..."
          className="edit_typingArea"
          value={updateInputValue}
          onChange={(event) => {
            automaticSetInputFiledHeight(event, "35px");
          }}
          ref={EditInputFieldValue}
        />

        <div className="editReplyBottomPart">
          {/* emoji */}
          <div className="emojiIcon" ref={EmojiIcon1}>
            {/* emoji icon */}
            <img
              src={DarkEmojiIcon}
              alt="Emoji Icon"
              onClick={() => setEmojiShow((val) => !val)}
              width={"auto"}
              height={"auto"}
            />

            {/* emoji */}
            {emojiShow && (
              <EmojiPicker
                onEmojiClick={(event) => {
                  automaticSetInputFiledHeightWithEmoji(event, "35px");
                }}
                autoFocusSearch={false}
                searchDisabled
                width="100%"
                className="emojiPicker"
                lazyLoadEmojis={true}
              />
            )}
          </div>

          {/* buttons */}
          <div className="editFuntionButton">
            <button onClick={cancelEdit}>Cancel</button>
            <button
              className="active1"
              onClick={isFieldEmpty ? null : editReplyAddFunction}
              disabled={isFieldEmpty}
              style={{
                opacity: isFieldEmpty ? 0.6 : 1,
                cursor: isFieldEmpty ? "not-allowed" : "pointer",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditComment;
