import { createSlice } from "@reduxjs/toolkit";
import { member1, member2, member3, member4, member5, member6 } from "../constant/imagePath";
import { MakeAdminIcon } from "../constant/IconsPath";

const UsersSlice = createSlice({
    name: "users",
    initialState: [
        {
            id: 1,
            img: member1,
            name: "Man-Burns",
            detail: "Joined on yesterday",
        },
        {
            id: 2,
            img: member2,
            name: "Man-Burns",
            detail: "Joined on yesterday",
        },
        {
            id: 3,
            img: member3,
            name: "Man-Burns",
            detail: "Joined on yesterday",
        },
        {
            id: 4,
            img: member4,
            name: "Man-Burns",
            detail: "Joined on yesterday",
        },
        {
            id: 5,
            img: member5,
            name: "Man-Burns",
            detail: "Joined on yesterday",
        },
        {
            id: 6,
            img: member6,
            name: "Man-Burns",
            detail: "Joined on yesterday",
        }
    ],
    reducers: {
        removeMember(state, action) {
            if (action.payload) {
                return state.filter((val) => val.id !== action.payload);
            }
            return state;
        },
        makeAdmin(state, action) {
            const targetId = action.payload;

            if (targetId) {
                return state.map((val) =>
                    val.id === targetId ? { ...val, icon: MakeAdminIcon } : val
                );
            }

            return state;
        },
        removeAdmin(state, action) {
            const targetId = action.payload;

            if (targetId) {
                return state.map((val) =>
                    val.id === targetId ? {
                        id: val.id,
                        img: val.img,
                        name: val.name,
                        detail: val.detail,
                    } : val
                );
            }

            return state;
        },
    },
});

export const {
    removeMember,
    makeAdmin,
    removeAdmin
} = UsersSlice.actions;

export default UsersSlice.reducer;
