import { createSlice } from "@reduxjs/toolkit";
import MuchatPostData from "../data/MuchatPostData";

const postsSlice = createSlice({
  name: "posts",
  initialState: MuchatPostData,
  reducers: {
    addComment(state, action) {
      const { parentId, commentDetail } = action.payload;
      const post = state.find(value => value.id === parentId);
      if (post) {
        if (!post.comments) {
          post.comments = [];
        }
        post.comments.unshift(commentDetail);
      }
    },
    editedComment(state, action) {
      const { parentId, commentId, updatedComment } = action.payload;
      const post = state.find(value => value.id === parentId);
      if (post && post.comments) {
        const comment = post.comments.find(val => val.id === commentId);
        if (comment) {
          comment.comment = updatedComment;
        }
      }
    },
    deleteComment(state, action) {
      const { parentId, commentId } = action.payload;
      const post = state.find(value => value.id === parentId);
      if (post && post.comments) {
        post.comments = post.comments.filter(val => val.id !== commentId);
      }
    },
    replyAdd(state, action) {
      const { parentId, commentId, reply } = action.payload;
      const post = state.find(value => value.id === parentId);
      if (post && post.comments) {
        const comment = post.comments.find(val => val.id === commentId);
        if (comment) {
          if (!comment.reply) {
            comment.reply = [];
          }
          comment.reply.push(reply);
        }
      }
    },
    editReply(state, action) {
      const { parentId, commentId, replyId, updatedReply } = action.payload;
      const post = state.find(value => value.id === parentId);
      if (post && post.comments) {
        const comment = post.comments.find(val => val.id === commentId);
        if (comment && comment.reply) {
          const reply = comment.reply.find(v => v.id === replyId);
          if (reply) {
            reply.reply = updatedReply;
          }
        }
      }
    },
    deleteReply(state, action) {
      const { parentId, commentId, replyId } = action.payload;
      const post = state.find(value => value.id === parentId);
      if (post && post.comments) {
        const comment = post.comments.find(val => val.id === commentId);
        if (comment && comment.reply) {
          comment.reply = comment.reply.filter(v => v.id !== replyId);
        }
      }
    },
  },
});

export const {
  addComment,
  editedComment,
  deleteComment,
  replyAdd,
  editReply,
  deleteReply,
} = postsSlice.actions;

export default postsSlice.reducer;