import {
  banner3,
  banner4,
  sr_user1,
  sr_user2,
  sr_user3,
  sr_user4,
  sr_user5,
  sr_user6,
  sr_user7,
} from "../constant/imagePath";

let users = [
  {
    id: 1,
    name: "George Smith",
    img: sr_user1,
    followers: 350,
    following: 150,
    post: 9,
    description:
      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most.",
    bgCover: banner3,
    type: "Members"
  },
  {
    id: 2,
    name: "George Smith",
    img: sr_user2,
    followers: 241,
    following: 150,
    post: 9,
    description:
      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most.",
    bgCover: banner4,
    type: "Groups"
  },
  {
    id: 3,
    name: "George Smith",
    img: sr_user3,
    followers: 280,
    following: 2,
    post: 9,
    description:
      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most.",
    bgCover: banner4,
    type: "Posts"
  },
  {
    id: 4,
    name: "George Smith",
    img: sr_user4,
    followers: 55,
    following: 455,
    post: 9,
    description:
      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most.",
    bgCover: banner4,
    type: "Members"
  },
  {
    id: 5,
    name: "George Smith",
    img: sr_user5,
    followers: 401,
    following: 545,
    post: 9,
    description:
      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most.",
    bgCover: banner4,
    type: "Groups"
  },
  {
    id: 6,
    name: "George Smith",
    img: sr_user6,
    followers: 528,
    following: 12,
    post: 9,
    description:
      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most.",
    bgCover: banner4,
    type: "Posts"
  },
  {
    id: 7,
    name: "George Smith",
    img: sr_user7,
    followers: 97,
    following: 20,
    post: 9,
    description:
      "They say money can’t buy happiness… but it can buy yoga classes. And that’s basically the same thing. If you want to know the correct way to perform an exercise, the answer is: Whatever hurts most.",
    bgCover: banner4,
    type: "Members"
  },
];

export default users;
