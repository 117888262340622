import { createSlice } from "@reduxjs/toolkit";
import { member1, user1, user10, user11, user12, user13, user14, user15, user16, user2, user3, user4, user5, user6, user7, user8, user9 } from "../constant/imagePath";

const NotificationSlice = createSlice({
    name: "notification",
    initialState: [
        {
            id: 1,
            img: member1,
            name: "Man-Burns",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read"
        },
        {
            id: 2,
            img: user1,
            name: "George Smith",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read"
        },
        {
            id: 3,
            img: user2,
            name: "Williams",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read"
        },
        {
            id: 4,
            img: user3,
            name: "Anthony",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read"
        },
        {
            id: 5,
            img: user4,
            name: "Tony Parker",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "unread"
        },
        {
            id: 6,
            img: user5,
            name: "Clara",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 7,
            img: user6,
            name: "Olivia",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 8,
            img: user7,
            name: "Henry",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 9,
            img: user8,
            name: "Amelia",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "unread",
        }
        ,
        {
            id: 10,
            img: user9,
            name: "Shawn Michael",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 11,
            img: user10,
            name: "Sophia",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 12,
            img: user11,
            name: "Ava",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 13,
            img: user12,
            name: "Jimmy",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 14,
            img: user13,
            name: "Mark",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 15,
            img: user14,
            name: "Tom",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 16,
            img: user15,
            name: "Joe",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "read",
        },
        {
            id: 17,
            img: user16,
            name: "Grace",
            msg: "mentioned you in a comment.",
            time: "9 hours ago",
            status: "unread",
        }
    ],
    reducers: {
        removeNotification(state, action) {
            if (action.payload) {
                return state.filter((val) => val.id !== action.payload);
            }
            return state;
        },
        allRead(state, action) {
            return state.map((val) => {
                return { ...val, status: "read" }
            })
        },
        isReadNotification(state, action) {
            const { id, isRead } = action.payload;

            if (typeof id !== "undefined" && typeof isRead === "boolean") {
                return state.map((val) => {
                    if (val.id === id) {
                        return { ...val, status: isRead ? "read" : "unread" };
                    } else {
                        return val;
                    }
                });
            }

            return state;
        }
    },
});

export const {
    removeNotification,
    allRead,
    isReadNotification
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
