import React from "react";

// Components
import { ClickButton } from "../../components";

const EmailAndNotifications = () => {
  return (
    <div className="border-2 border-solid border-slate-200 m-5 sm:m-0 sm:mb-6 EmailAndNotification bg-[#131313]" style={{ border: "1px solid rgba(138, 152, 164, 0.47)" }}>
      <div className="bg-[#c8ced436] px-[30px] py-5 editProfileHeading">
        <h3 className="text-[20px] font-bold text-[#fff]">Email & Notifications</h3>
      </div>

      <div className="my-4 sm:my-9 mx-4 sm:mx-16 sm:w-3/4">
        {/* Send Email  */}
        <h3 className="text-[20px] font-bold mb-5 sm:mb-7 text-[#fff]">Send Email When:</h3>
        <div className="flex items-center justify-between">
          <p className="text-lg font-light mr-5 sm:mr-0">
            An account is logged in with a new device or a browser
          </p>
          <ClickButton isOn={true} />
        </div>

        <div className="flex items-center justify-between my-7 sm:my-4">
          <p className="text-lg font-light mr-5 sm:mr-0">
            Someone followed you
          </p>
          <ClickButton isOn={false} />
        </div>

        <div className="flex items-center justify-between">
          <p className="text-lg font-light mr-5 sm:mr-0">
            Someone invited you to join group
          </p>
          <ClickButton isOn={false} />
        </div>

        {/* Send Notification */}
        <h3 className="text-[20px] font-bold mb-5 sm:mb-7 mt-5 text-[#fff]">
          Send Notification When:
        </h3>
        <div className="flex items-center justify-between">
          <p className="text-lg font-light mr-5 sm:mr-0">
            An account is logged in with a new device or a browser
          </p>
          <ClickButton isOn={true} />
        </div>

        <div className="flex items-center justify-between my-7 sm:my-4">
          <p className="text-lg font-light mr-5 sm:mr-0">
            Someone followed you
          </p>
          <ClickButton isOn={true} />
        </div>

        <div className="flex items-center justify-between pb-3 sm:pb-0">
          <p className="text-lg font-light mr-5 sm:mr-0">
            Someone invited you to join group
          </p>
          <ClickButton isOn={false} />
        </div>
      </div>
    </div>
  );
};

export default EmailAndNotifications;
