import React, {useRef, useState} from "react";

// font Awesome
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";

// style
import classes from "./FormInput.module.css";
import {AiOutlineInfo} from "react-icons/ai";

const FormInput = (props) => {
const [dateInfo,setdateInfo]=useState(false)
    const {
        label = "label", id = 0, type = "text", isInstructionShow, onChange = () => {
        },confirmPasswordError,newPasswordError
    } = props;
    const [flag, setFlag] = useState(false);
    const [isEyeOpen, setIsEyeOpen] = useState(faEyeSlash);
    const inputRef = useRef();

    const eyeIcon = () => {
        if (inputRef.current.type === "password") {
            inputRef.current.type = "text";
            setIsEyeOpen(faEye);
        } else {
            inputRef.current.type = "password";
            setIsEyeOpen(faEyeSlash);
        }
    };
    return (
        <div className={`${classes["form-control"]} ${props.className}`}>
            
            <label htmlFor={id}>{label} <span style={{color:"red"}}>*</span>

                {isInstructionShow &&
                    <React.StrictMode>
                        <AiOutlineInfo
                            className="h-[16px] w-[16px] rounded-full text-[#000] bg-[#D1D1D1] sm:inline infoIcon"
                            onMouseOver={() => setFlag(true)}
                            onMouseOut={() => setFlag(false)}
                        />
                        {/* info */}
                        {flag && (
                            <div className="infoStyle" style={{top: "-1.7rem"}}>
                                <p>
                                    1. At least 8 characters < br/>
                                    2. Contains a character, number and special character.
                                </p>
                            </div>
                        )}
                    </React.StrictMode>
                }
            </label>

            <input
                type={type}
                id={id}
                ref={inputRef}
                style={{padding: "0.25rem 35px 0.25rem 0.5rem", borderRadius: "6px"}}
                className="inputField"
                onChange={onChange}
            />
            {/* {eyeShow && type === "password" && ( */}
            <FontAwesomeIcon
                icon={isEyeOpen}
                onClick={eyeIcon}
                style={{
                    position: "absolute",
                    right: "0.6rem",
                    bottom: "1.3rem",
                    color: "#ff9600",
                    fontSize: ".9rem",
                }}
            />
            {/* )} */}
           
            {newPasswordError&&<span style={{
                    position: "absolute",
                    left: "0.6rem",
                    top: "-20px",
                    color: "red",
                    fontSize: ".9rem",
                }}> <AiOutlineInfo
                className="h-[16px] w-[16px] rounded-full text-[#000] bg-[#D1D1D1] sm:inline infoIcon"
                onMouseOver={() => setdateInfo(true)}
                onMouseOut={() => setdateInfo(false)}
              />
              {dateInfo && (
                <div className="infoStyle" style={{ top: "-3.1rem" }}>
                  <p>
                    <span>{newPasswordError}</span>
                  </p>
                </div>
              )}{newPasswordError.length>30?`${newPasswordError.slice(0,30)}...`:newPasswordError}</span>}
            {confirmPasswordError&&<span style={{
                    position: "absolute",
                    left: "0.6rem",
                    top: "-20px",
                    color: "red",
                    fontSize: ".9rem",
                }}>{confirmPasswordError}</span>}
        </div>
    );
};

export default FormInput;
