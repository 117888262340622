import React from "react";
import { useState } from "react";
import {
  ChatBoxIcon,
  TopArrow1Icon,
  DownArrowIcon,
  EmojiIcon,
  Send1Icon,
} from "../../constant/IconsPath";

import { useRef } from "react";
import EmojiPicker from "emoji-picker-react";
import { user1 } from "../../constant/imagePath";

import ReactScrollableFeed from "react-scrollable-feed";

import "../../style/MessageBox.css";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../Store/MessagesSlice";

// awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { addChat, isChatShow } from "../../Store/ChatSlice";

const Message = () => {
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chat);
  const data = useSelector((state) => state.messages[chat.user]);
  const [msgShow, setMsgShow] = useState(false);
  // set the current input field value
  let inputFieldMessage = useRef("");
  // set user input value in this state
  let [inputValue, setInputValue] = useState("");
  // taggle the emoji
  let [showEmoji, setShowEmoji] = useState(false);

  // time
  let date = new Date();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  var prepand = hour >= 12 ? " PM " : " AM ";

  hour = hour >= 12 ? hour - 12 : hour;
  if (hour === 0 && prepand === " PM ") {
    if (minute === 0 && second === 0) {
      hour = 12;
      prepand = " Noon";
    } else {
      hour = 12;
      prepand = " PM";
    }
  }
  if (hour === 0 && prepand === " AM ") {
    if (minute === 0 && second === 0) {
      hour = 12;
      prepand = " Midnight";
    } else {
      hour = 12;
      prepand = " AM";
    }
  }

  minute = minute < 10 ? `0${minute}` : minute;

  let time = `${hour}:${minute} ${prepand}`;

  const msgSendFunction = () => {
    if (inputFieldMessage.current.innerText === "") {
    } else {
      let message = {
        id: chat.user,
        who: "sender",
        msg: inputFieldMessage.current.innerText,
        time: time,
      };

      dispatch(add(message));
      // set input empty string
      inputFieldMessage.current.innerText = "";
    }
  };

  const closeChatBox = () => {
    dispatch(addChat(0));
    dispatch(isChatShow(false));
  };

  return (
    <React.StrictMode>
      {/* message box */}
      <div
        className="MessageBox"
        style={{
          height: msgShow ? "360px" : "60px",
          display: chat.isChatShow ? "flex" : "none",
        }}
      >
        <div className="TopMessageBox">
          <div className="left_side">
            <div className="userPicAndStatus">
              <img
                src={data?.image ? data.image : ChatBoxIcon}
                alt="userImage"
                width={"40px"}
              />
              <span className="online"></span>
            </div>
            <p>{data?.name ?? "Gavo Ami"}</p>
          </div>
          <div className="right_side">
            {msgShow ? (
              <img
                src={DownArrowIcon}
                alt="Down Arrow"
                onClick={() => setMsgShow(!msgShow)}
              />
            ) : (
              <img
                src={TopArrow1Icon}
                alt="top arrow"
                onClick={() => setMsgShow(!msgShow)}
              />
            )}
            <FontAwesomeIcon
              icon={faXmark}
              className="CrossIcon"
              onClick={closeChatBox}
            />
          </div>
        </div>

        {/* center box */}
        <div
          className="messages"
          style={{ display: msgShow ? "block" : "none" }}
        >
          <ReactScrollableFeed>
            <div className="shortUserIntro">
              <img
                src={data?.image ? data.image : ChatBoxIcon}
                alt={"user"}
              />
              <h3>{data?.name ? data.name : "James Smith"}</h3>
              <p>Follower {data?.follower ? data.follower : 225}</p>
            </div>
            {data?.messages &&
              data.messages.map((value, index) => {
                let { who, msg, time } = value;
                if (who === "reciver") {
                  return (
                    <div className="second_person" key={index}>
                      {/* user profile picture and message */}
                      <div className="second_person_top_part">
                        <img
                          src={data.image ?? user1}
                          alt={"user profile"}
                        />

                        <p>{msg ?? "---"}</p>
                      </div>
                      {/* time */}
                      <div className="time">
                        <p>{time ?? "7:29 P.M"}</p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="first_person" key={index}>
                      {/* user profile picture and message */}
                      <div className="first_person_top_part">
                        <p>{msg ?? "---"}</p>
                        <img src={ChatBoxIcon} alt={"user profile"} />
                      </div>
                      {/* time */}
                      <div className="time">
                        <p>{time ?? "7:29 P.M"}</p>
                      </div>
                    </div>
                  );
                }
              })}
          </ReactScrollableFeed>
        </div>

        <div
          className="BottomMessageBox"
          style={{ display: msgShow ? "block" : "none" }}
        >
          {/* bottom part */}
          <div className="bottom_part">
            <p
              suppressContentEditableWarning={true}
              contentEditable={true}
              className="sendMessageField"
              ref={inputFieldMessage}
            >
              {inputValue}
            </p>
            {/* send button and emojy */}
            <div className="sendButton">
              <img
                src={EmojiIcon}
                alt={"emojy icon"}
                onClick={() => setShowEmoji((val) => !val)}
              />
              <img
                src={Send1Icon}
                alt={"send icon"}
                onClick={msgSendFunction}
              />
            </div>
          </div>

          {/* emoji */}
          {showEmoji && (
            <EmojiPicker
              onEmojiClick={(event) => {
                setInputValue(
                  `${inputFieldMessage.current.innerText} ${event.emoji}`
                );
              }}
              autoFocusSearch={false}
              searchDisabled
              width="100%"
              lazyLoadEmojis={true}
            />
          )}
        </div>
      </div>
    </React.StrictMode>
  );
};

export default Message;
