import { configureStore } from "@reduxjs/toolkit";

import MessagesSlice from "./MessagesSlice";
import ChatSlice from "./ChatSlice";
import MessageLeftSideShowAndHide from "./MessageLeftSideShowAndHide";
import UsersSlice from "./Users";
import FollowersSlice from "./FollowersSlice";
import FollowingSlice from "./FollowingSlice";
import NotificationSlice from "./NotificationSlice";
import PostsSlice from "./PostsSlice";
import IdentitySlice from "./IdentitySlice";
import TrendingPageSlice from "./TrendingPageSlice";

const Store = configureStore({
  reducer: {
    messages: MessagesSlice,
    chat: ChatSlice,
    showAndHide: MessageLeftSideShowAndHide,
    posts: PostsSlice,
    users: UsersSlice,
    followers: FollowersSlice,
    following: FollowingSlice,
    notification: NotificationSlice,
    identity: IdentitySlice,
    Trending: TrendingPageSlice
  },
});

export default Store;
