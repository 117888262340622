import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { ImCross } from "react-icons/im";
import EmojiPicker from "emoji-picker-react";
import StorageService from "../../services/StorageService";
import { ImageIcon, AddAPhoto } from "../../constant/IconsPath";
import emojiIcon from "../../assets/img/Emoji.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";

const GroupCreate = ({
  open,
  setShowCreate,
  setFlag2,
  flag2,
  pop_up_title,
  titleShow,
  userNameShow,
  description_tile,
  button_title,
  height_of_textarea,
}) => {
  const [content, setContent] = useState({
    title: "",
    username: "",
    text: "",
    profilePicture: null,
    coverPicture: null,
  });
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [image, setImages] = useState([]);
  const [image1, setImages1] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [disablePost, setDisablePost] = useState(false);
  const [base64, setBase64] = useState("");
  const inputRef = useRef();
  const inputRef1 = useRef();
  const dispatch = useDispatch()
  const inputFieldMessage = useRef(null);
  const modalRef = useRef(null);
  const emoji_close = useRef(null);
  const MainClassName = "image_upload";
  const [err, setErr] = useState(false);
  const flag = true;

  useEffect(() => {
    if (open) {
      getGroup();
    }
  }, [open]);

  const getGroup = async () => {
    try {
      // Fetch group data from the API
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const resetFunction = () => {
    setShowCreate(false);
    setContent({
      title: "",
      username: "",
      text: "",
      profilePicture: null,
      coverPicture: null,
    });
    setDisablePost(false)
    setProfileImage(null);
    setCoverImage(null);
    setImages([]);
    setImages1([]);
    setInputValue("");
    setShowEmoji(false);
  };

  const handleProfilePictureUpload = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleCoverPictureUpload = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleSubmit = async () => {
    dispatch(startLoading())
    const token = StorageService.getJwt();
    if (!content.title || !content.text) {
      setErr(true);
      dispatch(stopLoading())
      return;
    }
    setDisablePost(true)
    try {
      const formData = new FormData();
      formData.append("Name", content.title);
      formData.append("Description", content.text);
      formData.append("ProfilePicture", profileImage);
      formData.append("CoverPicture", coverImage);

      const response = await axios.post(`${StorageService?.baseURL}/Group/CreateGroup`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Group created successfully!");
      dispatch(stopLoading())
      resetFunction();
    } catch (error) {
      console.error("Error creating group:", error);
      setDisablePost(false)
      dispatch(stopLoading())
    }
  };

  function showImageUpload() {
    setFlag2(true);
    const image_upload1 = document.querySelector(`.${MainClassName}`);
    const main_part = document.querySelector(".main_part");
    const closeIconStyle = document.querySelector(`.${MainClassName} svg`);

    if (image_upload1) {
      const image_upload_style = window.getComputedStyle(image_upload1);

      if (image_upload_style.height === "0px" || image_upload_style.transform === "scale(0)") {
        main_part.style.margin = "2.5rem 0 1rem 0";
        image_upload1.style.height = "184px";
        image_upload1.style.transform = "scale(1)";

        closeIconStyle.style.display = "block";
      } else {
        main_part.style.margin = "2.5rem 0 0 0";
        image_upload1.style.height = "0px";
        image_upload1.style.transform = "scale(0)";

        closeIconStyle.style.display = "none";
      }
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image.some((e) => e.name === file[i].name)) {
          setImages((prevImages) => [
            ...prevImages,
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }
        setFlag2(false);
      }
    }
  };

  function onFileSelect(event) {
    const file = event.target.files;
    setCoverImage(file[0]);

    if (file.length === 0) return;
    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image.some((e) => e.name === file[i].name)) {
          setImages((prevImages) => [
            ...prevImages,
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }
        // setFlag2(false);
      }
    }
  }
  function onFileSelect1(event) {
    const file = event.target.files;

    setProfileImage(file[0]);

    if (file.length === 0) return;
    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image1.some((e) => e.name === file[i].name)) {
          setImages1((prevImages) => [
            ...prevImages,
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }
        // setFlag2(false);
      }
    }
  }

  function onFunction(e) {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      resetFunction();
    }
  }

  function emojiCloseFn(e) {
    if (emoji_close.current && !emoji_close.current.contains(e.target)) {
      setShowEmoji(false);
    }
  }

  return (
    <div
      className={
        open
          ? "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center groupCreate"
          : "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center groupCreate"
      }
      style={{
        opacity: open ? 1 : 0,
        cursor: open ?? "none",
        zIndex: open ? 99 : -1,
        visibility: open ? "visible" : "hidden",
      }}
      onClick={onFunction}
    >
      <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-lg" ref={modalRef}>
        <div className="relative py-8 px-5 md:px-10 bg-[#131313] shadow-md rounded-xl">
          {/* <div className="flex items-center justify-between mb-10">
            <p className="font-medium text-5 text-[#26272D] createGroupHeading">
              <span className="text-[#fff]">{pop_up_title ?? "Create Group"}</span>
            </p>
            <button onClick={resetFunction} className="bg-[#ffffff80] rounded-full clossIcon">
              <ImCross className="text-[#131313] h-[22px] w-[22px] p-[6px] font-thin" />
            </button>
          </div>
          <div className="InputFieldGroupCreate">
            {titleShow ? (
              <>
              <label className="block">
                <input
                  type="text"
                  name="title"
                  className="mt-1 px-3 py-2 bg-[#000] shadow-sm block w-full h-[46px] rounded-md sm:text-sm text-[20px] hover:bg-[#42424257] createGroupInputField"
                  placeholder="Group title.."
                  value={content.title}
                  onChange={(e) => setContent({ ...content, title: e.target.value })}
                  style={{ color: "#fff" }} 
                />
              </label>
              {err && !content?.title && (
                <span style={{ color: "red" }}>Group title is Requried field! </span>
              )}
              </>
            ) : null}
            
            <div className="text_area">
              <textarea
                ref={inputFieldMessage}
                placeholder={
                  MainClassName && MainClassName === "image_upload"
                    ? "Write your caption here.."
                    : "Write your question here.."
                }
               
                value={content.text}
                onChange={(e) => setContent({ ...content, text: e.target.value })}
                className="mt-1 px-3 py-2 bg-[#000] shadow-sm block w-full h-[127px] rounded-md createGroupInputField textAaraHoverEffect"
                style={{ color: "#fff" }}
              />
              <p>
                {content.text
                  ? content.text.length < 10
                    ? `0${content.text.length}`
                    : content.text.length
                  : "00"}
                /250
              </p>
              {err && !content?.text && (
                <span style={{ color: "red" }}>Caption is Requried field! </span>
              )}
              <div
                className="bottom_part"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <img
                  src={ImageIcon}
                  alt="icon"
                  onClick={showImageUpload}
                  width={"auto"}
                  height={"auto"}
                />
                <div ref={emoji_close} style={{ position: "relative" }}>
                  <img
                    src={emojiIcon}
                    alt="Emoji Icon"
                    onClick={() => setShowEmoji((val) => !val)}
                  />
                  {showEmoji && (
                    <EmojiPicker
                      onEmojiClick={(event) => {
                        setInputValue((prevValue) => `${prevValue} ${event.emoji}`);
                      }}
                      autoFocusSearch={false}
                      searchDisabled
                      width="100%"
                      lazyLoadEmojis={true}
                    />
                  )}
                </div>
              </div>
            </div>
            {flag2 ? (
              <div className={MainClassName}>
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={showImageUpload}
                  className="closeIconStyle"
                />

                <div className="center p-10" style={{ width: "50%" }}>
                  {image && image[0] ? (
                    <img src={image[0]?.url} alt={image[0]?.name} />
                  ) : (
                    <>
                      <input
                        name="file"
                        type="file"
                        onChange={onFileSelect}
                        hidden
                        ref={inputRef}
                      />

                      <img src={AddAPhoto} alt="add" onClick={() => inputRef.current.click()} />

                      <p onClick={() => inputRef.current.click()}>Add a Cover Photo</p>
                    </>
                  )}
                </div>
                <div className="center p-10" style={{ width: "50%" }}>
                  {image1 && image1[0] ? (
                    <img src={image1[0]?.url} alt={image1[0]?.name} />
                  ) : (
                    <>
                      <input
                        name="file"
                        type="file"
                        onChange={onFileSelect1}
                        hidden
                        ref={inputRef1}
                      />

                      <img src={AddAPhoto} alt="add" onClick={() => inputRef1.current.click()} />

                      <p onClick={() => inputRef1.current.click()}>Add a profile Pic</p>
                    </>
                  )}
                </div>
              </div>
            ) : null
            }
            <button
              onClick={disablePost?null:handleSubmit}
              className="bg-primary w-full h-[50px] text-white rounded-md mt-4 createGroupBtn1"
            >
              {button_title ?? "Create Group"}
            </button>
          </div> */}
           <button onClick={resetFunction} className="bg-[#ffffff80] rounded-full clossIcon float-right mb-[10px]" >
              <ImCross className="text-[#131313] h-[22px] w-[22px] p-[6px] font-thin" />
            </button>
          <div className="flex items-center justify-center">
            <p className="empty flex items-center justify-center">Coming Soon...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupCreate;
