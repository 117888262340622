import React from "react";

const Subscription = () => {
  return (
    <h1 className="text-2xl text-center text-primary font-bold">
      Subscription Coming Soon!
    </h1>
  );
};

export default Subscription;
