import React from "react";

// Image
import { Line } from "../constant/imagePath";

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto sm:px-0 px-5">
      {/* section title */}
      <div className="mt-12">
        <h1 className="text-[44px] font-bold text-secondary">
          Terms and Conditions
        </h1>
        <p className="text-sm text-[#444444]">
          Effective September 12, 2022. Last Revised August 12, 2022
        </p>
      </div>

      {/* Definitions */}
      <div className="my-10">
        <h2 className="text-primary text-4xl font-bold mb-3 pl-4 border-l-2 border-gray">
          Definitions
        </h2>
        <p className="text-lg text-gray font-light">
          <span className="text-[#636363] font-normal">
            "Personal Information"
          </span>{" "}
          is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic.
        </p>
      </div>

      {/* Acceptable Use Policy */}
      <h2 className="text-primary text-4xl font-bold mb-3 pl-4 border-l-2 border-gray">
        Acceptable Use Policy
      </h2>
      <div className="sm:flex items-center justify-between gap-8">
        <div>
          <p className="text-lg text-gray font-light">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one of the more
            obscure Latin words, consectetur, from a Lorem Ipsum passage, and
            going through the cites of the word.
          </p>

          <p className="text-lg text-gray font-light my-5">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia.
          </p>

          <p className="text-lg text-gray font-light">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one.
          </p>
        </div>

        <div className="h-72 sm:block hidden">
          <img className="h-full" src={Line} alt="line" />
        </div>

        <div>
          <p className="text-lg text-gray font-light">
            <span className="text-[#636363] font-normal">
              Usage and log information.
            </span>{" "}
            Lorem Ipsum is not simply random text. It has roots in a piece of
            classical Latin literature from 45 BC, making it over 2000 years
            old. Richard McClintock, a Latin professor at Hampden-Sydney College
            in Virginia, looked up one of the more obscure Latin words,
            consectetur, from a Lorem Ipsum passage, and going through the cites
            of the word.
          </p>

          <p className="text-lg text-gray font-light my-5">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia.
          </p>

          <p className="text-lg text-gray font-light">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one.
          </p>
        </div>
      </div>

      {/* License */}
      <div className="my-10">
        <h2 className="text-primary text-4xl font-bold mb-3 pl-4 border-l-2 border-gray">
          License
        </h2>
        <p className="text-lg text-gray font-light">
          <span className="text-[#636363] font-normal">
            Usage and log information.
          </span>{" "}
          Lorem Ipsum is not simply random text. It has roots in a piece of
          classical Latin literature from 45 BC, making it over 2000 years old.
          Richard McClintock, a Latin professor at Hampden-Sydney College in
          Virginia, looked up one of the more obscure Latin words, consectetur,
          from a Lorem Ipsum passage, and going through the cites of the word.
        </p>

        <p className="text-lg text-gray font-light my-5">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia.
        </p>

        <p className="text-lg text-gray font-light">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia, looked up one.
        </p>
      </div>

      {/* Disclaimer */}
      <h2 className="text-primary text-4xl font-bold mb-3 pl-4 border-l-2 border-gray">
        Disclaimer
      </h2>
      <div className="sm:flex items-center justify-between gap-8">
        <div>
          <p className="text-lg text-gray font-light">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one of the more
            obscure Latin words, consectetur, from a Lorem Ipsum passage, and
            going through the cites of the word.
          </p>

          <p className="text-lg text-gray font-light my-5">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia.
          </p>

          <p className="text-lg text-gray font-light">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one.
          </p>
        </div>

        <div className="h-72 sm:block hidden">
          <img className="h-full" src={Line} alt="line" />
        </div>

        <div>
          <p className="text-lg text-gray font-light">
            <span className="text-[#636363] font-normal">
              Usage and log information.
            </span>{" "}
            Lorem Ipsum is not simply random text. It has roots in a piece of
            classical Latin literature from 45 BC, making it over 2000 years
            old. Richard McClintock, a Latin professor at Hampden-Sydney College
            in Virginia, looked up one of the more obscure Latin words,
            consectetur, from a Lorem Ipsum passage, and going through the cites
            of the word.
          </p>

          <p className="text-lg text-gray font-light my-5">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia.
          </p>

          <p className="text-lg text-gray font-light">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one.
          </p>
        </div>
      </div>

      {/* Profile */}
      <div className="my-10">
        <h2 className="text-primary text-4xl font-bold mb-3 pl-4 border-l-2 border-gray">
          Profile
        </h2>
        <p className="text-lg text-gray font-light">
          <span className="text-[#636363] font-normal">
            "Personal Information"
          </span>{" "}
          is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic.
        </p>
      </div>

      {/* Posting and Uploading */}
      <h2 className="text-primary text-4xl font-bold mb-3 pl-4 border-l-2 border-gray">
        Posting and Uploading
      </h2>

      <div className="sm:flex items-center justify-between gap-8 mb-12">
        <p className="text-lg text-gray font-light">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from 45 BC, making
          it over 2000 years old. Richard McClintock, a Latin professor at
          Hampden-Sydney College in Virginia, looked up one of the more obscure
          Latin words, consectetur, from a Lorem Ipsum passage, and going
          through the cites of the word.
        </p>

        <div className="h-36 sm:block hidden">
          <img className="h-full" src={Line} alt="line" />
        </div>

        <p className="text-lg text-gray font-light">
          <span className="text-[#636363] font-normal">
            Usage and log information.
          </span>{" "}
          Lorem Ipsum is not simply random text. It has roots in a piece of
          classical Latin literature from 45 BC, making it over 2000 years old.
          Richard McClintock, a Latin professor at Hampden-Sydney College in
          Virginia, looked up one of the more obscure Latin words, consectetur,
          from a Lorem Ipsum passage, and going through the cites of the word.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
