import React from "react";

// react router
import { useNavigate } from "react-router-dom";

// images
import { profile1 } from "../../constant/imagePath";
import user1 from "../../assets/img/searchResult/1.png";

// redux toolkit
import { useDispatch, useSelector } from "react-redux";
import { addParent } from "../../Store/MessagesSlice";

const SearchResultUser = ({ val }) => {
  let { name, img, followers, following, post } = val || "";

  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const navigate = useNavigate();

  const navigateAnotherPage = () => {
    navigate("/userProfile", { state: val });
  };

  const GoToMessagePage = () => {
    let data = {
      id: messages.length + 1,
      image: img ?? profile1,
      name: name ?? "Solly Sherman-Burns",
      message: "Hey bro! I just messaged..",
      time: "7:29 P.M",
      status: "Online",
      follower: followers ?? 250,
      following: following ?? 20,
      post: post ?? 9,
    };
    dispatch(addParent(data));
    navigate(`/messages/${messages.length + 1}`);
  };

  return (
    <div className="searchResultBox">
      {/* left side */}
      <div className="left_side">
        <img src={img ?? user1} alt={"user"} />

        {/* content */}
        <div className="detail">
          <h3 onClick={navigateAnotherPage}>{name ?? "George Smith"}</h3>

          <div className="follower_following">
            <p>{followers ?? 350} Followers</p>

            <p>{following ?? 150} Following</p>
          </div>
        </div>
      </div>

      {/* right side */}
      <div className="right_side">
        <button className="follow">Follow</button>

        <button className="message" onClick={GoToMessagePage}>
          Message
        </button>
      </div>
    </div>
  );
};

export default SearchResultUser;
