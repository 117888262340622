import React, { useEffect, useState, useRef } from "react";

// images
import {
  member1,
} from "../../constant/imagePath";

// icons
import { ImCross } from "react-icons/im";

// Style
import "../../style/GroupMembers.css";

// Fontawesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

// Redux Toolkit
import { useDispatch, useSelector } from "react-redux";
import { makeAdmin, removeAdmin, removeMember } from "../../Store/Users";
import { removeFollowers } from "../../Store/FollowersSlice";
import { removeFollowing } from "../../Store/FollowingSlice";

// Components
import EditDropMenu from "./EditDropMenu";

const User = ({ val, option, setValue }) => {
  let { id, img, name, detail, icon } = val || "";

  const [isCrownShow, setIsCrownShow] = useState(false);

  useEffect(() => {
    if (icon) {
      setIsCrownShow(true);
    } else {
      setIsCrownShow(false);
    }
  }, [val, icon]);

  return (
    <div className="flex items-center justify-between mt-2 relative">
      <div className="flex items-center justify-center">
        <div className="flex w-[54px] h-[54px] items-center justify-center rounded-full ">
          <img className="w-full rounded-full" src={img ?? member1} alt="logo" />
        </div>

        <div className="p-3">
          <h3 className="text-xl font-bold text-white"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {name ?? "Man-Burns"} {
              icon && <span style={{ marginLeft: "0.7rem" }}>
                <img src={icon} alt="Make Admin" className="text-[#636363] text-xl" />
              </span>
            }
          </h3>
          <p className="text-[#999999] font-normal text-xs -mt-[2px]">
            {detail ?? "Joined on yesterday"}
          </p>
        </div>
      </div>

      <EditDropMenu
        isOpenMenu={false}
        setValue={setValue}
        id={id}
        option={option}
        isCrownShow={isCrownShow} />

    </div>
  )
}


const GroupMembers = ({ toggle, open, heading, option, popupName }) => {
  let [value, setValue] = useState({ msg: "", id: null, icon: false, labelText: "" });

  const users = useSelector((state) => state[popupName ?? "users"]);
  const dispatch = useDispatch();
  const groupMember = useRef();

  const closePopUp = (e) => {
    if (groupMember.current && !groupMember.current.contains(e.target)) {
      toggle();
    }
  }

  const successFunction = () => {
    if (value?.id) {
      if (popupName === "followers") {
        dispatch(removeFollowers(value.id));
      } else if (popupName === "following") {
        dispatch(removeFollowing(value.id));
      } else {
        dispatch(removeMember(value.id));
      }
    }

    setValue({ msg: "", id: null, icon: false });
  }

  const discardFunction = () => {
    setValue({ msg: "", id: null, icon: false });
  }

  const makeAdmin1 = () => {
    dispatch(makeAdmin(value.id));
    setValue({ msg: "", id: null, icon: false });
  }
  const removeAsAdmin = () => {
    dispatch(removeAdmin(value.id));
    setValue({ msg: "", id: null, icon: false });
  }

  return (
    <div
      className={
        open
          ? "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center groupMembers"
          : "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center groupMembers"
      }
      style={{
        opacity: open ? 1 : 0,
        cursor: open ?? "none",
        zIndex: open ? 11111111111 : -1,
        visibility: open ? "visible" : "hidden",
      }}
      onClick={closePopUp}
    >
      <div className="relative w-[490px] py-8 px-5 md:px-10 bg-[#131313] shadow-md rounded-2xl GroupMembersChild" style={{ paddingBottom: (value && value.msg) ? "0px" : "32px" }}
        ref={groupMember}
      >
        <div className="flex items-center justify-between mb-10">
          <p className="font-medium text-5 text-[#fff]" style={{ fontSize: "18px" }}>
            <span className="text-white">
              {heading ?? "Group Members"}
            </span>
          </p>
          <button onClick={toggle} className="bg-[#ffffff80] rounded-full">
            <ImCross className="text-black h-[22px] w-[22px] p-[6px] font-thin " />
          </button>
        </div>

        <div className="center_content" style={{
          overflow: value?.msg ? "auto" : "unset"
        }}>

          {/* members section  */}
          {
            users && users.length > 0 ?
              users.map((val, index) => {
                return (
                  <React.StrictMode key={index}>
                    <User val={val} option={option} setValue={setValue} value={value} />
                  </React.StrictMode>
                )
              })

              :
              <p style={{
                fontSize: "18px",
                color: "rgba(122, 113, 113, 1)"
              }}>Empty Group Members</p>
          }
        </div>

        <div className="confirmation_message" style={{ transform: `${value?.msg ? "scale(1)" : "scale(0)"}`, minHeight: `${value?.msg ? "70px" : "0px"}`, height: "0" }} >
          <p>
            Are you sure you want to {value?.icon ? "remove as admin" : value?.labelText ?? "remove"} this member?
          </p>

          <div className="groupOfBtn">
            <button style={{ display: `${value?.msg ? "block" : "none"}` }}
              onClick={value?.icon ? removeAsAdmin : value?.msg === "assign admin role to" ? makeAdmin1 : successFunction}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button style={{ display: `${value?.msg ? "block" : "none"}` }}
              onClick={discardFunction}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default GroupMembers;
