import React, { useRef } from "react";

// style
import "../../style/ConfirmationDialogBox.css";

const ConfirmationDialogBox = ({
  comment,
  buttonText1,
  buttonText2,
  firstBTNFN,
  secondBTNFN,
  setConfirmationMessage,
  confirmationMessage,
}) => {
  let ConfirmationBox = useRef();

  const closeFn = (e) => {
    if (
      ConfirmationBox.current &&
      !ConfirmationBox.current.contains(e.target)
    ) {
      setConfirmationMessage(false);
    }
  };

  return (
    <section
      className="confirMationDialogBox"
      onClick={closeFn}
      style={{
        opacity: confirmationMessage ? 1 : 0,
        pointerEvent: confirmationMessage ? "normal" : "none",
        zIndex: confirmationMessage ? 1111111111 : -1,
      }}
    >
      <div className="centerDiv" ref={ConfirmationBox}>
        <p>{comment ?? "Are you sure you want to block this person?"}</p>

        <div className="button">
          <button onClick={firstBTNFN ?? null}>{buttonText1 ?? "Yes"}</button>
          <button onClick={secondBTNFN ?? null} className="active">
            {buttonText2 ?? "Cancel"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ConfirmationDialogBox;
