import React from "react";

// Component
import { Header } from "../components";

// css
import "../style/Agreement.css";

const Agreement = () => {
  return (
    <React.StrictMode>
      {/* header */}
      <Header loginAndSignup={false} messageShow={true} />
      <div className="main_content container mx-auto" id="centerContent">
        <div
          name="termly-embed"
          data-id="24778d60-6645-4b3a-b2cd-0cacbae276b3"
          data-type="iframe"
        ></div>
        <script type="text/javascript">
          {(function (d, s, id) {
            var js,
              tjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs);
          })(document, "script", "termly-jssdk")}
        </script>
      </div>
    </React.StrictMode>
  );
};

export default Agreement;
