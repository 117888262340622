import { createSlice } from "@reduxjs/toolkit";

const ChatSlice = createSlice({
  name: "chat",
  initialState: {
    isChatShow: false,
    user: 0,
  },
  reducers: {
    addChat(state, action) {
      state.user = action.payload;
    },
    isChatShow(state, action) {
      state.isChatShow = action.payload;
    },
  },
});

export const { addChat, isChatShow } = ChatSlice.actions;
export default ChatSlice.reducer;
