import React from "react";

const ChangePassword = () => {
  return (
    <div className="border-2 border-solid border-slate-200 mx-5 ChangePassword bg-[#131313]" style={{ border: "1px solid rgba(138, 152, 164, 0.47)" }}>
      <div className="bg-[#c8ced436] px-[30px] py-5 loginSessionHeading">
        <h3 className="text-[20px] font-bold text-[#fff]">Change Password</h3>
      </div>

      <div className="px-5 sm:px-[30px] pt-5 sm:pt-[37px]">
        {/* form */}
        <form action="#" className="w-full mx-auto mt-3">
          <div className="block sm:flex items-center justify-between flex-col sm:flex-row">
            <div>
              <label
                className="block text-lg sm:text-xl font-bold mb-[10px] relative text-[#fff]"
                htmlFor="oldPassword"
              >
                Old Password *
              </label>
              <input
                type="password"
                id="oldPassword"
                className="border border-[#D1D1D1] rounded-md w-full sm:w-[252px] h-[34px] pl-1 bg-[transparent] hover:bg-[#42424257] text-white"
                style={{ border: "1px solid #6d6d6d", outline: "none" }}
              />
            </div>
            <div className="my-6 sm:my-0">
              <label
                className="block text-lg sm:text-xl font-bold mb-[10px] relative text-[#fff]"
                htmlFor="newPassword"
              >
                New Password
              </label>
              <input
                type="password"
                id="newPassword"
                className="border border-[#D1D1D1] rounded-md w-full sm:w-[252px] h-[34px] pl-1 bg-[transparent] hover:bg-[#42424257] text-white"
                style={{ border: "1px solid #6d6d6d", outline: "none" }}
              />
            </div>

            <div>
              <label
                className="block text-lg sm:text-xl font-bold mb-[10px] relative text-[#fff]"
                htmlFor="confirm"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirm"
                className="border border-[#D1D1D1] rounded-md w-full sm:w-[252px] h-[34px] pl-1 bg-[transparent] hover:bg-[#42424257] text-white"
                style={{ border: "1px solid #6d6d6d", outline: "none" }}
              />
            </div>
          </div>

          <div className="block sm:flex items-center justify-end px-2 sm:px-0 mt-6 sm:mt-14 mb-4 sm:mb-12">
            <button
              type="submit"
              className="bg-[#ffa000] text-xl text-black px-[45px] py-[10px] rounded-md w-full sm:w-auto"
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
