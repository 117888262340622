import React from 'react';

// components
import Notifications from '../../components/Models/Notification/Notifications';
import { Header } from '../../components';

const NotificationPage = () => {
    return (
        <React.StrictMode>
            {/* Header */}
            <Header loginAndSignup={false} messageShow={true} notificationNotShow={true} />

            <Notifications NotificationStyle={
                {
                    width: "100%",
                    height: "100vh",
                    margin: "5rem auto 0px",
                    maxHeight: "calc(100vh - 80px)",
                    position: "relative",
                    top: 0,
                    right: 0,
                    backgroundColor: '#000'
                }}
                notifyStyle={{
                    width: "560px",
                    margin: "2rem auto 0px",
                    paddingTop: "0.7rem",
                    boxShadow: "rgb(0 0 0) 0px 20px 20px 0px",
                    backgroundColor: "#131313",
                    borderRadius: "5px",
                    border: "1px solid #6d6d6d8c",
                }}
                confirmationBoxStyle={{
                    width: "100%",
                    borderRadius: 0,
                    bottom: "0rem",
                    left: "26.8rem"
                }}
                notificationPage={true}
            />
        </React.StrictMode>
    )
}

export default NotificationPage;