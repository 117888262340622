import React, { useRef } from "react";
// images
import {
  member1,
  member2,
  member3,
  member4,
  member5,
  member6,
} from "../../constant/imagePath";
// icons
import { ImCross } from "react-icons/im";

import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const InviteMembers = ({ toggle, open }) => {

  const inviteMembers = useRef();
  const closePopUp = (e) => {
    if (inviteMembers.current && !inviteMembers.current.contains(e.target)) {
      toggle();
    }
  }

  return (
    <>
      <div
        className={
          open
            ? "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center"
            : "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center"
        }
        style={{
          transition: "0.3s",
          opacity: open ? 1 : 0,
          // cursor: open ?? "none",
          zIndex: open ? 11111111111 : -1,
          visibility: open ? "unset" : "hidden",
        }}
        onClick={closePopUp}
      >
        <div
          role="alert"
          className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
          ref={inviteMembers}
        >
          <div className=" py-8 px-5 md:px-10 bg-[#131313] shadow-md rounded-xl">
            <div className="flex items-center justify-between mb-10">
              <p className="font-medium text-5 text-[#fff]" style={{ fontSize: "20px" }}>
                <span className="text-[#fff]">
                  Invite Members
                </span>
              </p>
              <button onClick={toggle} className="bg-[#ffffff80] rounded-full">
                <ImCross className="text-black h-[22px] w-[22px] p-[6px] font-thin" />
              </button>
            </div>

            {/* members section  */}
            <div className="flex items-center justify-between mt-5">
              <div className="flex items-center justify-center">
                <div className="flex w-[54px] h-[54px] items-center justify-center rounded-full ">
                  <img
                    className="w-full rounded-full"
                    src={member1}
                    alt="logo"
                  />
                </div>

                <div className="p-3">
                  <h3 className="text-xl font-bold text-[#fff]">Man-Burns</h3>
                  <p className="text-[#999999] font-normal text-xs">Follower</p>
                </div>
              </div>

              <div className="w-[36px] h-[36px] bg-[#000] flex mt-[-9px] items-center justify-center rounded-full ">
                <a href="/">
                  <AiOutlinePlus className="text-[#FEA500] text-[18px]" />
                </a>
              </div>
            </div>
            {/* members section  */}
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center justify-center ">
                <div className="flex w-[54px] h-[54px] items-center justify-center rounded-full ">
                  <img
                    className="w-full rounded-full"
                    src={member2}
                    alt="logo"
                  />
                </div>

                <div className="p-3">
                  <h3 className="text-xl font-bold text-[#fff]">Man-Burns</h3>
                  <p className="text-[#999999] font-normal text-xs ">
                    Follower - Invited
                  </p>
                </div>
              </div>

              <div className="w-[36px] h-[36px] bg-[#000] flex mt-[-9px] items-center justify-center rounded-full ">
                <a href="/">
                  <AiOutlineMinus className="text-[#FEA500] text-[18px]" />
                </a>
              </div>
            </div>
            {/* members section  */}
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center justify-center ">
                <div className="flex w-[54px] h-[54px] items-center justify-center rounded-full ">
                  <img
                    className="w-full rounded-full"
                    src={member3}
                    alt="logo"
                  />
                </div>

                <div className="p-3">
                  <h3 className="text-xl font-bold  text-[#fff]">Man-Burns</h3>
                  <p className="text-[#999999] font-normal text-xs ">
                    Follower
                  </p>
                </div>
              </div>

              <div className="w-[36px] h-[36px] bg-[#000] flex mt-[-9px] items-center justify-center rounded-full ">
                <a href="/">
                  <AiOutlinePlus className="text-[#FEA500] text-[18px]" />
                </a>
              </div>
            </div>
            {/* members section  */}
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center justify-center ">
                <div className="flex w-[54px] h-[54px] items-center justify-center rounded-full ">
                  <img
                    className="w-full rounded-full"
                    src={member4}
                    alt="logo"
                  />
                </div>

                <div className="p-3">
                  <h3 className="text-xl font-bold  text-[#fff]">Man-Burns</h3>
                  <p className="text-[#999999] font-normal text-xs ">
                    Follower - Invited
                  </p>
                </div>
              </div>

              <div className="w-[36px] h-[36px] bg-[#000] flex mt-[-9px] items-center justify-center rounded-full ">
                <a href="/">
                  <AiOutlinePlus className="text-[#FEA500] text-[18px]" />
                </a>
              </div>
            </div>
            {/* members section  */}
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center justify-center ">
                <div className="flex w-[54px] h-[54px] items-center justify-center rounded-full ">
                  <img
                    className="w-full rounded-full"
                    src={member5}
                    alt="logo"
                  />
                </div>

                <div className="p-3">
                  <h3 className="text-xl font-bold text-[#fff]">Man-Burns</h3>
                  <p className="text-[#999999] font-normal text-xs ">
                    Follower
                  </p>
                </div>
              </div>

              <div className="w-[36px] h-[36px] bg-[#000] flex mt-[-9px] items-center justify-center rounded-full ">
                <a href="/">
                  <AiOutlineMinus className="text-[#FEA500] text-[18px]" />
                </a>
              </div>
            </div>
            {/* members section  */}
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center justify-center ">
                <div className="flex w-[54px] h-[54px] items-center justify-center rounded-full ">
                  <img
                    className="w-full rounded-full"
                    src={member6}
                    alt="logo"
                  />
                </div>

                <div className="p-3">
                  <h3 className="text-xl font-bold text-[#fff]">Man-Burns</h3>
                  <p className="text-[#999999] font-normal text-xs ">
                    Follower - Invited
                  </p>
                </div>
              </div>

              <div className="w-[36px] h-[36px] bg-[#000] flex mt-[-9px] items-center justify-center rounded-full ">
                <a href="/">
                  <AiOutlinePlus className="text-[#FEA500] text-[18px]" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteMembers;
