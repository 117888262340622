export const navItems = [
  {
    name: "Account Info",
    url: "accountInfo",
  },
  {
    name: "Edit Profile",
    url: "editProfile",
  },
  // {
  //   name: "Email & Notifications",
  //   url: "emailAndNotifications",
  // },
  {
    name: "Subscription",
    url: "subscription",
  },
];
