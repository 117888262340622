import { createSlice } from "@reduxjs/toolkit";
import { member1, member2, member3, member4, member5, member6 } from "../constant/imagePath";

const FollowingSlice = createSlice({
    name: "following",
    initialState: [
        {
            id: 1,
            img: member1,
            name: "Man-Burns",
            detail: "512 Followers",
        },
        {
            id: 2,
            img: member2,
            name: "Man-Burns",
            detail: "25 followers",
        },
        {
            id: 3,
            img: member3,
            name: "Man-Burns",
            detail: "1,089 followers",
        },
        {
            id: 4,
            img: member4,
            name: "Man-Burns",
            detail: "2,048 Followers",
        },
        {
            id: 5,
            img: member5,
            name: "Man-Burns",
            detail: "1,224 Followers",
        },
        {
            id: 6,
            img: member6,
            name: "Man-Burns",
            detail: "1,830 Followers",
        }
    ],
    reducers: {
        removeFollowing(state, action) {
            if (action.payload) {
                return state.filter((val) => val.id !== action.payload);
            }
            return state;
        },
    },
});

export const {
    removeFollowing
} = FollowingSlice.actions;

export default FollowingSlice.reducer;
