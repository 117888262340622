import { createSlice } from "@reduxjs/toolkit";

import userData from "../data/UserData";

const MessageSlice = createSlice({
  name: "messages",
  initialState: userData,
  reducers: {
    add(state, action) {
      let { id, who, msg, time } = action.payload;

      if (state[id] && state[id].messages) {
        state[id].messages = [
          ...state[id].messages,
          {
            who,
            msg,
            time,
          },
        ];
      } else {
        state[id].messages = [
          {
            who,
            msg,
            time,
          },
        ];
      }
    },
    addParent(state, action) {
      state.push(action.payload);
    },
  },
});

export const { add, addParent } = MessageSlice.actions;
export default MessageSlice.reducer;
