import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import Store from "./Store/Store";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
// let ClientID = '249815450018-ujph83k4ng7emspi98bismn9192p94ek.apps.googleusercontent.com';
let ClientID = '517556036673-1gh755p8dslitfgtvgtilv0t206oav6o.apps.googleusercontent.com';
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <GoogleOAuthProvider clientId={ClientID}>
        <ToastContainer />
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);
