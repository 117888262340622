import React from "react";
import classes from "./OTPButton.module.css";

const OTPButton = ({ href = "#", className, children, textColor }) => {
  return (
    <a
      href={href}
      className={`  ${classes["btn"]} ${className} ${textColor}`}
    >
      {/* <span className="text-[#000]"> */}
        {children}
      {/* </span> */}
    </a>
  );
};

export default OTPButton;
