import React, { useEffect, useRef, useState } from 'react';
// react router
import { NavLink, useNavigate } from 'react-router-dom';

// redux toolkit
import { useDispatch, useSelector } from 'react-redux';
import { allRead, isReadNotification, removeNotification } from '../../../Store/NotificationSlice';

// fonts awesome icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faBug, faCheck, faComputer, faEllipsis, faGear, faXmark } from '@fortawesome/free-solid-svg-icons'

// image path
import { logo4 } from "../../../constant/imagePath";

// style
import "../../../style/Notifications.css";

const NotificationSetting = ({ notificationPage, setShowNotificationSetting }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allReadFunction = () => {
        dispatch(allRead());
        setShowNotificationSetting(false);
    }

    return (
        <div className='notificationSetting'>
            <div className='option_1' onClick={allReadFunction}>
                <FontAwesomeIcon icon={faCheck} />
                <p>Mark all as read</p>
            </div>
            <div className='option_2' style={{ borderBottom: notificationPage ? "none" : "1px solid rgba(199, 199, 199, 0.3)" }}>
                <FontAwesomeIcon icon={faGear} />
                <p>Notification settings</p>
            </div>
            {
                notificationPage ??

                <div className='option_3' onClick={() => { navigate("/notifications"); setShowNotificationSetting(false) }}>
                    <FontAwesomeIcon icon={faComputer} />
                    <p>Open notifications</p>
                </div>
            }
        </div >
    )
}


const NotifySetting = ({ setShowNotifySetting, setShowNotifyoption, status, id, setNotification }) => {

    const dispatch = useDispatch();

    const notify_setting = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (notify_setting.current && !notify_setting.current.contains(e.target)) {
                setShowNotifySetting(false);
                setShowNotifyoption(false);
            }
        }

        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, [setShowNotifySetting, setShowNotifyoption]);


    const isReadFunction = () => {
        dispatch(isReadNotification({ id, isRead: status === "read" ? false : true }));

        setShowNotifySetting(false);
        setShowNotifyoption(false);
    }

    const remove_notification = () => {
        setNotification({ remove: true, id });

        setShowNotifySetting(false);
        setShowNotifyoption(false);
    }

    return (
        <div className='notifySetting' ref={notify_setting}>
            <div className='option_1' onClick={isReadFunction}>
                <FontAwesomeIcon icon={faCheck} />
                <p>{status === "read" ? "Mark as unread" : "Mark as read"}</p>
            </div>
            <div className='option_2' onClick={remove_notification}>
                <FontAwesomeIcon icon={faXmark} />
                <p>Remove this notification</p>
            </div>
            <div className='option_3'>
                <FontAwesomeIcon icon={faGear} />
                <p>Only receive highlights about videos from this page</p>
            </div>
            <div className='option_3'>
                <FontAwesomeIcon icon={faBug} />
                <p>Report issue to Notifications Team</p>
            </div>
        </div>
    )
}

const Notification = ({ val, setNotification }) => {

    let { name, img, id, time, status, msg } = val || "";

    const [showNotifyOption, setShowNotifyoption] = useState(false);
    const [showNotifySetting, setShowNotifySetting] = useState(false);

    let Function1 = () => {
        if (showNotifySetting) {
            return;
        } else {
            setShowNotifyoption(true)
        }
    }

    let Function2 = () => {
        if (showNotifySetting) {
            return;
        } else {
            setShowNotifyoption(false)
        }
    }


    return (
        <div className='notify' onMouseOver={Function1} onMouseOut={Function2}>

            <div className='left_side'>
                <img src={img ?? logo4} alt="user" />
                <FontAwesomeIcon icon={faBell} className={`${status === "unread" && "active"}`} />
            </div>


            <div className='right_side'>

                <div className="side_1" style={{ opacity: status === "read" ? .8 : 1 }}>
                    <p>
                        <span className='name'>{name ?? "George Smith"}</span>
                        {msg ?? "mentioned you in a comment."}
                    </p>
                    <p className={`time ${status === "unread" && "active"}`}>{time ?? "9 hours ago"}</p>
                </div>

                {
                    status === "unread" &&
                    <div className='side_2'>
                        <div className='dot'></div>
                    </div>
                }

            </div>


            {
                showNotifyOption &&
                <div className='parent_notify_option'>

                    <div className='notify_option' onClick={() => setShowNotifySetting(!showNotifySetting)}>
                        <FontAwesomeIcon icon={faEllipsis} />
                    </div>

                    {
                        showNotifySetting &&
                        <NotifySetting setShowNotifySetting={setShowNotifySetting} setShowNotifyoption={setShowNotifyoption} status={status} id={id} setNotification={setNotification} />
                    }

                </div>
            }

        </div>
    )
}

const Notifications = ({ NotificationStyle, notificationPage, confirmationBoxStyle, notifyStyle }) => {
    const [showNotificationSetting, setShowNotificationSetting] = useState(false);
    const [UnReadNotification, setUnReadNotification] = useState(false);
    const [removeNotification1, setNotification1] = useState({ remove: false, id: "" });
    const [isReadNotificationEmpty, setIsReadNotificationEmpty] = useState(false);

    const dispatch = useDispatch();

    const notification_setting = useRef();

    useEffect(() => {
        let handler = (e) => {
            if (notification_setting.current && !notification_setting.current.contains(e.target)) {
                setShowNotificationSetting(false);
            }
        };

        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        }
    }, []);


    const notification = useSelector((state) => state.notification);

    useEffect(() => {
        let areAllRead = notification?.every((val) => {
            return val.status === "read";
        });
        setIsReadNotificationEmpty(areAllRead);
    }, [notification]);

    const successFunction = () => {
        dispatch(removeNotification(removeNotification1?.id));
        setShowNotificationSetting(false);
        setNotification1({ remove: false, id: "" })
    };

    const discardFunction = () => {
        setShowNotificationSetting(false);
        setNotification1({ remove: false, id: "" })
    }

    return (
        <div className='parent' style={NotificationStyle && NotificationStyle}>
            <section className='notification' style={notifyStyle && notifyStyle}>

                <div className='row_1'>
                    <h1>Notifications</h1>

                    <div className='row_1_top_icon' ref={notification_setting}>
                        <div className="threeDotsHorizontalIocn" onClick={() => setShowNotificationSetting(!showNotificationSetting)}>
                            <FontAwesomeIcon icon={faEllipsis} />
                        </div>

                        {showNotificationSetting &&
                            <NotificationSetting notificationPage={notificationPage} setShowNotificationSetting={setShowNotificationSetting} />
                        }
                    </div>
                </div>

                <div className='row_2'>
                    <p onClick={() => setUnReadNotification(false)} className={`${UnReadNotification ? "" : "active"}`}>All</p>

                    <p onClick={() => setUnReadNotification(true)} className={`${UnReadNotification && "active"}`}>Unread</p>
                </div>
                {
                    UnReadNotification ?

                        <div className="unRead_notification">
                            {
                                notification && notification.length > 0 && notification.filter(val => val.status === "unread")
                                    .map((val, index) => (
                                        <React.StrictMode key={index}>
                                            <Notification val={val} setNotification={setNotification1} />
                                        </React.StrictMode>
                                    ))
                            }


                            {
                                isReadNotificationEmpty &&
                                <div className='empty_read_notification'>
                                    <FontAwesomeIcon icon={faBell} />
                                    <h1>You have no notifications</h1>
                                </div>
                            }

                        </div>

                        :

                        <>

                            <div className='row_3'>
                                <h3>Earlier</h3> {/* new */}

                                {
                                    notificationPage ??
                                    <NavLink to={"/notifications"}>
                                        See all
                                    </NavLink>
                                }
                            </div>

                            {/* <div className='new_notification'>
                            <Notification isNewNotification={true} />
                        </div> */}

                            <div className='Earlier_notification'>
                                {/* <h3>Earlier</h3> */}
                                {
                                    notification && notification.length > 0 && notification.map((val, index) => {
                                        return (
                                            <React.StrictMode key={index}>
                                                <Notification val={val} setNotification={setNotification1} />
                                            </React.StrictMode>
                                        )
                                    })
                                }
                            </div>
                        </>
                }


                <div className="confirmation_message"
                    style={{
                        transform: `${removeNotification1?.remove ? "scale(1)" : "scale(0)"}`, minHeight: `${removeNotification1?.remove ? "70px" : "0px"}`,
                        height: "0px",
                        position: "sticky",
                        bottom: "-0.1rem",
                        left: "0rem",
                        background: "rgb(255 255 255)",
                        width: "100%",//"352px"
                        borderRadius: "0px 0px 10px 10px",
                        padding: "0 1rem",

                        ...confirmationBoxStyle
                    }} >
                    <p>
                        Are you sure you want to remove this notification?
                    </p>

                    <div className="groupOfBtn">
                        <button style={{ display: `${removeNotification1?.remove ? "block" : "none"}` }}
                            onClick={successFunction}>
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button style={{ display: `${removeNotification1?.remove ? "block" : "none"}` }}
                            onClick={discardFunction}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Notifications;