import React, { useState, useRef, useEffect } from "react";
import "../../style/PostCreate.css";
import { ImageIcon, AddAPhoto } from "../../constant/IconsPath";
import emojiIcon from "../../assets/img/Emoji.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import EmojiPicker from "emoji-picker-react";
import { profile } from "../../constant/imagePath";
import { get, post } from "../../api/api";
import axios from "axios";
import StorageService from "../../services/StorageService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import { updateTrendingPostData } from "../../Store/TrendingPageSlice";

const EditPostSection = ({ open, setTopic, flag, setFlag, MainClassName, setEditPost, EditPost, data,setPostData }) => {

  const { id, userName: name, content, title: Usertitle, createdAt: date, profilePicture: logo, picture: postImage, commentModel: comments } = data;
  const location = useLocation();
  const dispatch = useDispatch();
  const [showEmoji, setShowEmoji] = useState(false);
  const [image, setImages] = useState([]);
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(content);
  const [title, setTitle] = useState(Usertitle);
  const [group, setGrop] = useState("");
  const [base64, setBase64] = useState("");
  const [groupData, setGroupData] = useState([]);
  let navigate = useNavigate();
  const inputFieldMessage = useRef();
  const modalRef = useRef();
  const emoji_close = useRef();

  
  const getDataById = async (postId) => {
    const token = StorageService.getJwt();
    try {
      const res = await axios.post(
        `${StorageService?.baseURL}/Post/ReadPost/${postId}?userId=${StorageService?.getValue("userId")?StorageService?.getValue("userId"):null}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPostData(res.data);
    } catch (error) {
      console.error("Error fetching post data:", error);
    }
  };
  const postNow = async () => {
    let data = {
      Title: title,
      Content: inputValue,
      Picture: base64,
    };

    const token = StorageService.getJwt();
    let header = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.post(`${StorageService?.baseURL}/Post/UpdatePost/${id}`, data, header);

    if (res.status == 200) {
      toast.success(res?.data?.message);
      setEditPost(false)
      getDataById(id)
    } else {
      toast.error(res?.data?.message);
    }
  };

  const [MainClassNameStyle, setMainClassNameStyle] = useState({
    transform: "scale(0)",
    height: "0px",
    margin: "2.5rem 0 0 0",
  });

  function showImageUpload() {
    setFlag(true);

    const image_upload1 = document.querySelector(`.${MainClassName}`);
    const main_part = document.querySelector(".main_part");
    const closeIconStyle = document.querySelector(`.${MainClassName} svg`);

    if (image_upload1) {
      const image_upload_style = window.getComputedStyle(image_upload1);

      if (image_upload_style.height === "0px") {
        main_part.style.margin = "2.5rem 0 1rem 0";
        image_upload1.style.height = "184px";
        setMainClassNameStyle({
          transform: "scale(1) translateY(0)",
          height: "184px",
          margin: "2.5rem 0 1rem 0",
          display: "block",
          transition: "transform 1.5s",
        });
        closeIconStyle.style.display = "block";
      } else {
        main_part.style.margin = "2.5rem 0 0 0";
        image_upload1.style.height = "0px";
        setMainClassNameStyle({
          transform: "scale(0) translateY(-100%)",
          height: "0px",
          margin: "2.5rem 0 0 0",
          display: "none",
          transition: "transform 1.5s",
        });
        closeIconStyle.style.display = "none";
      }
    }
  }
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image.some((e) => e.name === file[i].name)) {
          setImages((prevImages) => [
            ...prevImages,
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }
        setFlag(false);
      }
    }
  };

  const [base64String, setBase64String] = useState("");

  function onFileSelect(event) {
    const file = event.target.files[0];
    setBase64(file);
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64 = event.target.result;
      setBase64String(base64);
    };

    reader.readAsDataURL(file);
    // if (file.length === 0) return;
    // for (let i = 0; i < file.length; i++) {
    //   if (file[i].type.split("/")[0] !== "image") {
    //     alert("Online image support");
    //   } else {
    //     if (!image.some((e) => e.name === file[i].name)) {
    //       setImages((prevImages) => [
    //         ...prevImages,
    //         {
    //           name: file[i].name,
    //           url: URL.createObjectURL(file[i]),
    //         },
    //       ]);
    //     }
    //     setFlag(false);
    //   }
    // }
  }

  function resetFunction() {
    setTitle("");
    setGrop(""); // Set group to null or an empty value
    setInputValue("");
    setImages([]);
  }

  function resetAllValue() {
    setTopic(false);
    setEditPost(false);
    resetFunction();
    setInputValue("");

    const image_upload = document.querySelector(`.${MainClassName}`);
    if (image_upload) {
      image_upload.style.height = "0px";
      image_upload.style.transform = "scale(0)";
    }

    const main_part = document.querySelector(".main_part");
    if (main_part) {
      main_part.style.margin = "2.5rem 0 0 0";
    }

    const closeIconStyle = document.querySelector(".closeIconStyle");
    if (closeIconStyle) {
      closeIconStyle.style.display = "none";
    }

    const imageUpload = document.querySelector(".imageUpload");
    if (imageUpload) {
      imageUpload.style.height = "0px";
      imageUpload.style.transform = "scale(0)";
    }

    const title_field = document.querySelector(".title_field");
    title_field.value = "";
  }

  function onFunction(e) {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      resetAllValue();
    }
  }

  function emojiCloseFn(e) {
    if (emoji_close.current && !emoji_close.current.contains(e.target)) {
      setShowEmoji(false);
    }
  }


  return (
    <div
      className={"postCreate"}
      style={{
        opacity: EditPost ? 1 : 0,
        cursor: EditPost ?? "none",
        zIndex: EditPost ? 11111111111 : -1,
      }}
      onClick={onFunction}
    >
      <div className="center_div" ref={modalRef} onClick={emojiCloseFn}>
        {/* top part */}
        <div className="post_create_top_part">
          <p className="text-[#fff]">
            <span style={{ color: "#fff" }}>
              {EditPost ? "Edit Post" : "Add Post"}
              {/* {MainClassName && MainClassName === "image_upload" ? "Create Post" : "Edit Question"} */}
            </span>
          </p>
          {/* close icon */}
          <div className="close_icon" onClick={resetAllValue}>
            <div className="close_icon">
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </div>

        {/* main part */}
        <div className="main_part">
          {/* logo and name */}
          <div className="logo_and_name">
            <div className="logo">
              <img src={`data:image/png;base64,${postImage}`} alt="Mochat logo" />
            </div>
            <h6 className="text-[#fff]">{data?.readUserModel?.userName}</h6>
          </div>

          {/* post title */}
          <div className="post_title">
            <input type="text" value={title} placeholder="Post title.." className="title_field textFild01" onChange={(e) => setTitle(e.target.value)} />
          </div>
          {/* <div className="post_title">
            <select type="select" placeholder="Select Group.." value={group} className="title_field textFild01" onChange={(e) => setGrop(e.target.value)}>
              <option value="" disabled selected>
                Select Group..
              </option>
              {groupData && groupData?.map((ele) => <option value={ele?.id}>{ele?.name}</option>)}
            </select>
          </div> */}

          {/* text area */}
          <div className="text_area">
            <textarea
              ref={inputFieldMessage}
              placeholder={MainClassName && MainClassName === "image_upload" ? "Write your caption here.." : "Write your question here.."}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="textArea01"
            />

            <div className="bottom_part " style={{display:"flex",justifyContent:"end"}}>
              {/* image icon */}
              {/* <img src={ImageIcon} alt="icon" onClick={showImageUpload} width={"auto"} height={"auto"} /> */}

              <div ref={emoji_close} style={{ position: "relative" }}>
                {/* emoji icon */}
                <img src={emojiIcon} alt="Emoji Icon" onClick={() => setShowEmoji((val) => !val)} />

                {/* emoji */}
                {showEmoji && (
                  <EmojiPicker
                    onEmojiClick={(event) => {
                      setInputValue((prevValue) => `${prevValue} ${event.emoji}`);
                    }}
                    autoFocusSearch={false}
                    searchDisabled
                    width="100%"
                    lazyLoadEmojis={true}
                  />
                )}
              </div>
            </div>
          </div>

          {/* *****************IMAGEEEEEEEEEEEE SECTIONNNNNNNNNNNNNNNN********************* */}

          {/* photo upload area */}
          <div
            style={{
              minWidth: "100%",
              minHeight: "200px",
              maxWidth: "100%",
              maxHeight: "400px",
              backgroundRepeat:"no-repeat",
              backgroundSize:'cover',
              objectFit:"cover",
              backgroundImage: `url(${base64String ? base64String : `data:image/png;base64,${postImage}`})`,
            }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => inputRef.current.click()}
          >
            {/* <img src={`data:image/png;base64,${postImage}`} /> */}
            <input name="file" type="file" onChange={onFileSelect} hidden ref={inputRef} />
          </div>

          {/* {flag ? (
            <div
              className={MainClassName}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{ ...MainClassNameStyle }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                onClick={showImageUpload}
                className="closeIconStyle"
              />

              <div className="center">
                <input
                  name="file"
                  type="file"
                  onChange={onFileSelect}
                  hidden
                  ref={inputRef}
                />

                <img
                  src={AddAPhoto}
                  alt="add"
                  onClick={() => inputRef.current.click()}
                />

                <p onClick={() => inputRef.current.click()}>Add a Photo</p>
                <p>or drag and Drop</p>
              </div>
            </div>
          ) : (
            <div className="imageUpload">
              <div className="close_icon">
                <FontAwesomeIcon icon={faXmark} onClick={resetFunction} />
              </div>
              {image &&
                image.length > 0 &&
                image.map((images, index) => {
                  return <img src={images.url} alt={images.name} key={index} />;
                })}
            </div>
          )} */}
        </div>

        {/* bottom part button */}
        <div className="post_button" onClick={postNow}>
          <p>
            <span className="text-[#000]"> Edit Post</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditPostSection;
