import { createSlice } from "@reduxjs/toolkit";

const MessageLeftSideShowAndHide = createSlice({
  name: "showAndHide",
  initialState: true,
  reducers: {
    add(state, action) {
      return (state = action.payload);
    },
  },
});

export const { add } = MessageLeftSideShowAndHide.actions;
export default MessageLeftSideShowAndHide.reducer;
