import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MochatPostSection from "../../components/post/MochatPostSection";
import { ForYouIcon, NewTopicIcon, TrendingTopicIcon } from "../../constant/IconsPath";
import { BsStar } from "react-icons/bs";
import { useEffect } from "react";
import { post } from "../../api/api";
import { updateTrendingPostData } from "../../Store/TrendingPageSlice";
import { SyncLoader } from "react-spinners";
import StorageService from "../../services/StorageService";
import FilterTopic from "../../components/filterTopic/FilterTopic";
const TrendingPage = ({ TrendingPost, topPost, newPost, forYou }) => {
  const { postData, search } = useSelector((state) => state.Trending);
  console.log(postData, "postDatapostData")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [noData, setNData] = useState(true);
  const [toggelFilter, settoggelFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateTrendingPostData({ data: [], route: "tranding" }));
    const timer = setTimeout(() => {
      getTrandingPosts(page,search);
      setNData(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  const sectionRef = useRef();
  const datas = useRef(postData);
  const pageNo = useRef(page);
  const newSearch = useRef(search);
  newSearch.current = search;
  datas.current = postData;
  pageNo.current = page;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const totalCount = [...new Set(datas.current.map((item) => item.totalRecordCount))];
    
        if (entry.isIntersecting && !loading && pageNo.current < Math?.ceil(totalCount[0] / 3)) {
          getTrandingPosts(pageNo.current + 1,newSearch.current);
          setPage((prevPage) => prevPage + 1);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const getTrandingPosts = async (pageNumber,search) => {
    setLoading(true);
    try {
      let res = await post(
        `/Post/TopVotedIn24Hours?pageNumber=${pageNumber - 1}&pageSize=3&search=${search?.searchValue}&userId=${StorageService?.getValue("userId")?StorageService?.getValue("userId"):null}`
      );
      let data = await res.json();
      if (res && data) {
        dispatch(updateTrendingPostData({ data, route: "tranding" }));
        const timer = setTimeout(() => {
          setLoading(false);
        }, 500);
        return () => clearTimeout(timer);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching trending posts:", error);
      setLoading(false);
    }
  };
  

  const renderPosts = () => {
    if (loading || noData) {
      return null;
    } else if (postData?.length === 0) {
      if (!loading) {
        return <p className="empty flex items-center justify-center">No data found...</p>;
      }
    }
  };
console.log(postData, "postDatapostDatapostData22");
  
  return (
    <React.StrictMode>
      <FilterTopic tabValue={"trending"}/>

      {postData?.length &&
        postData?.map((value, index) => {
          return (
            <React.StrictMode key={index}>
              <MochatPostSection data={value} updateState={getTrandingPosts} />
            </React.StrictMode>
          );
        })}
      <div ref={sectionRef} className="flex justify-center items-center">.
        {loading && <SyncLoader color="#fea500" loading={true} height={15} width={5} />}
      </div>
      {renderPosts()}
    </React.StrictMode>
  );
};

export default TrendingPage;
