import React from "react";

// Image
import { PcIcon, SmartPhoneIcon } from "../../../constant/IconsPath";

const LoginSessions = () => {
  return (
    <div
      className="border-2 border-solid border-slate-200 m-5 sm:m-0 loginSession bg-[#131313]"
      style={{ marginBottom: "6rem", border: "1px solid rgba(138, 152, 164, 0.47)" }}
    >
      <div className="bg-[#c8ced436] px-[15px] sm:px-[30px] py-5 loginSessionHeading">
        <h3 className="text-[20px] font-bold text-white">Login Sessions</h3>
      </div>
      <div className="pl-[15px] sm:pl-[30px] py-9 loginSessionCenterContent bg-[#131313]">
        <h3 className="text-[20px] font-bold text-white">
          Following are your active sessions
        </h3>
        {/* </div> */}
        <div className="sm:mx-[29px] mt-5">
          <div className="flex items-center justify-between py-5">
            <div className="flex flex-row items-center justify-center">
              <img
                src={PcIcon}
                alt="Pc Icon"
                className="text-4xl text-[#1C1C1C]"
              />
              <div className="ml-5">
                <p>
                  <span className="text-white">
                    Windows PC - Chrome
                  </span>
                </p>
                <p>
                  <span className="text-white">
                    Karachi, Pakistan -{" "}
                  </span>
                  <span className="font-bold text-[#089252]">Active Now</span>{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="sm:flex items-start justify-between py-5 border-y border-slate-200 border-[#8a98a466]">
            <div className="flex flex-row items-center justify-start sm:justify-center">
              <img
                src={PcIcon}
                alt="Pc Icon"
                className="text-4xl text-[#1C1C1C]"
              />
              <div className="ml-5">
                <p><span className="text-white"> Mac OS - Safari </span></p>
                <p>
                  <span className="text-white">
                    Karachi, Pakistan -{" "}
                  </span>
                  <span className="font-bold text-[#fff]">5 Hous Ago</span>{" "}
                </p>
              </div>
            </div>
            <span className="text-xl font-light text-[#FEA500] text-center block mt-7 sm:mt-0">
              End Session
            </span>
          </div>

          <div className="sm:flex items-start justify-between py-5 loginSessionLastDeviceStyle">
            <div className="flex flex-row items-center justify-start sm:justify-center">
              <img
                src={SmartPhoneIcon}
                alt="smart phone icon"
                className="text-5xl text-[#1C1C1C]"
              />
              <div className="ml-5">
                <p>
                  <span className="text-white">
                    IOS - Safari
                  </span>
                </p>
                <p>
                  <span className="text-white">Islamabad, Pakistan -{" "}</span>
                  <span className="font-bold text-[#fff]">Yesterday</span>{" "}
                </p>
              </div>
            </div>
            <span className="text-xl font-light text-[#FEA500]  text-center block mt-7 sm:mt-0">
              End Session
            </span>
          </div>

          <p className="text-xl text-[#ffffff80] text-right mt-4 loginSessionTextStyle">
            Logout of all sessions
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginSessions;
