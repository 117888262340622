import React, { useEffect, useState } from "react";
import { FileUploaded } from "../../components/Models/Index";
import { FileNotUploaded } from "../../components/Models/Index";
import CropEasy from "../../components/Crop/CropEasy";
import { logo4, profile, default_cover } from "../../constant/imagePath";
import BackgroundImage from "../../assets/img/image 91.webp";
import { CameraIcon } from "../../constant/IconsPath";
import classes from "./EditProfile.module.css";
import "../../style/EditProfile.css";
import { toast } from "react-toastify";
import StorageService from "../../services/StorageService";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import axios from "axios";


const EditProfile = () => {
  const dispatch = useDispatch();
  const [uploaded, setUploaded] = useState(false);
  const [notUploaded, setNotUploaded] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [userBio, setUserBio] = useState("");
  const [image, setImages] = useState([]);
  const [photoURL, setPhotoURL] = useState(null);
  const [profile, setProfile] = useState(null);
  const [coverPic, setCoverPic] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [username, setUsername] = useState("");
  const [isUpdated, setUpdated] = useState({
    updated: false,
    bio: "",
    prifile: "",
    coverPic: "",
    prevUserName: "",
  });
  const someValue = StorageService.getValue('userName');
  const someProfile = StorageService.getValueProfilePic('profilePic');
  const secondSrc = someProfile != "null" ? `data:image/jpeg;base64,${someProfile}` : null;
  const firstSrc = photoURL ? (photoURL.length > 100 ? `data:image/jpeg;base64,${photoURL}` : photoURL) : secondSrc || logo4;
  const cover = coverPic ? `data:image/jpeg;base64,${coverPic}` : default_cover;
  let userId = StorageService?.getValue("userId")

  const fileChange = (e) => {
    const file = e.target.files;
    if (file.length === 0) return;
    const files = e.target.files[0];
    setCoverPic(files);
    switch (files.type) {
      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        setUploaded(true);
        break;
      default:
        setNotUploaded(true);
    }


    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image.some((e) => e.name === file[i].name)) {
          setImages((prevImages) => [
            ...prevImages,
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }
        setFlag2(true);
      }
    }
  };

  useEffect(() => {
    // useEffect logic...
    getUserData()
  }, []);

  const getUserData = async () => {
    try {
      dispatch(startLoading());
      const token = StorageService.getJwt();
      const response = await axios.post(
        `${StorageService?.baseURL}/Identity/GetUser?userId=${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        const userData = response?.data?.user;
        setUsername(userData?.userName);
        setUpdated((prevState) => ({
          ...prevState,
          prevUserName: userData?.userName,
          bio: userData?.bio,
          prifile: userData?.profilePicture,
          coverPic: userData?.coverPicture,
        }));
        setUserBio(userData?.bio);
        setProfile(userData?.profilePicture);
        setCoverPic(userData?.coverPicture);
        StorageService.setValue("userName", userData?.userName);
        StorageService?.setJwt(response?.data?.token);
        StorageService.setValueProfile("profilePic", userData?.profilePicture);
        dispatch(stopLoading());
      }
    } catch (error) {
      dispatch(stopLoading());
      console.error("Error fetching user data:", error);
    }
  };

  const changeCoverFunction = () => {
    setFlag2(false);
  };

  const resetCoverFunction = () => {
    setImages([]);
    setFlag2(false);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    setProfile(file);
    if (file) {
      setOpenCrop(true);
      setPhotoURL(URL.createObjectURL(file));
    } else {
      setOpenCrop(false);
      setPhotoURL(null);
    }
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    try {
      const token = StorageService.getJwt();
      const formData = new FormData();
      if (isUpdated.updated) {
        formData.append("UpdateUserName", username);
      } else {
        formData.append("UpdateUserName", "");
      }
      formData.append("Bio", userBio);

      if (profile) {
        formData.append("ProfilePicture", profile);
      }

      if (coverPic) {
        formData.append("CoverPicture", coverPic);
      }

      const response = await axios.post(`${StorageService?.baseURL}/Identity/UpdateProfile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        }
      });

      if (response) {
        toast.success('Your profile has been updated!');
        getUserData()

      }

    } catch (error) {
      toast.error(error?.response?.data)
      console.error("Error111:", error);
    }
  };

  return (
    <React.StrictMode>
      <FileNotUploaded
        toggle={() => setNotUploaded((prev) => !prev)}
        open={notUploaded}
      />
      <FileUploaded
        toggle={() => setUploaded((prev) => !prev)}
        open={uploaded}
      />
      {openCrop && (
        <CropEasy
          {...{ photoURL, setOpenCrop, setPhotoURL }}
          profile={firstSrc}
        />
      )}
      <div className="border-2 border-solid border-slate-200 m-5 sm:m-0 sm:mb-6 EditProfile bg-[#131313]" style={{ border: "1px solid rgba(138, 152, 164, 0.47)" }}>
        <div className="bg-[#c8ced436] px-[30px] py-5 editProfileHeading">
          <h3 className="text-[20px] font-bold text-[#fff]">Edit Profile</h3>
        </div>

        <div className={classes["bg-container"]}>
          <div
            className={`${classes["bg-profile"]} EditProfileBgProfile`}
            style={{
              filter: flag2 && "contrast(1) brightness(1)",
              overflow: flag2 ? "auto" : "hidden",
            }}
          >
            {image && image.length > 0 ? (
              image.map((images, index) => {
                return (
                  <img
                    src={images.url}
                    alt={images.name}
                    key={index}
                    style={{ width: "100%" }}
                  />
                );
              })
            ) : (
              <div
                className="BackgroundImage"
                style={{
                  backgroundImage: `url('${cover ? cover : default_cover}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100%",
                  backgroundSize:'cover'
                }}
              ></div>
            )}
          </div>

          {flag2 ? (

            <div
              style={{ position: "absolute", right: "0.5rem", top: "0.5rem" }}
            >
              {/* <img
                src={coverPic} 
                alt="Cover"
                style={{ width: "100%" }}
              /> */}
              <button
                className="hover:opacity-[.7]"
                style={{
                  color: "rgb(255, 255, 255)",
                  fontWeight: "500",
                  borderRadius: "5px",
                  width: "85px",
                  margin: "0 0.5rem",
                  backgroundColor: "rgb(0, 0, 0)",
                }}
                onClick={resetCoverFunction}
              >
                Cancel
              </button>
              <button
                className="SaveChangesStyle hover:opacity-[.7]"
                onClick={changeCoverFunction}
                style={{
                  color: "rgb(0, 0, 0)",
                  fontWeight: "500",
                  borderRadius: "5px",
                  width: "120px",
                  margin: "0 0.5rem",
                  background: "rgb(255 160 0)",
                }}
              >
                Save changes
              </button>
            </div>
          ) : (
            <div className={classes["middle"]}>
              <div className={classes["upload-btn-wrapper"]}>
                <button className={classes["btn"]}>Change Cover</button>
                <input type="file"
                  name="myfile"
                  onChange={fileChange}
                />
              </div>
            </div>
          )}
        </div>
        {/* profile image */}
        <div className="flex items-center justify-center">
          <div
            className="w-[93px] h-[93px] sm:w-[150px] sm:h-[150px] bg-white rounded-full -mt-14 sm:-mt-[77px] border border-gray-100" // Added border classes
            style={{
              zIndex: "1",
              boxShadow: "0 4px 34px 0 rgba(0, 0, 0, 0.12)",
              background: "rgba(255, 255, 255, 0.8)",
            }}
          >
            <div className="w-40 h-40 sm:w-40 sm:h-40 rounded-full overflow-hidden relative" style={{ marginLeft: "-10px", marginTop: '-10px' }}>
              <img
                className="w-40 h-40  rounded-full object-cover"
                src={firstSrc}
                alt="Profile"
              />
            </div>

            <label htmlFor="profilePhoto">
              <div
                className="bg-primary h-[30px] w-[30px] rounded-full flex items-center justify-center -mt-[50px] ml-[115px] relative editProfileCameraStyle"
                style={{ cursor: "pointer", background: "rgba(0, 0, 0, 0.5)" }}
              >
                <img
                  src={CameraIcon}
                  alt="camera"
                  className="text-white text-xl"
                />

                <input
                  accept="image/*"
                  id="profilePhoto"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleChange}
                />
              </div>
            </label>
          </div>
        </div>



        {/* form */}
        <form
          action="#"
          className="w-full lg:w-[600px] mx-auto mt-3  p-0 sm:p-3"
        >

          <div className="my-5 mx-[18px] sm:mx-0">
            <label
              className="block text-lg sm:text-xl font-bold mb-[10px] text-[#fff]"
              htmlFor="username"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUpdated((prevState) => ({ ...prevState, updated: prevState.prevUserName !== e.target.value }));
                setUsername(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === " ") { // Check if the pressed key is space
                  e.preventDefault(); // Prevent default behavior (typing space)
                }
              }}
              className="rounded-md w-full h-[34px] pl-1 bg-[transparent] hover:bg-[#1c1c1c] focus:bg-[#1c1c1c] text-[#fff]"
              style={{ border: "0.71px solid rgba(199, 199, 199, 0.5)", outline: "none" }}
              placeholder="Enter your username..."
            />
          </div>

          <div className="relative mx-[18px] sm:mx-0 BioStyle">
            <label
              className="block text-lg sm:text-xl font-bold mb-[10px] text-[#fff]"
              htmlFor="bio"
            >
              Bio
            </label>
            <textarea
              type="text"
              id="bio"
              className="rounded-md w-full h-[140px] pl-1 pt-1 bg-[transparent] textAareaBio text-[#fff]"
              style={{ border: "0.71px solid rgba(199, 199, 199, 0.5)", borderBottom: "none", outline: "none" }}
              placeholder="Tell us your favourite movies, actors and genres..."
              value={userBio === "null" ? "" : userBio}
              onChange={(e) => setUserBio(e.target.value)}
              maxLength={250}
            />

            <span className="absolute right-6 textxl text-[#868B90]" style={{ border: "0.71px solid rgba(199, 199, 199, 0.5)", borderTop: "none" }}>
              {userBio
                ? userBio.length < 10
                  ? `0${userBio.length}/250`
                  : `${userBio.length}/250`
                : "00/250"}
            </span>
          </div>

          <div className="block sm:flex items-center justify-end px-2 sm:px-0 mb-9 mt-5 editProfileBottomButton">
            <button
              onClick={(e) => saveChanges(e)}
              type="submit"
              className="bg-[#ffa000] text-xl text-black px-[45px] py-[10px] rounded-md w-full sm:w-auto hover:opacity-[.7]"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </React.StrictMode>
  );
};

export default EditProfile;
