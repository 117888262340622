import React, { useState, useRef, useEffect } from "react";

// React Toolkit
import { useDispatch } from "react-redux";
import { deleteReply, replyAdd } from "../../Store/PostsSlice";

// Components
import EditComment from "./EditComment";

// Emoji Picker
import EmojiPicker from "emoji-picker-react";

// Icons
import {
  DarkEmojiIcon,
  DownArrow1Icon,
  TopArrowIcon,
} from "../../constant/IconsPath";
import { logo4 } from "../../constant/imagePath";

// Font Awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import StorageService from "../../services/StorageService";
import { post } from "../../api/api";
import axios from "axios";
import { toast } from "react-toastify";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import "./comment.css";

const NestedComment = ({
  data,
  star2,
  star,
  homeView,
  showMore,
  setMessageForLogin,
  messageForLogin,
  getCommentById,
  setNestedLimitedCommentShow,
  NestedlimitedCommentShow,
}) => {
  const {
    id,
    canEdit,
    postId,
    content: text,
    convertedDate: time,
    reply,
    replyWith,
  } = data || "";
  const dispatch = useDispatch();
  const someProfile = StorageService.getValueProfilePic("profilePic");
  const secondSrc =
    someProfile != "null" ? `data:image/jpeg;base64,${someProfile}` : null;
  const [pushAndDown, setPushAndDown] = useState(null);
  const [showNestedCommentTextArea, setShowNestedCommentTextArea] =
    useState(false);
  const [nestedInputValue1, setNestedInputValue1] = useState("");
  const [nestedShowEmoji1, setNestedShowEmoji1] = useState(false);
  const nestedInputFieldMessage1 = useRef("");
  const EmojiIcon11 = useRef("");
  const [isEmpty2, setIsEmpty2] = useState(true);
  const [responsiveNestedCommentFunction, setResponsiveNestedCommentFunction] =
    useState(false);
  const [isEditNested, setIsEditNested] = useState(false);

  const responsiveNestedCommentFn = useRef("");

  useEffect(() => {
    const handler123 = (e) => {
      if (
        responsiveNestedCommentFn.current &&
        !responsiveNestedCommentFn.current.contains(e.target)
      ) {
        setResponsiveNestedCommentFunction(false);
      }
    };

    document.addEventListener("mousedown", handler123);

    return () => {
      document.removeEventListener("mousedown", handler123);
    };
  }, []);

  const nestedCancelReplyFunction1 = () => {
    setNestedInputValue1("");
    setShowNestedCommentTextArea(false);
  };

  const UpVote = async (CommentId, postId) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return;
    }
    setPushAndDown(true);
    const token = StorageService.getJwt();
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Comment/UpVote/${CommentId}`, header);
      // if (res) {
        // console.log("rescall");  
        getCommentById(showMore, postId);
      // }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const DownVote = async (CommentId, postId) => {
    if (homeView) {
      setMessageForLogin(!messageForLogin);
      return;
    }
    const token = StorageService.getJwt();
    setPushAndDown(false);
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await post(`/Comment/DownVote/${CommentId}`, header);
      if (res) {
        getCommentById(showMore, postId);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const nestedReplyAddFunction1 = async (postId, commentId) => {
    const token = StorageService.getJwt();
    try {
      const header = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      let body = {
        content: nestedInputValue1,
      };
      let res = await post(
        `/Comment/CreateReplyComment/${postId}/${commentId}`,
        body,
        header
      );
      
        setShowNestedCommentTextArea(false);
        getCommentById(showMore, postId);
      
    } catch (error) {
      // showNestedCommentTextArea()
      setShowNestedCommentTextArea(false);
      setNestedInputValue1("");
      setNestedShowEmoji1("");
      setIsEmpty2(true);
      console.error("Error sending reply:", error);
    }
  };

  const replyFn = () => {
    setShowNestedCommentTextArea(true);
  };

  const checkingIsEmptyOrNot = (e) => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(e.target);
    range.collapse(false); // Set cursor to end of the content
    selection.removeAllRanges();
    selection.addRange(range);

    setNestedInputValue1(e.target.innerText);
    // setNestedInputValue1(nestedInputFieldMessage1.current.innerText)
    // setIsEmpty2(nestedInputFieldMessage1.current.innerText.trim().length === 0);
  };

  const checkingIsEmptyOrNot1 = (element) => {
    setIsEmpty2(false);
    setNestedInputValue1(
      `${nestedInputFieldMessage1.current.innerText} ${element.emoji}`
    );
  };

  const editCommentFn = () => {
    setIsEditNested(true);
    setResponsiveNestedCommentFunction(false);
  };

  const delete_comment = async (id, e) => {
    e.preventDefault();

    const token = StorageService.getJwt();
    let header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let res = await axios.post(
      `${StorageService?.baseURL}/Comment/DeleteComment/${id}`,
      {},
      header
    );

    if (res.status == 200) {
      toast.success("Comment deleted");
      let filterData = NestedlimitedCommentShow?.filter((ele) => ele.id !== id);
      setNestedLimitedCommentShow(filterData);
      setResponsiveNestedCommentFunction(false);
    } else {
      toast.error(res?.data?.message);
    }
  };
  const updateComment = () => {
    setIsEditNested(false);
  };

  const convertToLinks = (inputText) => {
    const specificDomains = [
      "filmwaffle.com",
      "disneyplus.com",
      "netflix.com",
      "appletv.com",
      "paramountplus.com",
      "shudder.com",
      "youtube.com",  
      "primevideo.com",
      "nowtv.com",
      "imdb.com",
      "youtu.be",
      "wikipedia.org"

    ];
  
    const specificDomainPattern = new RegExp(
      `\\b(https?:\\/\\/)?(www\\.)?(${specificDomains.join("|")})(\\/[^\\s]*)?\\b`,
      "gi"
    );
  
    const linkifiedText = inputText.replace(
      specificDomainPattern,
      (match) => {
        const url = match.startsWith("http") ? match : `https://${match}`;
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="linkified">${match}</a>`;
      }
    );
    return { __html: linkifiedText };
  };

  return (
    <React.StrictMode>
      {isEditNested ? (
        <EditComment
          comment={reply}
          UpdateComment={updateComment}
          setIsEdit={setIsEditNested}
          styleApply={{
            width: "90%",
            responsiveWidth: "reponsiveWidth",
            justifyContent: "flex-end",
            imageSize: "30px",
          }}
          name={data?.readUserModel?.userName}
          // commentId={commentId}
          // parentId={parentId}
          replyId={id}
        />
      ) : (
        <div className="mobileResponsive nestedMobileResponsive">
          <div className="mobileResponsive_left_side1">
            {/* image */}
            <img
              src={
                data?.readUserModel?.profilePicture
                  ? `data:image/png;base64,${data?.readUserModel?.profilePicture}`
                  : logo4
              }
              alt="user"
              style={{
                height: "50px",
                width: "50px",
                objectFit: "cover",
                borderRadius: "100px",
              }}
            />
          </div>
          <div className="mobileResponsive_right_side">
            <div className="top_area">
              <div className="flex items-center justify-start responsive_comment_design">
                <h3 className="text-lg font-md text-[#fff]">
                  {data?.readUserModel?.userName}
                </h3>
                <p className="text-xs ml-3 text-[#999999]">
                  {time ?? "8 min ago"}
                </p>
              </div>
              <div
                className="top_area_right_side"
                ref={responsiveNestedCommentFn}
              >
               {canEdit&& <FontAwesomeIcon
                  icon={faEllipsis}
                  className="horizontalThreeDotsIcon"
                  onClick={() =>
                    setResponsiveNestedCommentFunction(
                      !responsiveNestedCommentFunction
                    )
                  }
                />}
                {responsiveNestedCommentFunction && (
                  <div className="commentFunctionality">
                    {/* edit comment */}
                    {/* <div className="editComment" onClick={editCommentFn}>
                      <FontAwesomeIcon icon={faPen} color="#fea500" />
                      <p>Edit Comment</p>
                    </div> */}

                    {/* Delete comment */}
                    <div
                      className="deleteComment"
                      onClick={(e) => delete_comment(id, e)}
                    >
                      <FontAwesomeIcon icon={faTrash} color="#fea500" />
                      <p>Delete Comment</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="comment_area">
              <p className="text-[#868B90] font-light text-base mb-2 user_comment_design">
                <span
                  dangerouslySetInnerHTML={convertToLinks(text ?? "---")}
                ></span>
              </p>
            </div>

            <div className="bottomArea">
              <div className="bottomArea_left_side">
                <div
                  className="flex items-center justify-center push_arrow cursor-pointer upWardStyle"
                  onClick={() => UpVote(id, postId)}
                >
                  {/* <TopArrowIcon topArrow_color={data?.readVote?.upvoteFlag ? "#ffa000" : "#999999"} width="20" height="20" /> */}
                  <FontAwesomeIcon
                    icon={faStar}
                    color={data?.readVote?.upvoteFlag ? "#ffa000" : "#999999"}
                  />
                  <span
                    className="text-[17px]"
                    style={{
                      marginLeft: "0.1rem",
                      color: data?.readVote?.upvoteFlag ? "#ffa000" : "#999999",
                    }}
                  >
                    {data?.readVote?.upvoteCount || 0}
                  </span>
                </div>

                <div
                  className="flex items-center justify-center ml-2 down_arrow cursor-pointer downWardStyle"
                  onClick={() => DownVote(id, postId)}
                >
                  {/* <DownArrow1Icon downArrow_color={data?.readVote?.downvoteFlag ? "#ffa000" : "#999999"} width="15" height="15.78" /> */}
                  <img
                    src={data?.readVote?.downvoteFlag ? star2 : star}
                    alt="Down arrow"
                    height="32px !important"
                    width="45px !important"
                  />
                  <span
                    className="ml-1"
                    style={{
                      fontSize: "17px",
                      color: data?.readVote?.downvoteFlag
                        ? "#ffa000"
                        : "#999999",
                    }}
                  >
                    {data?.readVote?.downvoteCount || 0}
                  </span>
                </div>
              </div>

              <div className="bottomArea_right_side">
                <button
                  className="text-primary text-base user_comment_replay_design"
                  onClick={replyFn}
                  style={{
                    cursor: "pointer",
                    border: "none",
                    background: "none",
                  }}
                >
                  Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showNestedCommentTextArea && (
        <div className="reply">
          {/* left side */}
          <div className="reply_leftSide1">
            <img
              src={!secondSrc?.includes("null") ? secondSrc : logo4}
              alt="user icon"
              width={"auto"}
              height={"auto"}
              style={{
                height: "50px",
                width: "50px",
                objectFit: "cover",
                borderRadius: "100px",
              }}
            />
          </div>

          {/* right side */}
          <div className="reply_rightSide">
            <div className="arrangeNameAndText">
              <span className="FrontSide">{data?.readUserModel?.userName}</span>
              <p
                className="typingArea"
                contentEditable={true}
                suppressContentEditableWarning={true}
                ref={nestedInputFieldMessage1}
                onKeyUp={(e) => checkingIsEmptyOrNot(e)}
              >
                {nestedInputValue1}
              </p>
            </div>

            <div className="replyBottomPart">
              {/* emoji */}
              <div className="emojiIcon" ref={EmojiIcon11}>
                {/* emoji icon */}
                <img
                  src={DarkEmojiIcon}
                  alt="Emoji Icon"
                  onClick={() => setNestedShowEmoji1((val) => !val)}
                  width={"100%"}
                  height={"100%"}
                />

                {/* emoji */}
                {nestedShowEmoji1 && (
                  <EmojiPicker
                    onEmojiClick={(event) => {
                      checkingIsEmptyOrNot1(event);
                    }}
                    autoFocusSearch={false}
                    searchDisabled
                    width="100%"
                    lazyLoadEmojis={true}
                  />
                )}
              </div>

              {/* buttons */}
              <div className="funtionButton">
                <button onClick={nestedCancelReplyFunction1}>Cancel</button>
                <button
                  className="active1"
                  onClick={() =>
                    !nestedInputValue1
                      ? null
                      : nestedReplyAddFunction1(postId, id)
                  }
                  style={{
                    opacity: !nestedInputValue1 ? 0.6 : 1,
                    cursor: !nestedInputValue1 ? "not-allowed" : "pointer",
                  }}
                  disabled={!nestedInputValue1 ? true : false}
                >
                  Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.StrictMode>
  );
};

export default NestedComment;
