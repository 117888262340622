import { CompanyLogo } from "../constant/LogoPath";
import {
    banner,
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    logo1,
    logo3,
    logo4,
} from "../constant/imagePath";

const MuchatPostData = [
    {
        id: 1,
        category: "TrendingPost",
        name: "Mochat",
        title: "Online Movie Forums",
        date: "Yesterday at 14:03",
        logo: CompanyLogo,
        // postDescription: "",
        postImage: banner,
        postPush: 5,
        postUnPush: 0,
        totalLike: 56,
    },
    {
        id: 2,
        category: "TrendingPost",
        name: "Mochat",
        title: "Online Movie Forums",
        date: "Yesterday at 14:03",
        logo: logo1,
        // postDescription: "",
        postImage: banner1,
        postPush: 5,
        postUnPush: 0,
        totalLike: 56,
    },
    {
        id: 3,
        category: "topPost",
        name: "Mochat",
        title: "Online Movie Forums",
        date: "Yesterday at 14:03",
        logo: logo3,
        // postDescription: "",
        postImage: banner2,
        postPush: 5,
        postUnPush: 0,
        totalLike: 56,
    },
    {
        id: 4,
        category: "topPost",
        name: "Mochat",
        title: "Online Movie Forums",
        date: "Yesterday at 14:03",
        logo: logo4,
        // postDescription: "",
        postImage: banner3,
        postPush: 5,
        postUnPush: 0,
        totalLike: 56,
    }
    ,
    {
        id: 5,
        category: "newPost",
        name: "Mochat",
        title: "Online Movie Forums",
        date: "Yesterday at 14:03",
        logo: logo3,
        // postDescription: "",
        postImage: banner4,
        postPush: 5,
        postUnPush: 0,
        totalLike: 56,
    },
    {
        id: 6,
        category: "forYouPost",
        name: "Mochat",
        title: "Online Movie Forums",
        date: "Yesterday at 14:03",
        logo: logo1,
        // postDescription: "",
        postImage: banner5,
        postPush: 5,
        postUnPush: 0,
        totalLike: 56,
    }
]


export default MuchatPostData;