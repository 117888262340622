import React from "react";

// Components
import AccountActivation from "../components/AccountActivation/AccountActivation";
import { Header } from "../components";

// image
import { successImg } from "../constant/imagePath";

const PasswordChangedSuccessful = () => {
  const heading = "Password Changed!";
  const content = <p>Your password has been changed successfully.</p>;
  const btnConfig = [{ id: "1", name: "Back to Login", to: "/login" }];
  return (
    <>
      <Header loginAndSignup={true} messageShow={true} sarchBarShow={false} />

      <AccountActivation
        heading={heading}
        content={content}
        btnConfig={btnConfig}
        img={successImg}
        Margin={"0 auto"}
      />
    </>
  );
};

export default PasswordChangedSuccessful;
