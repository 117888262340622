import React from "react";
import classes from "./Divider.module.css";

const Divider = (props) => {
  return (
    <div
      className={classes["divider-line"]}
      style={{ marginTop: props.topMargin }}
    >
      <div></div>
      <div><span className="text-[#868b90]">OR</span></div>
      <div></div>
    </div>
  );
};

export default Divider;
