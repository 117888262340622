import StorageService from "./../services/StorageService";

export const get = async (url) => {
    const token = StorageService.getJwt();

    const headers = {
        'Content-Type': 'application/json'
    };

    if (token && token.length > 0) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    try {
        const response = await fetch(`${StorageService?.baseURL}${url}`, {
            method: 'GET',
            headers: headers
        });

        if (!response.ok) {
            // Handle CORS error
            if (response.status === 0) {
                throw new Error('Failed to fetch. CORS error.');
            }

            // Handle unauthorized error
            if (response.status === 401) {
                throw new Error('Unauthorized. Please login again.');
            }

            // Handle other HTTP errors
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}


export const post = async (url, data) => {
    const token = StorageService.getJwt();

    const headers = {
        'Content-Type': 'application/json'
    };

    if (token && token.length > 0) {
            headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(`${StorageService?.baseURL}${url}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
    });

    return response;
};