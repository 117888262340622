import React from 'react';
import { Link } from "react-router-dom";
import "../../style/home.css";

const Footer = () => {
  return (
    <div className="flex items-center justify-center mb-16">
        <Link
        // className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
        className="font-[300] text-[18px] no-underline pt-5 px-1 text-[#b2b2b2]"
        // to="/Agreement"
        to=""
        reloadDocument={true}
        >
        User Agreement
        </Link>
        <span className="text-[#dddddd] mt-[21px] mx-2">|</span>
        <Link
        // className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
        className="font-[300] text-[18px] no-underline pt-5 px-1 text-[#b2b2b2]"
        // to="/PrivacyPolicy"
        to=""
        reloadDocument={true}
        >
        Privacy Policy
        </Link>
        <span className="text-[#dddddd] mt-[21px] mx-2">|</span>
        <Link
        // className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
        className="font-[300] text-[18px] no-underline pt-5 px-1 text-[#b2b2b2]"
        // to="/AcceptableUsePolicy"
        to=""
        reloadDocument={true}
        >
        Acceptable Use Policy
        </Link>
        <span className="text-[#dddddd] mt-[21px] mx-2">|</span>
        <Link
        // className="font-[300] text-[18px] underline decoration-1 pt-5 px-1 text-[#b2b2b2]"
        className="font-[300] text-[18px] no-underline pt-5 px-1 text-[#b2b2b2]"
        // to="/Disclaimer"
        to=""
        reloadDocument={true}
        >
        Disclaimer
        </Link>
    </div>  
  )
}

export default Footer