import React from "react";
import { useNavigate } from "react-router-dom";

const Groups = () => {
  const navigate = useNavigate();

  return (
    <React.StrictMode>
      {/* // {top 5 button section} */}
      <div className="flex items-center justify-around sm:justify-start py-8 sm:gap-4 BtnGroupStyle">
        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate("/userProfileView")}
        >
          <span className="text-white font-primaryFont font-normal sm:block">
            Posts
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10"
          onClick={() => navigate("/userProfileView/saved")}
        >
          <span className="text-white font-primaryFont font-normal sm:block">
            Saved
          </span>
        </button>

        <button
          className="bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[90px] sm:w-[145px] h-[30px] sm:h-10 buttonAddBorder"
          onClick={() => navigate("/userProfileView/groups")}
        >
          <span className="text-primary font-primaryFont font-normal sm:block">
            Groups
          </span>
        </button>
      </div>

      <div>
        <p className="empty flex items-center justify-center">Coming Soon...</p>
      </div>
    </React.StrictMode>
  );
};

export default Groups;
