import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Style
import classes from "./GetTheApp.module.css";
import "../../../style/GetTheApp.css";

// image
import { googlePlay, appStore } from "../../../constant/imagePath";

// Component
import { Help } from "../../Models/Index";

const GetTheApp = (props) => {
  const { isCreateAccount } = props;
  let [message, setMessage] = useState(false);

  const navigate = useNavigate();

  return (
    <React.StrictMode>
      {/* alert model */}
      <Help helpModal={message} setHelpModal={setMessage} justShow={"Mobile App will be launched soon."} />

      <div className={classes["get-the-app"]}>

        {isCreateAccount && (
          <p className={`text-center createANewAccount`} onClick={() => navigate("/signUp")}>
            <span className="text-[#fff]">
              Create a new account
            </span>
          </p>
        )}

        <div className={classes.divider}></div>
        <p className={`${classes["app-desc"]} text-center`}>Get The App</p>

        <div className={classes["form-btns"]}>
          <img src={googlePlay} alt="google Play store"
            style={{ cursor: "pointer" }}
            onClick={() => setMessage(!message)}
          />
          <img src={appStore} alt="app store"
            style={{ cursor: "pointer" }}
            onClick={() => setMessage(!message)}
          />
        </div>
      </div>
    </React.StrictMode>
  );
};

export default GetTheApp;
