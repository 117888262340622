import React from "react";

// Style
import classes from "./CardDiv.module.css";

const CardDiv = (props) => {
  return (
    <div
      className={`${classes.cardDiv} ${props.className} containerContent`}
      style={{
        maxWidth: props.boxWidth ?? "",
        margin: props.Margin ?? "0rem auto",
      }}
    >
      {props.children}
    </div>
  );
};

export default CardDiv;
