import React from 'react';
import { Outlet } from 'react-router-dom';

// component
import { Header } from '../../components';

const MediaPage = () => {
    return (
        <section className='mediaPage'>
            {/* Header */}
            <Header loginAndSignup={false} messageShow={true} />

            <Outlet />
        </section>
    )
}

export default MediaPage;