import React from "react";

// Images
import { successImg } from "../constant/imagePath";

// Components
import AccountActivation from "../components/AccountActivation/AccountActivation";
import { Header } from "../components";

const ActivationSuccessfulPage = () => {
  let heading = "Activation Successful";
  let content = (
    <p style={{ padding: "1.5rem 0" }}>
      Congratulations! Your account has been activated successfully. You can now
      login with the email and password you provided when you signed up.
    </p>
  );
  let btnConfig = [{ id: 1, name: "Back to Login", to: "/login" }];

  return (
    <React.StrictMode>
      {/* Header */}
      <Header loginAndSignup={true} messageShow={false} />

      <AccountActivation
        heading={heading}
        content={content}
        btnConfig={btnConfig}
        img={successImg}
        boxWidth={"580px"}
        Margin="0rem auto"
      />
    </React.StrictMode>
  );
};

export default ActivationSuccessfulPage;
