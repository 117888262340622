import React from "react";
import classes from "./FormContent.module.css";

const FormContent = (props) => {
  const { heading = "---", content = "---", paddingTop = "0px" } = props;
  return (
    <div className={classes.content}>
      <h2>{heading}</h2>
      <p style={{ paddingTop: paddingTop }}>{content}</p>
    </div>
  );
};

export default FormContent;
