import React, { useState, useRef, useEffect } from "react";
import "../../style/PostCreate.css";
import { ImageIcon, AddAPhoto } from "../../constant/IconsPath";
import emojiIcon from "../../assets/img/Emoji.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import EmojiPicker from "emoji-picker-react";
import { logo4,profile } from "../../constant/imagePath";
import { get, post } from "../../api/api";
import axios from "axios";
import StorageService from "../../services/StorageService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import { updateTrendingPostData, updateTrendingPostDataOnSearch } from "../../Store/TrendingPageSlice";

const PostCreate = ({ open, setTopic, flag, setFlag, MainClassName }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [showEmoji, setShowEmoji] = useState(false);
  const [disablePost, setDisablePost] = useState(false);
  const [image, setImages] = useState([]);
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const [title, setTitle] = useState("");
  const [err, setErr] = useState(false);
  const [group, setGrop] = useState("");
  const [base64, setBase64] = useState("");
  const [groupData, setGroupData] = useState([]);
  const someValue = StorageService.getValue("userName");
  const someProfile = StorageService.getValueProfilePic("profilePic");
  let navigate = useNavigate();
  const inputFieldMessage = useRef();
  const modalRef = useRef();
  const emoji_close = useRef();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const getGroup = async () => {
    // dispatch(startLoading());
    try {
      let res = await get("/Group/ReadGroups");
      if (res) {
        setGroupData(res);
        // dispatch(stopLoading());
      } else {
        // dispatch(stopLoading());
      }
    } catch (error) {
      console.error("Error fetching trending posts:", error);
      // dispatch(stopLoading());
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getGroup();
    }, 2000);

    return () => clearTimeout(timer);
    
  }, [get]);

  useEffect(() => {
    if (!open) {
      resetAllValue();
    }
  }, [open]);

  const getNewPosts = async () => {
    dispatch(startLoading());
    try {
      let res = await post("/Post/ReadLatestPost?pageNumber=0&pageSize=3");
      let data = await res.json();
      if (res && data) {
        dispatch(updateTrendingPostDataOnSearch(data));
      }
      dispatch(stopLoading());
    } catch (error) {
      console.error("Error fetching top trending posts:", error);
      dispatch(stopLoading());
    }
  };

  const postNow = async () => {
    dispatch(startLoading());
    let data = {
      Title: title,
      Content: inputValue,
      GroupId: group,
      Picture: base64,
    };
    const token = StorageService.getJwt();
    if (!title || title == "") {
      dispatch(stopLoading());
      setErr(true)
      return;
    }
    setDisablePost(true)
    let header = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.post(`${StorageService?.baseURL}/Post/CreatePost`, data, header);
    if (res.status == 200) {
      toast.success(res?.data?.message);
      axios.post(`${StorageService?.baseURL}/Sitemap`, {}, {})
      resetAllValue();
      setDisablePost(false)
      navigate('/new-post')
      if (location.pathname.includes("new-post")) {
        getNewPosts();
      }
      dispatch(stopLoading());
    } else {
      toast.error(res?.data?.message);
      setDisablePost(false)
      dispatch(stopLoading());
    }
  };

  const [MainClassNameStyle, setMainClassNameStyle] = useState({
    transform: "scale(0)",
    height: "0px",
    margin: "2.5rem 0 0 0",
  });

  function showImageUpload() {
    setFlag(true);

    const image_upload1 = document.querySelector(`.${MainClassName}`);
    const main_part = document.querySelector(".main_part");
    const closeIconStyle = document.querySelector(`.${MainClassName} svg`);

    if (image_upload1) {
      const image_upload_style = window.getComputedStyle(image_upload1);

      if (image_upload_style.height === "0px") {
        main_part.style.margin = "2.5rem 0 1rem 0";
        image_upload1.style.height = "184px";
        setMainClassNameStyle({
          transform: "scale(1) translateY(0)",
          height: "184px",
          margin: "2.5rem 0 1rem 0",
          display: "block",
          transition: "transform 1.5s",
        });
        closeIconStyle.style.display = "block";
      } else {
        main_part.style.margin = "2.5rem 0 0 0";
        image_upload1.style.height = "0px";
        setMainClassNameStyle({
          transform: "scale(0) translateY(-100%)",
          height: "0px",
          margin: "2.5rem 0 0 0",
          display: "none",
          transition: "transform 1.5s",
        });
        closeIconStyle.style.display = "none";
      }
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image.some((e) => e.name === file[i].name)) {
          setImages((prevImages) => [
            ...prevImages,
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }
        setFlag(false);
      }
    }
  };

  function onFileSelect(event) {
    const file = event.target.files;
    setBase64(file[0]);
    if (file.length === 0) return;
    for (let i = 0; i < file.length; i++) {
      if (file[i].type.split("/")[0] !== "image") {
        alert("Online image support");
      } else {
        if (!image.some((e) => e.name === file[i].name)) {
          setImages((prevImages) => [
            ...prevImages,
            {
              name: file[i].name,
              url: URL.createObjectURL(file[i]),
            },
          ]);
        }
        setFlag(false);
      }
    }
  }

  function resetFunction() {
    // setTitle("");
    // setGrop(""); // Set group to null or an empty value
    // setInputValue("");
    showImageUpload()
    setFlag(true)
    setImages([]);
    setBase64(null);

  }

  function resetAllValue() {
    setTopic(false);
    resetFunction();
    setInputValue("");
    setTitle("");
    setGrop(""); 
    setInputValue("");

    const image_upload = document.querySelector(`.${MainClassName}`);
    if (image_upload) {
      image_upload.style.height = "0px";
      image_upload.style.transform = "scale(0)";
    }

    const main_part = document.querySelector(".main_part");
    if (main_part) {
      main_part.style.margin = "2.5rem 0 0 0";
    }

    const closeIconStyle = document.querySelector(".closeIconStyle");
    if (closeIconStyle) {
      closeIconStyle.style.display = "none";
    }

    const imageUpload = document.querySelector(".imageUpload");
    if (imageUpload) {
      imageUpload.style.height = "0px";
      imageUpload.style.transform = "scale(0)";
    }

    const title_field = document.querySelector(".title_field");
    title_field.value = "";
  }

  function onFunction(e) {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      resetAllValue();
    }
  }

  function emojiCloseFn(e) {
    if (emoji_close.current && !emoji_close.current.contains(e.target)) {
      setShowEmoji(false);
    }
  }

  return (
    <div
      className={"postCreate"}
      style={{
        opacity: open ? 1 : 0,
        cursor: open ?? "none",
        zIndex: open ? 99 : -1,
        overflow: "hidden", // Prevent overflow outside of the modal
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
      onClick={onFunction}
    >
      <div className="center_div" ref={modalRef} onClick={emojiCloseFn} 
        style={{
          backgroundColor: "#1f1f1f",
          width: "90%",
          maxWidth: "600px",
          maxHeight: "90vh",
          overflow: "hidden",
          padding: "20px",
          borderRadius: "10px",
          position: "relative",
        }}>
        {/* top part */}
        <div className="post_create_top_part">
          <p className="text-[#fff]">
            <span style={{ color: "#fff" }}>
              {MainClassName && MainClassName === "image_upload" ? "Create Post" : "Ask Question"}
            </span>
          </p>
          {/* close icon */}
          <div className="close_icon" onClick={resetAllValue}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
        {/* main part */}
        <div className="main_part">
          {/* logo and name */}
          <div className="logo_and_name">
            <div className="logo">
              {/* <img src={`data:image/jpeg;base64,${someProfile}`} alt="Mochat logo" /> */}
              <img
                className="w-8 h-8  rounded-full object-cover"
                src={someProfile != "null" ? `data:image/jpeg;base64,${someProfile}` : logo4}
                alt="Profile"
              />
            </div>
            <h6 className="text-[#fff]">{someValue}</h6>
          </div>
          {/* post title */}
          <div className="post_title">
            <input
              type="text"
              placeholder="Post title.."
              className="title_field textFild01"
              onChange={(e) => setTitle(e.target.value)}
            />
            {err && !title && <span style={{ color: "red" }}>Title is required field!</span>}
          </div>
          {/* <div className="post_title">
            <select
              type="select"
              placeholder="Select Group.."
              value={group}
              className="title_field textFild01"
              onChange={(e) => setGrop(e.target.value)}
            >
              <option value="" selected>
                Select Group..
              </option>
              {groupData && groupData?.map((ele) => <option value={ele?.id}>{ele?.name}</option>)}
            </select>
          </div> */}
          {/* text area */}
          <div className="text_area">
            <textarea
              ref={inputFieldMessage}
              placeholder={
                MainClassName && MainClassName === "image_upload"
                  ? "Write your caption here.."
                  : "Write your question here.."
              }
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="textArea01"
              // style={{ height: "100px", maxHeight: "150px", overflowY: "auto" }}
            />

            <div className="bottom_part">
              {/* image icon */}
              <img
                src={ImageIcon}
                alt="icon"
                onClick={showImageUpload}
                width={"auto"}
                height={"auto"}
              />

              <div ref={emoji_close} style={{ position: "relative" }}>
                {/* emoji icon */}
                <img src={emojiIcon} alt="Emoji Icon" onClick={() => setShowEmoji((val) => !val)} />

                {/* emoji */}
                {showEmoji && (
                  <EmojiPicker
                    onEmojiClick={(event) => {
                      setInputValue((prevValue) => `${prevValue} ${event.emoji}`);
                    }}
                    autoFocusSearch={false}
                    searchDisabled
                    width="100%"
                    lazyLoadEmojis={true}
                  />
                )}
              </div>
            </div>
          </div>

          {/* photo upload area */}
          {/* {err && base64=='' && (
              <span style={{ color: "red" }}>Post Image is Requried field! </span>
            )} */}
          {flag ? (
            <div
              className={MainClassName}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{ 
                ...MainClassNameStyle,
                overflowY: "auto",
               }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                onClick={showImageUpload}
                className="closeIconStyle"
              />

              {/* center */}
              <div className="center">
                <input name="file" type="file" onChange={onFileSelect} hidden ref={inputRef} />

                <img src={AddAPhoto} alt="add" onClick={() => inputRef.current.click()} />

                <p onClick={() => inputRef.current.click()}>Add a Photo</p>
                <p>or drag and Drop</p>
              </div>
            </div>
          ) : (
            <div className="imageUpload">
              {/* close icon */}
              <div className="close_icon">
                <FontAwesomeIcon icon={faXmark} onClick={resetFunction} />
              </div>
              {image &&
                image.length > 0 &&
                image.map((images, index) => {
                  return <img src={images.url} alt={images.name} key={index} />;
                })}
            </div>
          )}
        </div>

        {/* bottom part button */}
        <div className="post_button" onClick={disablePost ? null : postNow}>
          <p>
            <span className="text-[#000]"> Post Now</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PostCreate;
