import React, { useEffect, useRef, useState } from "react";

// react router
import { useLocation } from "react-router-dom";

// dummy data
import users from "../../data/AllUsers";

// components
import Message from "../../components/Models/Message";
import SearchResultUser from "./SearchResultUser";
import { Header } from "../../components";

// react icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

// style
import "../../style/searchResult.css";

const SearchResult = () => {
  const [text, setText] = useState(["Members", "Posts", "Groups"]);
  const [activeText, setActiveText] = useState("All");
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [sortUser, setSortUser] = useState([]);

  const { state } = useLocation();
  const optionPopUp = useRef();

  useEffect(() => {
    let sortAllUsers = users.filter((val) => {
      return val.name.toLowerCase().includes(state?.toLowerCase());
    });

    setSortUser(sortAllUsers);
  }, [state]);

  useEffect(() => {
    const handler = (e) => {
      if (optionPopUp.current && !optionPopUp.current.contains(e.target)) {
        setIsShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  }, []);

  const currentTextInsert = (e) => {
    setText((val) => {
      let sortingVal = val.filter((value) => value !== e);
      return [activeText, ...sortingVal];
    });
    setActiveText(e);
    setIsShowDropDown(false);
  };

  return (
    <React.StrictMode>
      {/* header */}
      <Header loginAndSignup={false} messageShow={true} />

      <div className="search_result_content container mx-auto">
        {/* top label */}
        <div className="label">
          <p>Search Results for “<span className="text-[#fff]">{state ?? "George Smith"}</span>”</p>

          {/* drop down */}
          <div className="Filter_dropDown" ref={optionPopUp}>
            <div
              className="labelText"
              onClick={() => setIsShowDropDown(!isShowDropDown)}
            >
              <h4>{activeText}</h4>
              <FontAwesomeIcon icon={faAngleDown} />
            </div>

            {/* option */}
            <div
              className="dropDown_option"
              style={{ transform: isShowDropDown ? "scale(1)" : "scale(0)" }}
            >
              {text.map((val, index) => {
                return (
                  <p key={index} onClick={() => currentTextInsert(val)}>
                    {val}
                  </p>
                );
              })}
            </div>
          </div>
        </div>

        {
          sortUser.length === 0 && <p style={{
            fontSize: "1rem",
            padding: "0px 3rem",
            textAlign: "center",
            color: "#444",
            lineHeight: "normal",
            fontWeight: "500"
          }}>Sorry, we couldn't find any result for “{state ?? "George Smith"}”.</p>
        }

        {sortUser?.length > 0 &&
          sortUser.map((val, index) => {
            if (activeText === "All" || val?.type === activeText) {
              return (
                <React.StrictMode key={index}>
                  <SearchResultUser val={val} />
                </React.StrictMode>
              );
            }

            return null;
          })}
      </div>

      <Message />
    </React.StrictMode >
  );
};

export default SearchResult;
