import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import StorageService from '../../services/StorageService';
import { Header } from '../../components';
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import { toast } from 'react-toastify';
import { post } from '../../api/api';
import { DownArrow1Icon } from '../../constant/IconsPath';
import {
    // DownArrow1Icon,
    TopArrowIcon,
    Notification2Icon,
    ChatIcon,
    ShareIcon,
    DeletePostIcon,
    DarkEmojiIcon,
} from "../../constant/IconsPath";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch } from 'react-redux';

const ViewPost = () => {
    const homeView = false;
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const navigate = useNavigate()
    const [postData, setPostData] = useState(null);
    const token = StorageService.getJwt();
    const [marked, setMarked] = useState(false);
    const [pushAndDown, setPushAndDown] = useState(false);
    const [messageForLogin, setMessageForLogin] = useState(false);
    const [Share, setShare] = useState(false);
    const [flag, setFlag] = useState(false);
    const PostOption = useRef("");
    const [showCommentSection, setShowCommentSection] = useState(false);
    useEffect(() => {
        const handler = (e) => {
            if (PostOption.current && !PostOption.current.contains(e.target)) {
                setFlag(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        };
    }, []);
    useEffect(() => {
        if (id) {
            getDataById(id);
        }
    }, [id]);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        return formattedDate;
    };

    const DeletePost = async (idd) => {
        const token = StorageService.getJwt();
        dispatch(startLoading());
        try {
            const header = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            let res = await axios.post(`${StorageService?.baseURL}/Post/DeletePost/${idd}`, {}, header);
            if (res.status === 200) {
                toast.success(res.data);
                // updateState();
                dispatch(stopLoading());
                setFlag(false);
            } else {
                toast.error(res);
                dispatch(stopLoading());
                setFlag(false);
            }
        } catch (error) {
            dispatch(stopLoading());
            setFlag(false);
            console.error("Error deleting post:", error);
        }
    };


    const pageNavigateFunction = () => {
        navigate(`/movietalk/${id}`, {
            state: { id },
        });
    };



    const yourDate = postData?.createdAt;
    const formattedDate = formatDate(yourDate);
    const UpVote = async (PostId) => {
        if (homeView) {
            setMessageForLogin(!messageForLogin);
        } else {
            setPushAndDown(true);
        }
        const token = StorageService.getJwt();
        dispatch(startLoading());
        try {
            const header = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };
            let res = await post(`/Post/UpVote/${PostId}`, {}, header);
            toast.success("Vote added successfully!");
            dispatch(stopLoading());
        } catch (error) {
            dispatch(stopLoading());
            console.error("Error upvoting post:", error);
        }
    }

    const DownVote = async (PostId) => {
        if (homeView) {
            setMessageForLogin(!messageForLogin);
        } else {
            setPushAndDown(false);
        }
        const token = StorageService.getJwt();
        dispatch(startLoading());
        try {
            const header = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };
            let res = await post(`/Post/DownVote/${PostId}`, {}, header);
            toast.success("Unvote successfully!");
            dispatch(stopLoading());
        } catch (error) {
            dispatch(stopLoading());
            console.error("Error downvoting post:", error);
        }
    }

    const getDataById = async (postId) => {
        try {
            const res = await axios.post(
                `${StorageService?.baseURL}/Post/ReadPost/${postId}?userId=${StorageService?.getValue("userId")?StorageService?.getValue("userId"):null}`, {}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
            );
            setPostData(res.data);
        } catch (error) {
            console.error('Error fetching post data:', error);
        }
    };

    return (
        <div style={{ color: "red", fontSize: "30px" }}>
            {postData ? (
                <div>
                    <Header />
                    <h2>{postData?.title}</h2>
                    <p>{postData?.content}</p>
                    <div
                        className={`shadow-[0_4px_32px_rgba(0,0,0,0.08)] mb-5 p-[30px] rounded-lg responsive_post_design ${homeView && "homeView"
                            }`}
                        style={{
                            margin: "2.5rem 0",
                            backgroundColor: "#131313",
                        }}
                    >
                        <div style={{ maxWidth: "400px", margin: "0 auto" }}>
                            <div className="flex items-center justify-between top_responsive_post_design">
                                <div className="flex items-center justify-center">
                                    <div
                                        className="flex w-[54px] h-[54px] p-1 items-center justify-center border-solid border-[0.9px] border-black rounded-full bg-white"
                                        style={{
                                            overflow: "hidden",
                                            padding: "0.25rem",
                                        }}
                                    >
                                        <img src={`data:image/png;base64,${postData?.picture}`} alt="Description of the image" />

                                    </div>

                                    <div className="ml-4">
                                        <h3 className="sm:text-xl font-bold mb-1 text-white">{postData?.userName}</h3>
                                        <p className="text-[#999999] font-normal text-sm -mb-1">
                                            {postData?.readUserModel?.userName ? postData?.readUserModel?.userName : postData?.title}
                                        </p>
                                        <small className="font-normal text-xs" style={{ color: "rgba(255, 255, 255, 0.25)" }}>
                                            {formattedDate ? formattedDate : "null"}
                                        </small>
                                    </div>
                                </div>


                                {homeView ?? (
                                    <div className="flex items-center justify-center -mr-[12px] sm:-mr-0">
                                        <div
                                            className="sm:ml-3 sm:w-[36px] sm:h-[36px] sm:border-solid sm:border-[0.9px] sm:border-[#000] sm:rounded-full flex items-center justify-center sm:bg-[#000] optionBtn"
                                            style={{ position: "relative", cursor: "pointer" }}
                                            ref={PostOption}
                                        >
                                            <BsThreeDotsVertical
                                                className="text-[#fff] p-1 text-3xl"
                                                onClick={() => setFlag(!flag)}
                                            />
                                            {flag && (
                                                <div className="option">
                                                    <div className="option1">
                                                        <DeletePostIcon />
                                                        <p onClick={() => DeletePost(id)}>Delete this post</p>
                                                    </div>
                                                    <div className="option1">
                                                        <Notification2Icon />
                                                        <p>Turn off notifications</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="py-4 description">
                                <h3
                                    className="title text-white"
                                    onClick={homeView ?? pageNavigateFunction}
                                    style={{ cursor: "pointer" }}
                                >
                                    {postData?.title ? postData?.title : ""}
                                </h3>
                                <p
                                    className="text-lg font-light text-[#636363] description"
                                    onClick={homeView ?? pageNavigateFunction}
                                    style={{ cursor: "pointer" }}
                                >
                                    {postData?.content ? postData?.content : ""}
                                </p>
                            </div>


                            <img
                                className="w-full rounded-lg postImage postGraph"
                                style={{ cursor: "pointer" }}
                                src={`data:image/png;base64,${postData?.picture}`}
                                alt="logo"
                                height={"auto"}
                                width={"100%"}
                            />
                            <div className="flex items-center justify-between py-4 follow_bottom_design">
                                <div className="flex items-center justify-center gap-4 sm:gap-6">
                                    <div
                                        className="flex items-center justify-center cursor-pointer"
                                        onClick={() => UpVote(id)}
                                    >
                                        <TopArrowIcon
                                            topArrow_color={
                                                pushAndDown === null ? "#999999" : pushAndDown ? "#ffa000" : "#999999"
                                            }
                                        />
                                        <p className="ml-1" style={{ marginLeft: "0.1rem" }}>
                                            <span
                                                style={{
                                                    color: pushAndDown === null ? "#999999" : pushAndDown ? "#ffa000" : "#999999",
                                                }}
                                            >
                                                {postData?.readVote?.upvoteCount ? postData?.readVote?.upvoteCount : 0}
                                            </span>
                                        </p>
                                    </div>

                                    <div
                                        className="flex items-center justify-center cursor-pointer"
                                        onClick={() => DownVote(id)}
                                    >
                                        <DownArrow1Icon
                                            downArrow_color={
                                                pushAndDown === null ? "#999999" : pushAndDown ? "#999999" : "#ffa000"
                                            }
                                        />
                                        <p className="ml-1">
                                            <span
                                                style={{
                                                    color: pushAndDown === null ? "#999999" : pushAndDown ? "#999999" : "#ffa000",
                                                }}
                                            >
                                                {postData?.readVote?.downvoteCount ? postData?.readVote?.downvoteCount : 0}

                                            </span>
                                        </p>
                                    </div>

                                    <img
                                        src={ChatIcon}
                                        alt="chat icon"
                                        className="text-[#999999] sm:text-xl cursor-pointer icon3"
                                        onClick={() => setShowCommentSection(!showCommentSection)}
                                        height={"17px"}
                                        width={"20px"}
                                    />

                                </div>

                                <img
                                    src={ShareIcon}
                                    alt="share icon"
                                    className="text-[#999999] sm:text-xl cursor-pointer icon4"
                                    onClick={() => setShare(!Share)}
                                    height={"17px"}
                                    width={"20px"}
                                />

                                <div
                                    className="text-[#999999] sm:text-xl cursor-pointer icon5"
                                    onClick={() => (homeView ? setMessageForLogin(!messageForLogin) : setMarked(!marked))}
                                >
                                    <svg
                                        width="17"
                                        height="20"
                                        viewBox="0 0 17 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="m15.705 18.864-7-4.995-7 4.994V2.88a1.997 1.997 0 0 1 2-2h10a2 2 0 0 1 2 1.999v15.984z"
                                            stroke={marked ? "#ffa000" : "#939393"}
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default ViewPost;
