import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Header } from "../components";
import AccountActivation from "../components/AccountActivation/AccountActivation";
import { mailSent } from "../constant/imagePath";
import { processActivation } from "../services/IdentityService";

const ProcessActivation = () => {
    const { token } = useParams(); // Extract the token from the URL
    const navigate = useNavigate();
    const [hit, setHit] = useState(1)
    useEffect(() => {
        let timeoutId;
    
        // Set a timeout to execute processActivation after 30 seconds
        timeoutId = setTimeout(() => {
            if (token && hit === 1) {
                setHit(2);
                processActivation(token)
                    .then(result => {
                        if (result.success) {
                            navigate("/activation-successful");
                        } else {
                            if (result.message === "") navigate("/page-not-found");
                        }
                    })
                    .catch(() => {
                        navigate("/link-expired");
                    });
            }
        }, 3000); // 30 seconds
    
        // Clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []); // No dependencies, execute once after mount
    
    let heading = "Processing Activation";
    let content = (
        <p style={{ padding: "1.5rem 0" }}>
            We are processing your activation request.
        </p>
    );

    


    return (
        <React.StrictMode>
            {/* Header */}
            <Header loginAndSignup={true} messageShow={false} />

            <AccountActivation
                heading={heading}
                content={content}
                btnConfig={[]}
                img={mailSent}
                particularImg="mailImg"
                boxWidth={"695px"}
                Margin="0rem auto"
            />
        </React.StrictMode>
    );
};

export default ProcessActivation;
