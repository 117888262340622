import React, { useEffect, useRef, useState } from "react";

// react router
import { useNavigate } from "react-router-dom";

// Styling
import "../../style/BeforeLoginMessage.css";

// font awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/img/logo2.png";
import StorageService from "../../services/StorageService";
const BeforeLoginOnScroll = () => {
  const modalRef = useRef();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  function resetAllValue() {
    setOpen(false);

    const closeIconStyle = document.querySelector(".closeIconStyle");
    if (closeIconStyle) {
      closeIconStyle.style.display = "none";
    }
  }

  function onFunction(e) {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      resetAllValue();
    }
  }
  useEffect(() => {
    const token = StorageService?.getJwt();
    if (!token) {
        const timeoutId = setTimeout(() => {
            setOpen(true);
        }, 30000);

        return () => clearTimeout(timeoutId);
    }
}, []);


  return (
    <div
      className={"postCreate helpModal"}
      style={{
        opacity: open ? 1 : 0,
        cursor: open ?? "none",
        zIndex: open ? 1111111111111 : -1,
        visibility: open ? "visible" : "hidden",
      }}
      onClick={onFunction}
    >
      <div className="center_div" ref={modalRef}>
        {/* top part */}
        <div className="post_create_top_part">
          <p></p>
          {/* close icon */}
          <div className="close_icon" onClick={resetAllValue}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>

        {/* main part */}
        <div className="main_part">
          {/* text area */}
          <div className="text_area">
            <div className="flex justify-center items-center">
              <img src={logo} alt="logo" width="150px" height="auto" />
            </div>

            {/* button */}
            <div className="btn_group" style={{ marginTop: "2rem" }}>
              <button onClick={() => navigate("/signUp")} className="bg-primary w-full h-[50px] mb-4 text-black rounded-md flex items-center justify-center">
                Join The Discussion
              </button>

              <button
                onClick={() => navigate("/login")}
                className="bg-[transparent] text-[#ffa000] w-full h-[50px] rounded-md flex items-center justify-center log_pop"
                style={{ border: "1px solid rgb(255, 160, 0)" }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeLoginOnScroll;
