import React, { useRef } from "react";

// icon
import { ImCross } from "react-icons/im";

const FileUploaded = ({ toggle, open }) => {
  const modalRef = useRef("");

  function closePopUp(e) {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      toggle();
    }
  }
  return (
    <React.StrictMode>
      <div
        className={
          open
            ? "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center"
            : "h-[100vh] bg-[#ffffff54] fixed overflow-hidden top-0 right-0 left-0 z-10  w-full flex items-center justify-center"
        }
        style={{
          transition: "0.3s",
          opacity: open ? 1 : 0,
          cursor: open ?? "none",
          zIndex: open ? 11111111111 : -1,
          visibility: open ? "visible" : "hidden"
        }}
        onClick={closePopUp}
      >
        <div
          role="alert"
          className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
          ref={modalRef}
        >
          <div className="relative py-8 px-5 md:px-10 bg-[#131313] shadow-md rounded-xl">
            <div className="flex items-center justify-between mb-2">
              <button onClick={toggle} className="bg-[#ffffff80] rounded-full">
                <ImCross className="text-black h-[22px] w-[22px] p-[6px] font-thin" />
              </button>
            </div>
            {/* input field  */}
            <div className="h-[100%]">
              <p className="text-center">This File can't be uploaded</p>
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default FileUploaded;
