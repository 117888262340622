import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import MochatPostSection from "../../components/post/MochatPostSection";
import {
  ForYouIcon,
  NewTopicIcon,
  TrendingTopicIcon,
} from "../../constant/IconsPath";
import { BsStar } from "react-icons/bs";
import { post } from "../../api/api";
import { updateTrendingPostData } from "../../Store/TrendingPageSlice";
import { SyncLoader } from "react-spinners";
import FilterTopic from "../../components/filterTopic/FilterTopic";
const ForYouPage = ({ TrendingPost, topPost, newPost, forYou }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postData,search } = useSelector((state) => state.Trending);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(true);
    useEffect(() => {
      if (typeof window !== "undefined") {
        window.document.title = "FilmWaffle - For You";
  
        const metaSiteName = document.createElement("meta");
        metaSiteName.setAttribute("property", "og:site_name");
        metaSiteName.setAttribute("content", "FilmWaffle");
        document.head.appendChild(metaSiteName);
  
        const metaOgImage = document.createElement("meta");
        metaOgImage.setAttribute("id", "og-image");
        metaOgImage.setAttribute("property", "og:image");
        metaOgImage.setAttribute("content", "%PUBLIC_URL%/favicon.png");
        document.head.appendChild(metaOgImage);
      }
      dispatch(updateTrendingPostData({ data:[], route: "For" }));
      const delay = setTimeout(() => {
        getNewPosts(page,search)
        setNoData(false)
      }, 2000);
      return () => clearTimeout(delay);
    }, [dispatch]);

    const sectionRef = useRef();
    const datas = useRef(postData);
    const pageNo = useRef(page);
    const newSearch = useRef(search);
    newSearch.current = search;
    datas.current = postData;
    pageNo.current = page;
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          const totalCount = [...new Set(datas?.current?.map((item)=> item?.totalRecordCount))];
          if (entry.isIntersecting && !loading && pageNo.current < Math?.ceil(totalCount[0] / 3)) {
            getNewPosts(pageNo.current + 1,newSearch.current);
            setPage((prevPage) => prevPage + 1);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );
  
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
  
      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }, []);

    const getNewPosts = async (pageNumber,search) => {
      setLoading(true)
      try {
        let res = await post(`/Post/GetUserGroupData?pageNumber=${pageNumber-1}&pageSize=3&search=${search?.searchValue}`);
        let data = await res.json();
        if (res && data) {
          dispatch(updateTrendingPostData({ data, route: "For" }));
          const timer = setTimeout(() => {
            setLoading(false);
          }, 500);
          return () => clearTimeout(timer);
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching top trending posts:", error);
        setLoading(false)
      }
    };
  const renderPosts = () => {
    if (loading||noData) {
      return null; 
    } else if (postData?.length === 0) {
        if (!loading) {
          return <p className="empty flex items-center justify-center">You need to join a group in order to see for you posts...</p>;
        }
    }
  };

  return (
    <React.StrictMode>
       <FilterTopic tabValue={"forYou"}/>
      {postData?.length > 0 &&
        postData.map((value, index) => {
          return (
            <React.StrictMode key={index}>
              <MochatPostSection data={value} />
            </React.StrictMode>
          );
        })}
        <div ref={sectionRef} className="flex justify-center items-center">.{loading&&<SyncLoader color="#fea500" loading={true} height={15} width={5} />}</div>
        {renderPosts()}
    </React.StrictMode>
  );
};

export default ForYouPage;
