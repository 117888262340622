import React from "react";

// React Router
import { NavLink } from "react-router-dom";

const DropDown = ({ open, toggleNav }) => {
  const dropItems = [
    {
      name: "Change Password",
      url: "security/changePassword",
    },
    {
      name: "Two Factor Authentication",
      url: "security/twoAuthentication",
    },
    // {
    //   name: "Login Sessions",
    //   url: "security/loginSessions",
    // },
  ];
  return (
    <nav
      className={
        open
          ? "absolute top-9 left-7 scale-y-100 transition-all duration-300 ease-in-out origin-top"
          : "absolute top-9 left-7 scale-y-0 transition-all duration-300 ease-in-out origin-top"
      }
    >
      {dropItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.url}
          className={({ isActive }) =>
            isActive
              ? "text-primary text-lg font-light flex items-center justify-start mb-[10px] relative after:h-5 after:w-[1.5px] after:bg-primary after:absolute after:-left-2"
              : "text-white text-lg font-light flex items-center justify-start mb-[10px]"
          }
          onClick={toggleNav}
        >
          {item.name}
        </NavLink>
      ))}
    </nav>
  );
};

export default DropDown;
