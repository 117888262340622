import React, { useState } from "react";

// ******Icons******

import {
  RemoveUserIcon,
  BlockMemberIcon,
  MakeAdminIcon,
} from "../../constant/IconsPath";

import { BiDotsHorizontalRounded } from "react-icons/bi";

// style
// =====

import "../../style/editDropMenu.css";


const Options = ({ value, id, setValue, setOpenMenu, openMenu }) => {
  let { simpleText, icon, text, labelText } = value || "";
  return (
    <div
      className="flex items-center justify-start hover:bg-[#131313] py-[6px] pl-6 cursor-pointer"
      onClick={() => {
        simpleText !== "follow" && setValue({ msg: simpleText ?? "remove", id, labelText });
        setOpenMenu(!openMenu)
      }}
    >
      <img
        src={icon ?? RemoveUserIcon}
        alt={text ?? "remove user"}
        className="text-[#636363] text-xl"
      />
      <span className="text-[#fff] font-light ml-2">
        {text ?? "Remove member"}
      </span>
    </div>
  )
}


const EditDropMenu = ({ isOpenMenu, setValue, id, option, isCrownShow }) => {
  const [openMenu, setOpenMenu] = useState(isOpenMenu);

  const makeAdmin = () => {
    setValue({ msg: "assign admin role to", id, labelText: "assign admin role to" });
    setOpenMenu(!openMenu);
  }

  const makeAdmin1 = () => {
    setValue({ msg: "assign admin role to", id, icon: true, labelText: "remove as admin" });
    setOpenMenu(!openMenu);
  }

  return (
    <React.StrictMode>
      <button onClick={() => setOpenMenu(!openMenu)}>
        <BiDotsHorizontalRounded
          className={
            openMenu
              ? "text-primary text-2xl -mt-6"
              : "text-[#fff] text-2xl -mt-6"
          }
        />
      </button>
      {openMenu ? (
        <div
          className="h-screen w-full fixed left-0 top-0 z-20"
          onClick={() => setOpenMenu(false)}
        ></div>
      ) : (
        ""
      )}
      <div
        className={
          openMenu
            ? "block absolute top-10 right-0 w-[269px] bg-black rounded-xl shadow-md z-20"
            : "hidden absolute top-10 right-0 w-[269px] bg-black rounded-xl shadow-md z-20"
        }
      >
        <div className="py-[17px]">
          {
            option && option.length > 0 ? option.map((value, index) => {
              if (value?.simpleText === "remove") {
                return (
                  <React.StrictMode key={index}>
                    <Options value={value} id={id} setValue={setValue} setOpenMenu={setOpenMenu} openMenu={openMenu} />
                  </React.StrictMode>
                )
              } else {
                return (
                  <React.StrictMode key={index}>
                    <Options value={value} setValue={setValue} setOpenMenu={setOpenMenu} openMenu={openMenu} />
                  </React.StrictMode>
                )
              }

            })
              :
              <React.StrictMode>

                <div
                  className="flex items-center justify-start hover:bg-[#131313] py-[6px] pl-6 cursor-pointer GroupMembersOption"
                  onClick={() => { setValue({ msg: "remove", id }); setOpenMenu(!openMenu) }}
                >
                  <img
                    src={RemoveUserIcon}
                    alt="remove user"
                    className="text-[#636363] text-xl"
                  />
                  <span className="text-[#fff] font-light ml-2">
                    Remove member
                  </span>
                </div>

                <div
                  className="flex items-center justify-start hover:bg-[#131313] py-[6px] pl-6 cursor-pointer text-white GroupMembersOption"
                  onClick={() => { setValue({ msg: "remove", id, labelText: "block" }); setOpenMenu(!openMenu) }}

                >
                  <img
                    src={BlockMemberIcon}
                    alt="Block Member"
                    className="text-[#636363] text-xl"
                  />
                  <span className="text-[#fff] font-light ml-2">Block member</span>
                </div>

                <div
                  className="flex items-center justify-start hover:bg-[#131313] py-[6px] pl-6 cursor-pointer GroupMembersOption"
                  onClick={isCrownShow ? makeAdmin1 : makeAdmin}
                >
                  <img
                    src={MakeAdminIcon}
                    alt="Make Admin"
                    className="text-[#636363] text-xl"
                  />
                  <span className="text-[#fff] font-light ml-2">{isCrownShow ? "Remove as Admin" : "Make Admin"}</span>
                </div>
              </React.StrictMode>

          }
        </div>
      </div>
    </React.StrictMode>
  );
};

export default EditDropMenu;
