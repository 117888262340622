import React from "react";

// component
import { ClickButton } from "../../../components";

const TwoFactorAuthentication = () => {
  return (
    <div className="border-2 border-solid border-slate-200 m-5 sm:m-0 TwoFactorAuthentication" style={{border: "1px solid rgba(138, 152, 164, 0.47)"}}>
      <div className="bg-[#c8ced436] px-[15px] sm:px-[30px] py-5 loginSessionHeading">
        <h3 className="text-[20px] font-bold text-white">Two factor Authentication</h3>
      </div>

      <div
        className="my-4 sm:my-9 mx-4 sm:mx-16 sm:w-4/5 twoFactorAuthenticationCenterStyle"
        style={{
          marginLeft: "2rem",
        }}
      >
        <div className="sm:flex items-center justify-between">
          <p className="text-lg font-light mr-5 sm:mr-0">
            <span className="text-[#ffffff]">

            We'll ask for a verification code via your email if we notice a
            login from <br className="hidden sm:inline" />
            an unrecognised device or browser.
            </span>
          </p>

          <div className="flex items-center justify-between flex-row sm:flex-col w-full sm:w-[72px] sm:text-right mt-[30px] sm:mt-0">
            <span className="text-xl font-bold block text-white">Turn On</span>
            <ClickButton  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
