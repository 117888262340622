import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import filter from '../../assets/img/filter.png';
import { BsStar } from 'react-icons/bs';
import { ForYouIcon, NewTopicIcon, TrendingTopicIcon } from '../../constant/IconsPath';
import StorageService from '../../services/StorageService';

const FilterTopic = ({ tabValue }) => {
  const navigate = useNavigate();
  const [toggleFilter, setToggleFilter] = useState(false);

  const handleClickInside = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const storedToggle = StorageService.getValue("toggleHeader");
    if (storedToggle) {
      setToggleFilter(JSON.parse(storedToggle));
    }
  }, []);

  const toggleFunction = () => {
    const newToggleState = !toggleFilter;
    setToggleFilter(newToggleState);
    StorageService.setValue("toggleHeader", JSON.stringify(newToggleState));
  }

  return (
    <div className={`flex items-center justify-start ${toggleFilter ? 'w-full' : 'w-[40px]'} sm:w-full bg-[#131313] tranding`}>
      {/* Filter icon for mobile, always visible */}
      <div className="w-[40px] sm:hidden">
        <img
          src={filter}
          className="w-[35px] h-[35px] bg-[#131313] p-2"
          // onClick={() => setToggleFilter(!toggleFilter)}
          onClick={toggleFunction}
        />
      </div>
      {/* Button section, always open on desktop, conditionally open on mobile */}
      <div className={`flex items-center justify-around sm:justify-start sm:gap-7 bg-[#131313] btnSection 
        ${toggleFilter ? 'block' : 'hidden'} sm:flex`}
        onClick={handleClickInside}>
        <button
          className={`bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10 ${tabValue === "trending" ? 'active' : ""}`}
          onClick={() => navigate("/")}
        >
          <TrendingTopicIcon fill={"#fff"} />
          <span className="text-primary font-primaryFont font-normal ml-1 hidden sm:block">Trending Topic</span>
        </button>

        <button
          className={`bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10 ${tabValue === "top" ? 'active' : ""}`}
          onClick={() => navigate("/top-post")}
        >
          <BsStar className="text-[#fff] text-lg" fill="#fff" />
          <span className="text-[#fff] font-primaryFont font-normal ml-1 hidden sm:block">Top</span>
        </button>

        <button
          className={`bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10 ${tabValue === "new" ? 'active' : ""}`}
          onClick={() => navigate("/new-post", { state: { from: true } })}
        >
          <NewTopicIcon fill="#fff" />
          <span className="text-[#fff] font-primaryFont font-normal ml-1 hidden sm:block">New</span>
        </button>

        <button
          className={`bg-[#131313] shadow-[0_4px_32px_rgba(0,0,0,0.08)] rounded-lg flex items-center justify-center w-[70px] sm:w-[145px] h-[30px] sm:h-10 ${tabValue === "forYou" ? 'active' : ""}`}
          onClick={() => navigate("/for-you")}
        >
          <ForYouIcon fill="#fff" />
          <span className="text-[#fff] font-primaryFont font-normal ml-1 hidden sm:block">For You</span>
        </button>
      </div>
    </div>
  );
};

export default FilterTopic;
