import React, {useState} from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { get, post } from "../../api/api";
import { toast } from "react-toastify";
import StorageService from '../../services/StorageService';
import Loader from "../../components/Loader/Loader";
import { Height } from "@mui/icons-material";
import { startLoading, stopLoading } from "../../Store/IdentitySlice";
import axios from "axios";
import { useDispatch } from "react-redux";

const GoogleAuthentication = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const getUserData = async (userId,token) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(
        `${StorageService?.baseURL}/Identity/GetUser?userId=${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        const userData = response?.data?.user;
        StorageService.setValue("userName", userData?.userName);
        StorageService?.setJwt(response?.data?.token);
        StorageService.setValueProfile("profilePic", userData?.profilePicture);
        dispatch(stopLoading());
      }
    } catch (error) {
      dispatch(stopLoading());
      console.error("Error fetching user data:", error);
    }
  };

  const loginWithGoogle = async (val) => {
    setIsLoading(true);
    try {
      let res = await post("/Identity/GoogleExternalLogin", val);
      let loginRes = await res.json();
      if (loginRes.error === false) {
        toast.success("Login success!");
        setIsLoading(false);
        getUserData(loginRes?.userId,loginRes?.token)
        StorageService.setJwt(loginRes?.token);
        StorageService.setValue("userName", loginRes?.username);
        StorageService.setValue("userId", loginRes?.userId);
        navigate('/');
      } else {
        setIsLoading(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      // Handle CORS error
      setIsLoading(false);
      console.error("CORS error:", error);
      toast.error("Failed to login due to CORS issue!");
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div style={{ width:'100%', height:'100%', border:'none !important', boxShadow: 'none !important', outline: 'none !important' }}>
      
    {isLoading && <Loader />}
      <GoogleLogin
        onSuccess={(credentialResponse) =>  {
          const response = jwtDecode(credentialResponse?.credential);
          let GoogleSignIn = {
            token: credentialResponse?.credential,
            email: response.email,
            username: response.given_name + " " + response.family_name
          }
          loginWithGoogle(GoogleSignIn)
        }}
        onError={() => {
          toast.error("Login Failed");
        }}
        useOneTap
      />
      </div>
  );
};

export default GoogleAuthentication;
