import React, { useRef, useState } from 'react'
import StorageService from '../../services/StorageService';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { startLoading,stopLoading } from "../../Store/IdentitySlice";
import { get, post } from "../../api/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const TwoFAModel = ({ open, setTwofa }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [authCode, setAuthCode] = useState("");
    const modalRef = useRef();

    function resetFunction() {
    }

    const verifyUser = async () => {
        dispatch(startLoading());
        let data = {
            userId:StorageService.getValue("userId"),
            authcode: authCode,
            secretKey: StorageService.getValue("secretKey")
        }
        let res = await post('/Identity/VerifyAuthCode', data);
        if(res.status === 200){
            dispatch(stopLoading());
            toast.success("Login success!");
            setTwofa(false);
            navigate('/')
        } else {
            dispatch(stopLoading());
            toast.error("Unauthorized please try again!");
        }
    }

    const closeModel = () => {
        setTwofa(false);
    }

    function resetAllValue() {
        setTwofa(false);
        resetFunction();
    }

    function onFunction(e) {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            closeModel();
        }
      }

  return (
    <>
     <div
      className={"postCreate"}
      style={{
        opacity: open ? 1 : 0,
        cursor: open ?? "none",
        zIndex: open ? 11111111111 : -1,
      }}
      onClick={onFunction}
    >
        
        <div className="center_div" ref={modalRef}>
        <div className="close_icon" style={{ color:"white",display:"flex",flexWrap:"wrap",justifyContent:"end"}} onClick={closeModel}>
            <FontAwesomeIcon icon={faXmark} />
        </div>
            <div className='qr-img-div'>
                <img src={StorageService.getValue("QRCode")}  alt="qr-code" />
            </div>
            <div className="qr_post_title">
            <input
              type="text"
              placeholder="Enter auth code"
              className="qr_title_field"
              onChange={(e) => setAuthCode(e.target.value)}
            />
          </div>
          <div className="post_button" onClick={verifyUser}>
          <p>
            <span className="text-[#000]">
              {" "}
              Verify
            </span>
          </p>
        </div>
        </div>
    
    </div>
    </>
  )
}

export default TwoFAModel