import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import classes from "./ResetPasswordLinkForm.module.css";
import CardDiv from "../../UI/CardDiv/CardDiv";
import ConfigurePasswordBtn from "../ConfigurePasswordBtn/ConfigurePasswordBtn";
import FormInput from "../FormInput/FormInput";
import GetTheApp from "../GetTheApp/GetTheApp";
import Divider from "../Divider/Divider";
import FormContent from "../FormContent/FormContent";
import Header from "../../Header/Header";
import StorageService from "../../../services/StorageService";
import { startLoading, stopLoading } from "../../../Store/IdentitySlice";

const ResetPasswordFormLink = () => {
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const dispatch= useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(startLoading())
    StorageService.setValue("emailOrUsername", emailOrUsername);
    try {
      const response = await axios.post(
        `${StorageService?.baseURL}/Identity/ForgetPassword?emailOrUserName=${emailOrUsername}`
      );

      if (response) {
        toast.success("Password reset link sent to your email.");
        dispatch(stopLoading())
        StorageService.removeItem("emailOrUsername");
      }
    } catch (error) {
      dispatch(stopLoading())
            if (error.response) {
        toast.error(error.response.data);
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailOrUsername(value);
  };

  return (
    <React.StrictMode>
      <Header loginAndSignup={true} messageShow={false} searchBarShow={false} />
      <CardDiv className={classes.login}>
        <form action="" className={classes["form-controls"]} onSubmit={handleSubmit}>
          <FormContent
            heading="Trouble logging in?"
            content="Enter your email or username and we'll send 
            you a link to get back into your account."
          />
          <FormInput
            label="Email or Username"
            className={`mt-2rem`}
            value={emailOrUsername}
            onChange={handleInputChange}
          />
          <ConfigurePasswordBtn title="Send Password Reset Link" />

          <Divider />
          <GetTheApp isCreateAccount={true} />
        </form>
      </CardDiv>
    </React.StrictMode>
  );
};

export default ResetPasswordFormLink;
